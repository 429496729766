import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
    mt: {
        marginTop: 10
    },
    textfield: {
        marginTop: 5
    },
    icon: {
        // background: '#4D72F8',
        borderRadius: 14,
        color: 'white'
    },
    nextIcon: {
        background: '#ECECEC',
        float: 'right',
        margin: 10,
        '&:hover': {
            border: "2px solid #4D72F8",
        },
        "&:active": {
            backgroundColor: "blue",
        },
    },
    nextIconActive: {
        border: "5px solid #4D72F8",
        // float: 'right',
        margin: 10,
        '&:hover': {
            border: "5px solid #4D72F8",
        },
        "&:active": {
            backgroundColor: "blue",
        },
    },
    nextIconActive1: {
        backgroundColor: '#4D72F8',
        border: "5px solid #4D72F8",
        // float: 'right',
        margin: 10,
        '&:hover': {
            backgroundColor: '#4D72F8',
        },
        "&:active": {
            backgroundColor: "blue",
        },
    },
    err: {
        margin: 0,
        fontSize: "0.6rem",
        color: "red",
    },
    main: {
        color: "#ffc107!important",
        fontWeight: 600,
        display: "inline-block",
        marginBottom: "0.5rem",
    },
    type: {
        fontWeight: 600
    }
})
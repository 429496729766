import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
    tabs: {
        padding: 5,
        paddingBottom: 5,
        // width: '50%',
        '@media (max-width:426px)': {
            marginBottom: '-4px'
        }
    },
    err: {
        margin: 0,
        fontSize: "0.6rem",
        color: "red",
    },
    okButton: {
        width: '20%',
        marginRight: '40%',
        background: '#377dff',
        '&:hover': {
            background: '#377dff',
            color: 'white',
        },
        color: 'white',
        '@media (max-width:426px)': {
            width: '32%',
            marginRight: '35%'
        }
    },
    nextIcon: {
        background: '#ECECEC',
        float: 'right',
        margin: 10,
        '&:hover': {
            border: "5px solid #4D72F8",
        },
        "&:active": {
            backgroundColor: "blue",
        },
    },
    dilText: {
        textAlign: 'center',
        fontWeight: 600,
        color: 'black',

    },
    nextIconActive: {
        border: "5px solid #4D72F8",
        // float: 'right',
        margin: 10,
        '&:hover': {
            border: "5px solid #4D72F8",
        },
        "&:active": {
            backgroundColor: "blue",
        },
    },
    nextIconActive1: {
        backgroundColor: '#4D72F8',
        border: "5px solid #4D72F8",
        // float: 'right',
        margin: 10,
        '&:hover': {
            backgroundColor: '#4D72F8',
        },
        "&:active": {
            backgroundColor: "blue",
        },
    },
    icon: {
        // background: '#4D72F8',
        borderRadius: 14,
        color: 'white'
    },
    tab: {
        minHeight: 10,
        // height: 30,
        borderRadius: 5,
        background: '#2645AD',
        boxSizing: 'border-box',
        fontFamily: 'work sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        color: '#FFFFFF',
        textTransform: 'none',
    },
    tabActive: {
        minHeight: 10,
        // height: 30,
        borderRadius: 5,
        background: '#F2F2F2',
        boxSizing: 'border-box',
        fontFamily: 'work sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        color: '#C9C9C9',
        textTransform: 'none',
    },
    header: {
        fontSize: 16,
        fontFamily: 'work sans',
        color: '#464646',
        fontWeight: 500,
    },
    selectField: {
        marginTop: 5
    },
    headerOne: {
        fontSize: 16,
        fontFamily: 'work sans',
        color: '#464646',
        fontWeight: 500,
        marginTop: 15,
    },
    headerTwo: {
        fontSize: 16,
        fontFamily: 'work sans',
        color: '#464646',
        fontWeight: 500,
        marginTop: 25,
    },
    bFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // marginTop: '15px'
    },
    availableButton: {
        fontSize: 16,
        textTransform: 'capitalize',
        fontFamily: 'work sans',
        padding: '3px 20px',
    },
    numButtonOne: {
        marginTop: 20,
    },
    numButton: {
        marginTop: 10,
    },
    label: {
        fontSize: 12
    },
    textsize: {
        fontSize: 14
    },
    transferNum: {
        display: 'flex',
        padding: 20,
        alignItems: 'center',
        "&:svg": {
            marginRight: 10
        }
    },
    dFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 40

    },
    mt: {
        marginTop: 15
    },
    footer: {
        position: 'fixed',
        bottom: '0px',
        width: '100%',
        background: '#f5f5f5'
    }
})
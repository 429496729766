
import SupportComp from "../container/SupportPage/SupportPage";
import LocalNumber from "../container/LocalNumber/LocalNumber";
import TransferNumber from "../container/TransferNumber/TransferNumber";
import SigninPage from "../container/SigninPage/SigninPage";
import OrderSummary from "../container/OrderSummary/OrderSummary";
import UserProfile from "../container/UserProfile/UserProfile";
import EfoneNumbers from '../container/LocalNumber/AddEfoneNumber/AddEfoneNumber'
import Register from "../container/NewSignUp/Register/Register";
import ChoosePlan from "../container/NewSignUp/ChoosePlan/ChoosePlan";
import Checkout from "../container/NewSignUp/Checkout/Checkout";
import ReserveNumbers from "../container/NewSignUp/ReserveNumbers/ReserveNumbers";
import VerifyLink from "../container/NewSignUp/VrifyLink/VerifyLink";
import Thankyou from "../container/NewSignUp/Thankyou/Thankyou";
import Stripe from "../container/OrderSummary/StripeContainer";
import Successfull from "../container/NewSignUp/ReserveNumbers/SuccessFullPage";


const routes = [
  {
    path: "/signup/cdn1/phone-number/type",
    Title: "Support",
    Component: SupportComp,
    routes: [],
  },
  {
    path: "/signup/cdn1/phone-number/local",
    Title: "Local Number",
    Component: EfoneNumbers,
    routes: [],
  },
  {
    path: "/signup/cdn1/phone-number/port/number",
    Title: "Transfer Number",
    Component: TransferNumber,
    routes: [],
  },
  // {
  //   path: "/v1/signup/register",
  //   Title: "Register",
  //   Component: Register,
  //   routes: [],
  // },


  {
    path: "/v1/signup/reservenumber",
    Title: "Reserve Number",
    Component: ReserveNumbers,
    routes: [],
  },
  {
    path: "/v1/signup/chooseplan",
    Title: "Choose Plan",
    Component: ChoosePlan,
    routes: [],
  },
  {
    path: "/v1/signup/checkout",
    Title: "Choose Plan",
    Component: Checkout,
    routes: [],
  },
  {
    path: "/v1/signup/thankyou",
    Title: "welcome",
    Component: Thankyou,
    routes: [],
  },
  {
    path: "/v1/signup/successfull",
    Title: "Successfull",
    Component: Successfull,
    routes: [],
  },
  {
    path: "/v1/signup/stripe",
    Title: "Stripe",
    Component: Stripe,
    routes: [],
  },
  {
    path: "/signup/cdn1/payment",
    Title: "Order Summary",
    Component: OrderSummary,
    routes: [],
  },
  
  {
    path: "/signup/cdn1/profile",
    Title: "User Profile",
    Component: UserProfile,
    routes: [],
  },

];

export default routes;

import { makeStyles } from "@material-ui/styles";

export const useStyles : any = makeStyles((theme) => ({
    err: {
        // margin: -2,
        fontSize: "12px",
        color: "red",
        fontFamily: 'Work Sans',
        marginTop: -5,
    },
    annual:{
        fontFamily: 'Century Gothic ! important',
        fontWeight: 400,
        fontSize: '22px',
        color: '#555555'
    },
    logo: {
        margin: '10px 14%',
    },
    margings: {
        marginTop: -70
    },
    tabLists: {
        padding: '20px',
        marginLeft: '500px',
        alignItems: 'center',
    },
    overalCards: {
        dispaly: 'flex',
        gap: '20px',
        alignItems: 'center'
    },
    tww: {
        display: 'flex',
        gap: '65px',
        marginLeft: '200px',
    },
    div: {
        marginTop: '30px'
    },
    divboxs: {
        display: 'flex',
        gap: '20px'
    },
    onenewcard: {
        width: '300px',
        height: '400px',
        padding: '30px',
        background: '#ffffff',
        boxShadow: '0px 0px 6px 2px rgba(0, 0, 0, 0.1)',
        borderRadius: '13px'
    },
    newcard: {
        width: '300px',
        height: '430px',
        padding: '30px',
        // marginLeft: '533px',
        background: '#4D72F8',
        boxShadow: '0px 0px 6px 2px rgba(0, 0, 0, 0.1)',
        borderRadius: '13px'
    },
    threecard: {
        width: '300px',
        height: '480px',
        padding: '30px',
        background: '#ffffff',
        boxShadow: '0px 0px 6px 2px rgba(0, 0, 0, 0.1)',
        borderRadius: '13px'
    },
    divtext: {
        marginTop: '25px',
        // marginLeft:'16px'
    },
    righticons: {
        display: 'flex',
        gap: '8px'

    },
    freetypo: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: '20px',
        color: '#FFBB00',
        marginTop: '5px'
    },
    Enterprise: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: '20px',
        color: '#4D72F8',
        marginTop: '5px'
    },
    protypo: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: '20px',
        color: '#ffffff',
        marginTop: '5px'
    },
    numberfree: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: '32px',
        color: ' #000000',
        marginTop: '5px'
    },
    Custom: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: '32px',
        color: ' #000000',
        marginTop: '5px'
    },
    numberMonthly: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: '32px',
        color: '#ffffff',
        marginTop: '5px'
    },
    monthly: {
        fontFamily: 'Arial',
        fontWeight: 400,
        fontSize: '14px',
        color: '#ffffff',
        marginTop: '20px',
        marginLeft: '10px'
    },
    nummonthly: {
        fontFamily: 'Arial',
        fontWeight: 400,
        fontSize: '14px',
        color: '#000000',
        marginTop: '20px',
        marginLeft: '10px'
    },
    oneicons: {
        color: '#FFBB00',
        marginTop: '6px'
    },
    twoicons: {
        color: '#ffffff',
        marginTop: '6px'
    },
    icons: {
        color: '#ffffff',
        marginTop: '6px'
    },
    threeicons: {
        color: ' #4D72F8',
        marginTop: '6px'
    },
    onetool: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: '14px',
        color: '#000000',
        marginTop: '7px'
    },
    twotool: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: '14px',
        color: '#ffffff',
        marginTop: '7px'
    },
    tool: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: '14px',
        color: '#ffffff',
        marginTop: '7px'
    },
    onebutton: {
        fontSize: '20px',
        fontFamily: 'Arial',
        fontStyle: 'normal',
        fontWeight: 700,
        width: 246,
        height: 50,
        marginTop: '15px',
        // padding: '7px 20px',
        background: '#FFBB00',
        borderRadius: '13px',
        color: '#ffffff',
        // width: '70%',
        '&:hover': {
            background: '#FFBB00',
            color: '#ffffff'
        },
    },
    twobutton: {
        fontSize: '20px',
        fontFamily: 'Arial',
        fontStyle: 'normal',
        fontWeight: 700,
        width: 246,
        height: 50,
        marginTop: '15px',
        // padding: '7px 20px',
        borderRadius: '13px',
        background: '#FFFFFF',
        color: '#4D72F8',
        '&:hover': {
            background: '#FFFFFF',
            color: '#4D72F8'
        },

    },
    button: {
        marginTop: '15px',
        padding: '7px 20px',
        background: '#4D72F8',
        borderRadius: '13px',
        color: '#ffffff',
       marginRight:'15px',
        '&:hover': {
            background: '#4D72F8',
            color: '#ffffff',
        }
    },
    threebutton: {
        marginTop: '15px',
        padding: '7px 20px',
        background: '#4D72F8',
        borderRadius: '13px',
        color: '#ffffff',
        width: '70%',
        '&:hover': {
            background: '#4D72F8',
            color: '#ffffff',
        }
    },
    secondCard: {
        //  width:'1100px',
        // height:'680px',
        background: '#FAFAFA',
        border: ' 1px solid rgba(0, 0, 0, 0.1)',
        // padding: '30px',
        //  margin:'50px'
        marginLeft: 212,
        marginRight: 275

    },
    cvv: {
        marginLeft: '-90px'
    },
    divpadding: {
        padding: '30px'

    },
    cardetails:{
        padding:"30px 0px 15px 0px",
    },
    divider: {
        border: '1px solid rgba(51, 51, 51, 0.15)',
        // height: '80vh'

    },
    cardthree: {
        width: '302px',
        padding: '20px',
        // height:'274px',
        background: '#ffffff',
        border: '3px solid #4D72F8',
        borderRadius: '7px'
    },
    ProTypography: {
        fontFamily: 'Century Gothic ! important',
        fontWeight: 700,
        fontSize: '27px',
        color: '#555555'
    },
    business: {
        fontFamily: 'Century Gothic ! important',
        fontWeight: 400,
        fontSize: '16px',
        color: '#555555'
    },
    user: {
        fontFamily: 'Century Gothic ! important',
        fontWeight: 700,
        fontSize: '22px',
        color: '#555555'
    },
    subtotal: {
        fontFamily: 'Century Gothic ! important',
        fontWeight: 400,
        fontSize: '18px',
        color: '#000000'
    },
    subtotalNumber: {
        display: 'flex',
        // float:'right',
        fontFamily: 'Century Gothic ! important',
        fontWeight: 700,
        fontSize: '18px',
        color: '#555555',
        marginTop: '-27px',
        marginLeft: '250px'
    },
    Newfeaturess:{
        fontWeight: 600,
        marginTop:15,

    },
    subtotalNumbers:{
        display: 'flex',
        // float:'right',
        fontFamily: 'Century Gothic ! important',
        fontWeight: 500,
        fontSize: '18px',
        color: '#555555',
    },
    carddiv: {
        marginTop: '25px',
        // marginLeft: '15px'
    },
    fourboxs: {
        dispaly: 'flex',
        gap: '10px'

    },
    cards: {
        dispaly: 'flex',
        gap: '10px'

    },
    yourPayment: {
        fontFamily: 'Century Gothic ! important',
        fontWeight: 700,
        fontSize: '24px',
        color: ' #5A5A5A',
        // marginLeft:'16px'
    },
    cardName: {
        fontFamily: 'Arial',
        fontWeight: 400,
        fontSize: '14px',
        color: ' #5A5A5A',
        marginTop: '15px',
        // marginBottom:'15px'
    },
    textfield: {
        background: '#ffffff',
        // border: '1px solid #E7E7E7',
        borderRadius: '4px',
        width: '70%'
    },
    expiryDate:{
        background: '#ffffff',
        // border: '1px solid #E7E7E7',
        borderRadius: '4px',
        width:'120px'
        // width: '40%'
    },
    divTextfield: {
        display: 'flex',
        // width: '15%',
        background: '#ffffff',
        // border: '1px solid #E7E7E7',
        borderRadius: '4px',
        width:80
    },
    cardNumber: {
        fontFamily: 'Arial',
        fontWeight: 400,
        fontSize: '14px',
        color: ' #5A5A5A'
        
    },

    expirationdate: {
        fontFamily: 'Arial',
        fontWeight: 400,
        fontSize: '14px',
        color: ' #5A5A5A'
    },
    textfielddate: {
        width: '20%',
        background: '#ffffff',
        border: '1px solid #E7E7E7',
        borderRadius: '4px',
    },
    paynow: {
        marginBottom:'15px',
        padding: '7px 10px',
        background: '#4D72F8',
        borderRadius: '5px',
        // marginTop: '40px',
        // marginLeft: '100px',
        display: 'flex',
        alignItems: 'center',
        color: '#ffffff',
        // width: '35%',
        '&:hover': {
            background: '#4D72F8',
            color: '#ffffff'
        }
    },
    newTextfield: {
        width: '35%',
        background: '#ffffff',
        border: '1px solid #E7E7E7',
        borderRadius: '4px',
    },
    age: {
        width: '100%',
        marginTop: '14px',
        background: '#ffffff'
    },
    place: {
        background: '#ffffff',
        border: '1px solid #E7E7E7',
        borderRadius: '4px',
        width: '35%'
    }

}))
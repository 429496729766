import React from 'react'
import { Card, Tab, Tabs } from '@mui/material'
import { TabContext, TabPanel } from '@material-ui/lab';
import Default from '../Default/Default';
import AreaCode from "../Default/AreaCode"
import SignupServices from '../SignupServices';
import { useHistory } from 'react-router-dom';
import './myst.css'
import { useStyles } from './style'
import { Button, Tooltip, Typography } from '@material-ui/core';
import { BiEdit } from 'react-icons/bi';
import App from "../../OrderSummary/StripeContainer";
const countryOptions = [
  { label: "canada", value: 'CAN' },
  { label: "united states", value: 'USA' },
];


function ReNumbers(props:any) {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState("2")
  const [stripe, setStripe] = React.useState(false)
  const [reNumbers, setreNumbers] = React.useState("1")
  const [city, setCity] = React.useState<any>([]);
  const [country, setCountry] = React.useState<any>({ label: "Canada", value: 'CAN' });
  const [cities, setCities] = React.useState([]);
  const [area, setArea] = React.useState<any>([]);
  const [state, setState] = React.useState<any>([]);
  const [states, setStates] = React.useState([]);
  const [areaCode,setAreaCode]=React.useState("");
  const [localNumbers, setLocalNUmbers] = React.useState<any>([]);
  const [localNumberAreaCode,setLocalNUmbersAreaCode]=React.useState<any>([]);
  const [repeatNumber, setRepaetNumber] = React.useState<any>([]);
  const [index, setIndex] = React.useState(0)
  const [citiesOptions, setCityOptions] = React.useState([]);
  const [id, setId] = React.useState('')
  const [Number, setSelectedNumber] = React.useState("");
  const [reserveNumber, setreserveNumber] = React.useState(false);
  const [Repaetindex, setRepeatIndex] = React.useState(0)
  const [loading, setLoading] = React.useState(false);
  
  const handleSelectedNumber = (number: any) => {
    setSelectedNumber(number)
  }

  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    setValue(value);
  };
  var summary = {
    accountId: id,
    Number:Number
  }
 console.log("sunnarty",summary)

  const handleReserveNumber = () => {
    history.push({
      pathname: "/v1/signup/stripe",
      state: { data: summary },
    });
  
    // const data = {
    //   userId: id,
    //   phoneNumber: Number
    // }
    // SignupServices.saveReserveNumber(data).then((res: any) => {
    //   if (res.success) {
    //     // console.log("phonenumbers",res.data)
    //     // history.push("/v1/signup/chooseplan")
    //     props.handleClickNumber()
    //     getUserData(id)
    //     setLoading(false)
    //   } else {
    //     setLoading(false)
    //   }
    // })
    //   .catch((err: any) => {
    //     setLoading(false)
    //   });
  }

  const onChangeAreaCode = (e: any) => {
    setAreaCode((prev: any) => {
        return {
            ...prev,
            area: e.target?.value
        }
    })
    setLoading(true)
    SignupServices.fetchLocalNumbersThroughAreaCode(e.target.value).then((res: any) => {
      // console.log('kkkk',res)
      if (res.success) {
        // console.log("phonenumbers",res.data)
        setLocalNUmbersAreaCode(res.data);
        setLoading(false)
      } else {
        setLoading(false)
      }
    })
}

const handleLoading = (callback:any) => {
  setLoading(callback);
};
  const onChangeCountryType = (data: any) => {
    // console.log('state',data.value)
    setState([]);
    setCity([]);
    setArea([])
    setLocalNUmbers([])
    setCountry(data);
    setLoading(true)
    SignupServices.fetchStates(data.value).then((res: any) => {
      // console.log('sttt',res)
      if (res.success) {
        const mappedStateData: any = res.data.map((item: any) => {
          return { label: item.value, value: item.id };
        });
        setStates(mappedStateData);
        setLoading(false)
      } else {
        setLoading(false)
      }
    })
      .catch((err: any) => {
        // send error
        setLoading(false)
      });
  };
  const onChangeStateType = (data: any) => {
    setCity([]);
    setLocalNUmbers([])
    setState(data);
    setLoading(true)
    SignupServices.fetchCities(data.value).then((res: any) => {
      // console.log('citiesdesfdese',res)
      if (res.success) {
        const mapCitiesDropdown = res.data.map((dt: any) => {
          return { label: dt.value, value: dt.id };
        });
        // console.log("mapCitiesDropdown", mapCitiesDropdown)
        setCities(res.data);
        setCityOptions(mapCitiesDropdown);
        setLoading(false)
        // console.log(res.data);
      } else {
        setLoading(false)
      }
    })
      .catch((err: any) => {
        setLoading(false)
      });
  };
  //   // console.log('cities',cities,citiesOptions)
  const onChangeCity = (data: any) => {
    setCity(data);
    setLoading(true)
    // console.log("---city",data)
    const selectedCity: any = cities.find((cty: any) => cty.value === data.label);

    if (country && state && selectedCity) {
      const filterObject: any = {
        stateValue: state?.value,
        cityValue: selectedCity.value,
        npa: selectedCity?.npa,
        searchType: reNumbers,
      };
      SignupServices.fetchLocalNumbers(filterObject, id).then((res: any) => {
        // console.log('kkkk',res)
        if (res.success) {
          // console.log("phonenumbers",res.data)
          setLocalNUmbers(res.data);
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
        .catch((err: any) => {
          setLoading(false)
        });
    }
    // console.log("selected city", selectedCity);
  };



  const getUserData = (id: any) => {
    SignupServices.fetchUserData(id).then((res: any) => {
      // console.log('kkkk',res)
      if (res.success) {
        // console.log("userData", res.data)
        if (res.data?.reserveNumber) {
          // setSelectedNumber(res.data?.reserveNumber)
          setreserveNumber(true)
        }
      } else {

      }
    })
      .catch((err: any) => {

      });
  }
  React.useEffect(() => {
    const Id: any = localStorage.getItem("accountId")
    // console.log(Id)
    setId(Id)
    if (Id) {
      const newId = JSON.parse(Id)
      setId(newId)
    }
  }, [])
  // console.log('id', id);


  React.useEffect(() => {
    const id: any = localStorage.getItem("accountId")
    onChangeCountryType(country)
    getUserData(JSON.parse(id))

  }, [])

  React.useEffect(() => {
    if (city) {
      setIndex(0)
    }
  }, [city])
  React.useEffect(() => {
    if (area) {
      setRepeatIndex(0)
    }
  }, [area])

  const handleChangeNumbers = () => {
    setLoading(true)
    var myIndex = localNumbers.length > index + 5 ? index + 5 : 0
    setIndex(myIndex)
    setTimeout(() => {
      setLoading(false)
    }, 1000);

  }


  return (
    <div>
      <div className={classes.div}>
        <Typography className={classes.newnumber + " " + "mobreserve1"}>Reserve your new number</Typography>
        <Typography className={classes.typography}>You can always change or port your number at a later time.</Typography>
      </div>
      <div className={classes.phonenumbers}>
        <Typography className={classes.phonenumber}>{Number}</Typography>
        {reserveNumber ?
          <Tooltip title="Edit Number">
            <span>
              <BiEdit style={{ color: 'gray', fontSize: '26px' }} onClick={() => setreserveNumber(!reserveNumber)} />
            </span>
          </Tooltip>
          :
          ""
        }
      </div>
      <TabContext value={value}>
        <Tabs className={classes.city}
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          TabIndicatorProps={{
            style: {
              height: "0px",
            },
          }}
        >
          <Tab label="Area Code" value="1" className={value === "1" ? classes.tab : classes.tabActive} />
          <Tab label="City Name" value="2" className={value === "2" ? classes.tab : classes.tabActive} />


        </Tabs>
        <TabPanel className='citytab' value="2">
          <Default
            countryOptions={countryOptions} onChangeStateType={onChangeStateType}
            states={states} state={state}
            country={country}
            citiesOptions={citiesOptions}
            onChangeCountryType={onChangeCountryType} onChangeCity={onChangeCity}
            city={city} localNumbers={localNumbers}
            // fetchNumberLists={props.fetchNumberLists}
            loading={loading}
            handleChangeNumbers={handleChangeNumbers}
            index={index}
            // selectedNumber={props.selectedNumber}
            handleSelectedNumber={handleSelectedNumber}
            reserveNumber={reserveNumber}
          />
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {/* <Button
              variant='outlined'
              size='small'
              className={classes.back + " " + "backmob"}
              onClick={() => history.push("/v1/signup/register")}
            >
              Back
            </Button> */}
            <Button
              variant='outlined'
              size='small'
              className={classes.button + " " + "resmobbtn"}
              onClick={handleReserveNumber}
            >
              Reserve this number
            </Button>
            {/* {
              Number ?
                <Button
                  variant='outlined'
                  size='small'
                  className={classes.button}
                  onClick={() => history.push("/v1/signup/chooseplan")}
                >
                  Next
                </Button> :
                ""
            } */}

          </div>
        </TabPanel>

        <TabPanel value="1">
          <AreaCode
           country={country}
           onChangeAreaCode={onChangeAreaCode}
           areaCode={areaCode}
           handleSelectedNumber={handleSelectedNumber}
           localNumbers={localNumberAreaCode}
            onChangeCountryType={onChangeCountryType} 
           countryOptions={countryOptions}
          />
               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {/* <Button
              variant='outlined'
              size='small'
              className={classes.back + " " + "backmob"}
              onClick={() => history.push("/v1/signup/register")}
            >
              Back
            </Button> */}
            <Button
              variant='outlined'
              size='small'
              className={classes.button + " " + "resmobbtn"}
              onClick={handleReserveNumber}
            >
              Reserve this number
            </Button>
            {/* {
              Number ?
                <Button
                  variant='outlined'
                  size='small'
                  className={classes.button}
                  onClick={() => history.push("/v1/signup/chooseplan")}
                >
                  Next
                </Button> :
                ""
            } */}

          </div>
        </TabPanel>

      </TabContext>
    </div>
  )
}

export default ReNumbers
import axiosInstance from "../../environment/axiosInstances";
import v5axiosInstance from '../../environment/v5axiosInstances'
import { toast } from 'react-toastify'
class SignupServices {

    fetchUserData = async (id: any) => {
        try {
            const response = await v5axiosInstance.get(`/auth?userId=${id}`);
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            if (error.response.data.data) {
                const data = error.response.data.data;
                return { success: true, data: data };
            }

            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };

    fetchStates = async (value: any) => {
        try {
            const response = await v5axiosInstance.get(`/auth/states?country=${value}`);
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };

    fetchCities = async (id: any) => {
        try {
            const response = await v5axiosInstance.get(`/auth/cities?state=${id}`);
            if (response.data) {
                const data = response.data.data;
                //   console.log('ggggg', response.data.data)
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };

    fetchLocalNumbers = async (data: any, id: any) => {
        //  console.log('fff',data)
        const SearchType = data.searchType
        try {
            var response = await v5axiosInstance.get(`/auth/getNumbersList?state=${data.stateValue}&city=${data.cityValue}`);
            if (response.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    fetchLocalNumbersThroughAreaCode = async (data: any) => {
        try {
            var response = await v5axiosInstance.get(`/auth/getNumbersList?npa=${data}`);
            if (response.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    fetchPackageDetails = async () => {
        try {
            var response = await v5axiosInstance.get(`/auth/plans`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    saveReserveNumber = async (data: any) => {
        try {
            const response = await v5axiosInstance.post(`/auth/phoneNumber`, data);
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };

    savePackageDetails = async (data: any) => {
        try {
            var response = await v5axiosInstance.post(`/auth/subscribe`, data);
            if (response.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    savecardDetails = async (data: any) => {
        try {
            var response = await v5axiosInstance.post(`/auth/checkout`, data);
            if (response.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
}
const services = new SignupServices();
export default services;
import { makeStyles } from '@material-ui/core/styles';
import { Padding } from '@mui/icons-material';
import { textTransform } from '@mui/system';
import { borderRadius } from 'react-select/src/theme';

export const useStyles = makeStyles(() => ({
    email: {
        fontFamily: "Century Gothic",
        fontSize: "13px",
        color: '#212121',
        fontWeight: 600,
        fontStyle: 'normal',
    },
    err: {
        margin: 0,
        fontSize: "13px",
        color: "red",
        marginTop: 5
    },
    pagetit: {
        paddingTop: '30px',
        paddingBottom: '30px'

    },
    logo: {
        margin: '10px 14%',
        "@media (max-width: 768px)": {
            margin: '20px 20px 0px 20px'
        }
    },

    margings: {
        marginTop: -70,
        "@media (max-width: 768px)": {
            marginTop: 0,
            marginBottom: 60
        }
    },
    card: {
        margin: '10px 5%',
        backgroundColor: 'rgba(255, 255, 255, 0.1215686275)!important',
        boxShadow: 'none!important',
        padding: '65px',
        width: '-moz-fit-content',
        "@media (max-width: 768px)": {
            background: 'none !important'
        }

        // marginTop:-10
    },
    textfield: {
        width: '80%',
        // border: '1px solid rgba(0.1)',
        // borderRadius: '15px',
        "@media (max-width: 768px)": {
            width: '100%'
        }
    },
    imgStyles: {
        // width: 400,
        // height: 320,
        "@media (max-width: 426px)": {
            width: '275px',
            height: '300px'
        }
    },
    Password: {
        fontFamily: "Century Gothic",
        fontSize: "13px",
        color: '#212121',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    account: {
        marginTop: '25px',
        color: '#ffffffde',
        fontFamily: 'sans-serif!important',
        fontSize: '13px',
        textDecorationLine: 'Login',
        cursor: 'pointer',
        "@media (max-width: 768px)": {
            fontSize: '12px',
            textAlign: 'center'

        }
    },
    creating: {
        marginTop: '10px',
        color: '#ffffffde',
        fontSize: '13px',
        fontStyle: 'normal',
        fontFamily: 'sans-serif!important',
        "@media (max-width: 768px)": {
            fontSize: '12px',
            textAlign: 'center'

        }
    },
    vertical: {
        borderLeft: '2px solid rgba(51, 51, 51, 0.15)',
    },
    div: {
        marginTop: '100px',
        marginLeft: '270px',
    },
    div1: {
        // marginLeft: '40px',
        // marginTop: '40px'
    },
    div2: {
        marginTop: '10px'
    },
    create: {
        padding: "5px",
        width: "80%",
        borderRadius: "5px",
        outline: 20,
        border: "1px solid #ffffff8c",
        "@media (max-width: 768px)": {
            width: '100%'
        }
    },
    continue: {
        // padding: "10px 9px",
        width: "80%",
        borderRadius: "5px",
        fontSize: '16px',
        border: "1px solid rgba(0, 0, 0, 0.2)",
        marginTop: '20px',
        background: 'white',
        color: '#0B1B37',
        '&:hover': {
            background: '#ffffffe0',
            color: '#0B1B37',
        },
        textTransform: 'capitalize',
        height: '42px',
        "@media (max-width: 768px)": {
            width: '100%'
        }
    },
    google: {
        width: "80%",
        borderRadius: "5px",
        border: " 1px solid rgba(0, 0, 0, 0.2)",
        marginTop: '25px',
        background: '#FFFFFF',
        height: '42px',
        color: '#0B1B37',
        '&:hover': {
            background: '#ffffffe0',
            color: '#0B1B37',
        },
        textTransform: 'capitalize',
        "@media (max-width: 768px)": {
            width: '100%'
        }
    },
    verifyText: {
        marginBottom: 10
    },
    button: {
        width: '100%',
        height: '42px',
        background: 'linear-gradient(#d92cc1, #4d72f8)',
        color: 'white',
        borderRadius: "5px",
        border: 'none',
        marginTop: '30px!important',
        "&:hover": {
            background: '#4D72F8', color: 'white',
        }

    }


}))



import { Button, Card, Divider, FormControlLabel, Grid, Radio, TextField, Typography } from '@material-ui/core'
import React from 'react'
import Select from '../ChoosePlan/Select'
import { useStyles } from './styles'
import StepFour from '../Steps/StepFour'
import { useHistory } from 'react-router-dom';
import SignupServices from '../SignupServices';
import logo from '../../../assets/images/logo.png'
import v5axiosInstaces from '../../../environment/v5axiosInstances'
import { toast } from "react-toastify";
import { YearPicker, MonthPicker } from "react-dropdown-date";
import './styles.css';
import axios from 'axios'
import PlanData from '../ChoosePlan/PlanandFeatures'
const formGroup: any = [
  {
    field: 'card_Number',
    valid: true,
    message: ''
  },
  {
    field: 'card_Holder_Name',
    valid: true,
    message: ''
  },
  {
    field: 'date',
    valid: true,
    message: ''
  },
  {
    field: 'expMonth',
    valid: true,
    message: ''
  },
  {
    field: 'expYear',
    valid: true,
    message: ''
  },
  {
    field: 'card_CVC',
    valid: true,
    message: ''
  },
]



function Checkout(props: any) {
  const classes : any = useStyles()
  const history = useHistory();
  const [formGroupData, setFormgroup] = React.useState<any>(formGroup);
  const [pasted, setPasted] = React.useState(false);
  const [saveCardDetails, setsaveCardDetails] = React.useState<any>(false);
  const [checkoutData, setcheckoutData] = React.useState<any>([])
  const [paymentData, setPaymentData] = React.useState<any>({
    userId: '',
    planId: "",
    featureIds: [],
    cardDetails: {
      card_Number: "",
      card_ExpMonth: "",
      card_ExpYear: "",
      card_CVC: "",
      card_Holder_Name: "",
      cardnum1: '',
      cardnum2: '',
      cardnum3: '',
      cardnum4: ''
    },
  })
  const [date, setDate] = React.useState<any>({ year: "", month: "", day: "" });
  const [monthError, setMonthError] = React.useState<any>(false);
  const [dayError, setDayError] = React.useState<any>(false);
  const [yearError, setYearError] = React.useState<any>(false);
  const [planId, setPlanId] = React.useState('');
  const [featureId, setFeatureId] = React.useState([]);
  const [featureName, setFeatureName] = React.useState([]);


  console.log('featureName', featureName);





  const submit = () => {
    console.log(date);
    if (date.month === "") {
      setMonthError(true);
    }
    if (date.day === "") {
      setDayError(true);
    }
    if (date.year === "") {
      setYearError(true);
    }
  };
  function formats(ele: any, e: any) {
    if (ele.card_Number.length < 16) {
      ele.card_Number = ele.card_Number.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
      setPaymentData({ ...paymentData, card_Number: ele.card_Number })
      return true;
    } else {
      return false;
    }
  };

  function numberValidation(e: any) {
    e.target.value = e.target.value.replace(/[^\d ]/g, '');
    return false;
  };

  const handleChangeStepTwo = (e: any) => {
    const val = e.target.value
    if (val !== " ") {
      const details = {
        ...paymentData.cardDetails,
        card_CVC: val
      }
      setPaymentData((prev: any) => {
        return {
          ...prev,
          cardDetails: details
        }
      })
    }
  };

  const handleChangeCvv = (e: any) => {
    const val = e.target.value
    if (val !== " ") {
      const details = {
        ...paymentData.cardDetails,
        card_CVC: val
      }
      setPaymentData((prev: any) => {
        return {
          ...prev,
          cardDetails: details
        }
      })
    }
  };

  const handleMonthChange = (month: any) => {
    const details = {
      ...paymentData.cardDetails,
      card_ExpMonth: parseInt(month)
    }
    setPaymentData((prev: any) => {
      return {
        ...prev,
        cardDetails: details
      }
    })
  }

  const handleYearChange = (year: any) => {
    const details = {
      ...paymentData.cardDetails,
      card_ExpYear: parseInt(year),
    }
    setPaymentData((prev: any) => {
      return {
        ...prev,
        cardDetails: details
      }
    })

  }
  const handleChangeCardName = (e: any) => {
    e.preventDefault()
    const details = {
      ...paymentData.cardDetails,
      card_Holder_Name: e.target.value
    }
    setPaymentData((prev: any) => {
      return {
        ...prev,
        cardDetails: details
      }
    })
    validateForm({ property: "card_Holder_Name", value: details })
  }
  const handleChangeCardNum1 = (e: any) => {
    e.preventDefault()
    const details = {
      ...paymentData.cardDetails,
      cardnum1: e.target.value
    }
    setPaymentData((prev: any) => {
      return {
        ...prev,
        cardDetails: details
      }
    })
  }
  const handleChangeCardNum2 = (e: any) => {
    e.preventDefault()
    const details = {
      ...paymentData.cardDetails,
      cardnum2: e.target.value
    }
    setPaymentData((prev: any) => {
      return {
        ...prev,
        cardDetails: details
      }
    })
  }
  const handleChangeCardNum3 = (e: any) => {
    e.preventDefault()
    const details = {
      ...paymentData.cardDetails,
      cardnum3: e.target.value
    }
    setPaymentData((prev: any) => {
      return {
        ...prev,
        cardDetails: details
      }
    })
  }

  const handleChangeCardNum4 = (e: any) => {
    e.preventDefault()
    const details = {
      ...paymentData.cardDetails,
      cardnum4: e.target.value
    }
    setPaymentData((prev: any) => {
      return {
        ...prev,
        cardDetails: details
      }
    })
  }
  const getUserData = (id: any) => {
    SignupServices.fetchUserData(id).then((res: any) => {
      if (res.success) {
        const data = res.data
        const featureIds = res.data.featureData
        setFeatureName(featureIds)
        const feaIds: any = []
        const featuteNames: any = [];
        featureIds.map((feaId: any) => {
          feaIds.push(feaId.featureId)

        })
        setFeatureId(feaIds)
        setcheckoutData(data)
        const planId = res.data.planData[0].planId
        setPlanId(planId)
      } else {

      }
    }).catch((err: any) => {

    });
  }

  React.useEffect(() => {

  }, [planId, featureId])


  React.useEffect(() => {
    const userid: any = localStorage.getItem("verifiedId")
    getUserData(JSON.parse(userid))
  }, [])

  const handlePayment = () => {
    const userid: any = localStorage.getItem("verifiedId")
    const cardnum = paymentData.cardDetails?.cardnum1 + paymentData.cardDetails?.cardnum2 + paymentData.cardDetails?.cardnum3 + paymentData.cardDetails?.cardnum4
    paymentData.cardDetails.card_Number = cardnum
    const payLoad: any = {
      userId: JSON.parse(userid),
      planId: planId,
      featureIds: featureId,
      cardDetails: {
        card_Number: cardnum,
        card_ExpMonth: paymentData?.cardDetails.card_ExpMonth,
        card_ExpYear: paymentData.cardDetails.card_ExpYear,
        card_CVC: paymentData.cardDetails.card_CVC,
        card_Holder_Name: paymentData.cardDetails.card_Holder_Name
      },
    }
    validateForm({ property: 'card_Holder_Name', value: paymentData.cardDetails?.card_Holder_Name })
    validateForm({ property: 'card_Number', value: cardnum })
    validateForm({ property: 'card_CVC', value: paymentData.cardDetails?.card_CVC })
    const [card_Holder_Name, card_Number, card_CVC] = formGroupData;
    // console.log('formGroupData', formGroupData);
    if (card_Holder_Name.valid && card_Number.valid && card_CVC.valid) {
      SignupServices.savecardDetails(payLoad).then((res: any) => {
        if (res.success) {
          localStorage.removeItem("verifiedId")
          history.push("/v1/signup/register")
        } else {

        }
      }).catch((err: any) => {

      });
    }

  }


  const handlePaymentDetails = () => {
    const userid: any = localStorage.getItem("verifiedId")
    const cardnum = paymentData.cardDetails?.cardnum1 + paymentData.cardDetails?.cardnum2 + paymentData.cardDetails?.cardnum3 + paymentData.cardDetails?.cardnum4
    paymentData.cardDetails.card_Number = cardnum
    const payLoad: any = {
      userId: JSON.parse(userid),
      planId: planId,
      featureIds: featureId,
      cardDetails: {
        card_Number: cardnum,
        card_ExpMonth: paymentData?.cardDetails.card_ExpMonth,
        card_ExpYear: paymentData.cardDetails.card_ExpYear,
        card_CVC: paymentData.cardDetails.card_CVC,
        card_Holder_Name: paymentData.cardDetails.card_Holder_Name
      },
    }
    // validateForm({ property: 'card_Holder_Name', value: paymentData.cardDetails?.card_Holder_Name })
    // validateForm({ property: 'card_Number', value: cardnum })
    // validateForm({ property: 'card_CVC', value: paymentData.cardDetails?.card_CVC })

    const check = (object: any) => Object
      .entries(object)
      .every(([key, value]) => !['', 'null', 'undefined'].includes(key) && value);

    if (payLoad.userId && payLoad.featureIds.length > 0 && payLoad.planId) {
      // alert("with feature ids and without card")
      // toast.error("with feature ids and without card", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 1000,
      // });
      // console.log("hhhhh", payLoad.cardDetails, check(payLoad.cardDetails))
      if (!check(payLoad.cardDetails)) {
        // alert("please enter card details")
        toast.error("please enter card details", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
      SignupServices.savecardDetails(payLoad).then((res: any) => {
        if (res.success) {
          // localStorage.removeItem("verifiedId")
          history.push("/v1/signup/thankyou")
        } else {

        }
      }).catch((err: any) => {

      });
    }
    if (payLoad.userId && payLoad.planId && !payLoad.featureIds) {
      alert("with only plan ids")
      if (check(payLoad.cardDetails)) {
        alert("with only plan ids and card")
        // toast.error("please enter card details", {
        //   position: toast.POSITION.TOP_RIGHT,
        //   autoClose: 1000,
        // });
        SignupServices.savecardDetails(payLoad).then((res: any) => {
          if (res.success) {
            // localStorage.removeItem("verifiedId")
            history.push("/v1/signup/thankyou")
          } else {

          }
        }).catch((err: any) => {

        });
      } else {
        alert("with plan  ids and without card")
        let payload1: any = {
          userId: payLoad.userId,
          planId: payLoad.planId
        }
        SignupServices.savecardDetails(payload1).then((res: any) => {
          if (res.success) {
            // localStorage.removeItem("verifiedId")
            history.push("/v1/signup/thankyou")
          } else {

          }
        }).catch((err: any) => {

        });
      }
    }
  }

  const paywithOutCard = () => {
    const userid: any = localStorage.getItem("verifiedId")
    const cardnum = paymentData.cardDetails?.cardnum1 + paymentData.cardDetails?.cardnum2 + paymentData.cardDetails?.cardnum3 + paymentData.cardDetails?.cardnum4
    paymentData.cardDetails.card_Number = cardnum
    const payLoad: any = {
      userId: JSON.parse(userid),
      planId: planId,
      featureIds: featureId,
      cardDetails: {
        card_Number: cardnum,
        card_ExpMonth: paymentData?.cardDetails.card_ExpMonth,
        card_ExpYear: paymentData.cardDetails.card_ExpYear,
        card_CVC: paymentData.cardDetails.card_CVC,
        card_Holder_Name: paymentData.cardDetails.card_Holder_Name
      },
    }

    if (payLoad.featureIds.length > 0 && payLoad.cardDetails.card_Number === "") {
      // alert('selected features please enter card details and click on pay now')
      props.popUpAlert("selected features please enter card details and click on pay now", "UPDATE_AGENT_SUCCESS", "error");

    }
    else {
      const payLoad1: any = {
        userId: JSON.parse(userid),
        planId: planId,

      }
      SignupServices.savecardDetails(payLoad1).then((res: any) => {
        if (res.success) {
          // localStorage.removeItem("verifiedId")
          history.push("/v1/signup/thankyou")
        } else {
          props.popUpAlert("Please enter card details", "UPDATE_AGENT_SUCCESS", "error");

        }
      }).catch((err: any) => {
        // props.popUpAlert("Please enter card details", "UPDATE_AGENT_SUCCESS", "error");
      });
    }



  }


  const validateForm = (dataToValidate: any) => {
    let formgroupCopy = [...formGroupData];
    if (dataToValidate.property === 'card_Holder_Name') {
      if (dataToValidate.value === '') {
        formgroupCopy.forEach((formG: any) => {
          if (formG.field === 'card_Holder_Name') {
            formG.valid = false;
            formG.message = 'Name should not be empty';
          }
        })
        setFormgroup(formgroupCopy)
      }
    }
    else if (dataToValidate.property === 'card_Number') {
      const number = paymentData.card_Number?.replace(/[^a-zA-Z0-9]/g, '')
      if (dataToValidate.value === '') {
        formgroupCopy.forEach((formG: any) => {
          if (formG.field === 'card_Number') {
            formG.valid = false;
            formG.message = 'Number should not be empty';
          }
        })
        setFormgroup(formgroupCopy)
      }
      else if (dataToValidate.value?.length < 16) {
        formgroupCopy.forEach((formG: any) => {
          if (formG.field === 'card_Number') {
            formG.valid = false;
            formG.message = 'Please enter your 16 digit card numbers';
          }
        })
        setFormgroup(formgroupCopy)
      } else if (dataToValidate.value?.length > 16) {
        formgroupCopy.forEach((formG: any) => {
          if (formG.field === 'card_Number') {
            formG.valid = false;
            formG.message = 'card number should not exceed 16 digit';
          }
        })
        setFormgroup(formgroupCopy)
      }
      else {
        formgroupCopy.forEach((formG: any) => {
          if (formG.field === 'card_Number') {
            formG.valid = true;
            formG.message = ''
          }
        })
        setFormgroup(formgroupCopy)
      }
    }

    if (dataToValidate.property === 'date') {
      if (dataToValidate.value === '') {
        formgroupCopy.forEach((formG: any) => {
          if (formG.field === 'date') {
            formG.valid = false;
            formG.message = 'Date should not be empty';
          }
        })
        setFormgroup(formgroupCopy)
      } else if (dataToValidate.value) {
        var expire: any = document.getElementById("date");
        const ex: any = expire.value;
        var d = new Date();
        var currentYear = d.getFullYear();
        var twoDigitYear: any = currentYear.toString().substr(-2);
        var currentMonth = d.getMonth() + 1;
        // get parts of the expiration date
        var parts = ex.toString().split(/[-\/]+/);
        var year = parseInt(parts[1], 10);
        var month = parseInt(parts[0], 10);
        // compare the dates
        if (year < twoDigitYear || (year == twoDigitYear && month < currentMonth)) {
          formgroupCopy.forEach((formG: any) => {
            if (formG.field === 'date') {
              formG.valid = false;
              formG.message = 'The expiry date has passed';
            }
          })
        } else if (month > 12) {
          formgroupCopy.forEach((formG: any) => {
            if (formG.field === 'date') {
              formG.valid = false;
              formG.message = 'Invalid month';
            }
          })
        } else {
          formgroupCopy.forEach((formG: any) => {
            if (formG.field === 'date') {
              formG.valid = true;
              formG.message = ''
            }
          })
        }
      }
      else {
        formgroupCopy.forEach((formG: any) => {
          if (formG.field === 'date') {
            formG.valid = true;
            formG.message = ''
          }
        })
        setFormgroup(formgroupCopy)
      }
    }

    if (dataToValidate.property === 'card_CVC') {
      const cvc = paymentData.card_CVC?.replace(/[^a-zA-Z0-9]/g, '')
      if (dataToValidate.value === '') {
        formgroupCopy.forEach((formG: any) => {
          if (formG.field === 'card_CVC') {
            formG.valid = false;
            formG.message = 'cvc should not be empty';
          }
        })
        setFormgroup(formgroupCopy)
      } else if (cvc?.length < 1 || cvc?.length > 3) {
        formgroupCopy.forEach((formG: any) => {
          if (formG.field === 'card_CVC') {
            formG.valid = false;
            formG.message = 'CVV number must be 3 digits';
          }
        })
        setFormgroup(formgroupCopy)
      } else {
        formgroupCopy.forEach((formG: any) => {
          if (formG.field === 'card_CVC') {
            formG.valid = true;
            formG.message = ''
          }
        })
        setFormgroup(formgroupCopy)
      }
    }
  }

  const handleSavecarddetails = () => {
    setsaveCardDetails(!saveCardDetails)
  }


  //validation
  const validate = (submitted: any) => {
    const validData = {
      isValid: true,
      message: ""
    };
    const retData = {
      phoneNumber: { ...validData },
      currentProviderName: { ...validData },
      serviceType: { ...validData },
      endUserName: { ...validData },
      existingAccountNumber: { ...validData },
      StreetNumber: { ...validData },
      streetName: { ...validData },
      city: { ...validData },
      province: { ...validData },
      zipCode: { ...validData },
      loosingCarrierComments: { ...validData },
      comments: { ...validData }
    };
    let isValid = true;
    if (submitted) {
      if (!paymentData.card_Holder_Name) {
        retData.phoneNumber = {
          isValid: false,
          message: "Please enter phone number"
        };
        isValid = false;
      }
      if (paymentData.cardDetails.phoneNumber.length < 10 || paymentData.cardDetails.phoneNumber.length > 15) {
        retData.phoneNumber = {
          isValid: false,
          message: "Please enter 10 to 15 digit phone number "
        };
        isValid = false;
      }
      if (!paymentData.cardDetails.currentProviderName) {
        retData.currentProviderName = {
          isValid: false,
          message: "Please enter provider name"
        };
        isValid = false;
      }
      if (!paymentData.cardDetails.serviceType) {
        retData.serviceType = {
          isValid: false,
          message: "Please select service type"
        };
        isValid = false;
      }
      if (!paymentData.cardDetails.serviceType) {
        retData.serviceType = {
          isValid: false,
          message: "Please select service type"
        };
        isValid = false;
      }
      if (!paymentData.cardDetails.endUserName) {
        retData.endUserName = {
          isValid: false,
          message: "Please enter end user name "
        };
        isValid = false;
      }
      if (!paymentData.cardDetails.existingAccountNumber) {
        retData.existingAccountNumber = {
          isValid: false,
          message: "Please enter account number"
        };
        isValid = false;
      }
      if (!paymentData.cardDetails.StreetNumber) {
        retData.StreetNumber = {
          isValid: false,
          message: "Please enter street number"
        };
        isValid = false;
      }
      if (!paymentData.cardDetails.streetName) {
        retData.streetName = {
          isValid: false,
          message: "Please enter street name"
        };
        isValid = false;
      }
      if (!paymentData.cardDetails.city) {
        retData.city = {
          isValid: false,
          message: "Please enter city "
        };
        isValid = false;
      }
      if (!paymentData.cardDetails.province) {
        retData.province = {
          isValid: false,
          message: "Please enter province "
        };
        isValid = false;
      }
      if (!paymentData.cardDetails.zipCode) {
        retData.zipCode = {
          isValid: false,
          message: "Please enter zip code"
        };
        isValid = false;
      }
      if (!paymentData.cardDetails.loosingCarrierComments) {
        retData.loosingCarrierComments = {
          isValid: false,
          message: "Please enter carrier comments"
        };
        isValid = false;
      }
      if (!paymentData.cardDetails.comments) {
        retData.comments = {
          isValid: false,
          message: "Please enter comments"
        };
        isValid = false;
      }
    }
    return {
      ...retData,
      isValid
    };
  };

  return (
    <>
      <div className={classes.logo}>
        <img alt='logo' src={logo} />
      </div>
      <div className={classes.margings}>
        <StepFour />
        <Card className={classes.secondCard}>
          <Grid container spacing={6}>
            <Grid item md={4}>
              <div className={classes.divpadding}>
                <Card className={classes.cardthree}>
                  <Typography className={classes.ProTypography}>
                    {
                      checkoutData.planData?.map((pln: any) => {
                        return (
                          <>{pln.name}</>
                        )
                      })
                    }

                  </Typography>
                  <Typography className={classes.business}>
                    Our most popular plan includes
                    everything you need for starting a new
                    business.
                  </Typography>
                  <Typography className={classes.ProTypography}>${checkoutData?.monthly}</Typography>
                  <Typography className={classes.user}>user/month</Typography>
                  <Typography className={classes.annual}>${checkoutData?.annualy}</Typography>
                </Card>

                <div>
                  <div style={{marginBottom:20}}>
                    <Typography className={classes.Newfeaturess}>Feature Names</Typography>
                    {
                      featureName?.map((dt: any) => {
                        return (
                            <>
                              <Typography className={classes.subtotalNumbers}>{dt.name}</Typography>
                            </>
                        )
                      })
                    }
                  </div>
                  <div>
                    <Typography className={classes.subtotal}>Subtotal</Typography>
                    <Typography className={classes.subtotalNumber}>${checkoutData?.subtotal}</Typography>
                  </div>
                  <div>
                    <Typography className={classes.subtotal}>Tax</Typography>
                    <Typography className={classes.subtotalNumber}>{checkoutData?.tax}%</Typography>
                  </div>
                  <Divider style={{ width: 290 }} />
                  <div>
                    <Typography className={classes.subtotal}>Grand Total</Typography>
                    <Typography className={classes.subtotalNumber}>${checkoutData?.grandTotal}</Typography>
                  </div>
                </div>

              </div>
            </Grid>
            <Grid item md={1}>
              <Divider orientation='vertical' className={classes.divider} />
            </Grid>
            <Grid item md={7}>
              <div className={classes.cardetails}>
                <Typography className={classes.yourPayment}>Your Payment Details</Typography>
                <div className={classes.divtext}>
                  <Typography className={classes.cardName}>CARDHOLDER NAME</Typography>
                  <TextField className={classes.textfield}
                    size='small'
                    variant='outlined'
                    placeholder='ENTER CARD HOLDER NAME'
                    fullWidth
                    name="card_Holder_Name"
                    value={paymentData?.cardDetails.card_Holder_Name}
                    onChange={handleChangeCardName}
                  />
                  <p style={{ marginTop: 4 }} className={classes.err}>{formGroupData?.map((fileds: any) => {
                    if (fileds.valid === false && fileds.field === 'card_Holder_Name') {
                      return fileds.message
                    } else {
                      return ''
                    }
                  })}</p>
                </div>
                <div className={classes.carddiv}>
                  <Typography className={classes.cardNumber}> CARD NUMBER</Typography>
                  <div className={classes.divboxs}>
                    <TextField size='small'
                      variant='outlined'
                      placeholder=''
                      className={classes.divTextfield}
                      name="cardnum1"
                      onChange={handleChangeCardNum1} />
                    <TextField size='small' variant='outlined' placeholder='' className={classes.divTextfield} name="cardnum2" onChange={handleChangeCardNum2} />
                    <TextField size='small' variant='outlined' placeholder='' className={classes.divTextfield} name="cardnum3" onChange={handleChangeCardNum3} />
                    <TextField size='small' variant='outlined' placeholder='' className={classes.divTextfield} name="cardnum4" onChange={handleChangeCardNum4} />
                  </div>
                  <p className={classes.err} style={{ marginTop: 8 }}>{formGroupData?.map((fileds: any) => {
                    if (fileds.valid === false && fileds.field === 'card_Number') {
                      return fileds.message
                    } else {
                      return ''
                    }
                  })}</p>

                  {/* <div>
                  <TextField
                    className={classes.textfield}
                    variant="outlined"
                    placeholder="ENTER CARD NUMBER"
                    fullWidth
                    margin="normal"
                    name="card_Number"
                    size="small"
                    value={paymentData?.card_Number}
                    onChange={handleChangeStepTwo}
                    onKeyPress={(e) => formats(paymentData, e)}
                    onKeyUp={(e) => numberValidation(e)}
                  />
                  <p className={classes.err}>{formGroupData?.map((fileds: any) => {
                    if (fileds.valid === false && fileds.field === 'card_Number') {
                      return fileds.message
                    } else {
                      return ''
                    }
                  })}</p>
                </div> */}
                </div>
                <div>
                  <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: 25 }}>
                    <div style={{ marginRight: 15 }}>
                      <Typography className={classes.cardNumber} >EXPIRY MONTH</Typography>
                      <MonthPicker
                        name='card_ExpMonth'
                        defaultValue={"MM"}
                        numeric // to get months as numbers
                        endYearGiven // mandatory if end={} is given in YearPicker
                        year={paymentData.cardDetails?.card_ExpYear} // mandatory
                        value={paymentData.cardDetails?.card_ExpMonth} // mandatory
                        onChange={(month: any) => handleMonthChange(month)}
                        id={"month"}
                        classes={`dropdown ${dayError ? "error" : ""}`}
                        optionClasses={"option"}
                      />

                    </div>
                    <div style={{ marginRight: 75 }}>
                      <Typography className={classes.cardNumber}>EXPIRY YEAR</Typography>
                      <YearPicker
                        name='card_ExpYear'
                        defaultValue={"YYYY"}
                        start={2010} // default is 1900
                        end={2050} // default is current year
                        // default is ASCENDING
                        value={paymentData.cardDetails?.card_ExpYear} // mandatory
                        onChange={(year: any) => handleYearChange(year)}
                        id={"year"}
                        classes={`dropdown1 ${yearError ? "error" : ""}`}
                        optionClasses={"option"}
                      />
                    </div>
                    <div>
                      <Typography className={classes.cardNumber}>CVV</Typography>
                      <div>
                        <input
                          type="password"
                          name="card_CVC"
                          max={3}
                          placeholder="CVV"
                          value={paymentData?.cardDetails?.card_CVC}
                          className="dropdown" onChange={handleChangeCvv} />
                        {/* <TextField
                      className={classes.expiryDate}
                      variant="outlined"
                      placeholder="CVV"
                      fullWidth
                      margin="normal"
                      name="card_CVC"
                      size="small"
                      type="password"
                      value={paymentData?.card_CVC}
                      onChange={handleChangeStepTwo}
                    /> */}
                        <p className={classes.err}>{formGroupData?.map((fileds: any) => {
                          if (fileds.valid === false && fileds.field === 'card_CVC') {
                            return fileds.message
                          } else {
                            return ''
                          }
                        })}
                        </p>

                      </div>
                    </div>

                    {/* <div>
                    <TextField
                      className={classes.expiryDate}
                      variant="outlined"
                      placeholder="MM/YY"
                      fullWidth
                      margin="normal"
                      name="date"
                      size="small"
                      id="date"
                      value={paymentData?.date}
                      onChange={handleChangeStepTwo}
                      onKeyUp={(event) => formatString(event)}
                    />
                    <p className={classes.err}>{formGroupData?.map((fileds: any) => {
                      if (fileds.valid === false && fileds.field === 'date') {
                        return fileds.message
                      } else {
                        return ''
                      }
                    })}</p>
                  </div> */}
                  </div>
                </div>

              </div>

              <div style={{ display: 'grid', justifyContent: 'center' }}>
                <Button size='small' variant='outlined' className={classes.paynow} onClick={handlePayment}>Pay Now</Button>
                {/* <Button size='small' variant='outlined' className={classes.paynow} 
                 onClick={paywithOutCard}
                >Pay Without Card</Button> */}
              </div>
            </Grid>

          </Grid>

        </Card >
        <div style={{ marginLeft: '700px' }}>
          <Button
            variant='outlined'
            size='small'
            className={classes.button}
            onClick={() => history.push("/v1/signup/chooseplan")}
          >
            Back
          </Button>
        </div>
      </div>
    </>
  )
}

export default Checkout
import React from 'react'
// import { IoLocationOutline } from 'react-icons/io'
import { CardContent, Typography, Button, Grid, IconButton } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import IoLocationOutline from 'react-icons/io';
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import { BsCheckCircleFill } from 'react-icons/bs'
import { useStyles } from './style'
import './style.css'
import SupportServices from "./SupportServices/services"
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import Backdrop from '../../components/Modal/Backdrop';
import axiosInstance from '../../environment/axiosInstances'
import Modal from '../../components/Modal/Modal'
import TextScroller from '../Layout/Textscroll'
import Loader from '../../assets/images/ZZ5H.gif'
function SupportPage() {
  const classes = useStyles();
  const history = useHistory()
  const [loading, setLoading] = React.useState(false)
  const [getSignupDetails, setFetSignupDetails] = React.useState<any>({})
  const [load, setLoad] = React.useState(false)
  const [load1, setLoad1] = React.useState(false)
  const [val, setVal] = React.useState('')

  const onClickChooseLocalType = () => {
    setVal('1')
    setLoad(true)
    var numberType: any = {}
    numberType["numberType"] = "1"
    SupportServices.patchNumberType(numberType, localStorage.getItem("token")).then((res) => {
      if (res.success) {
        history.push("/signup/cdn1/phone-number/local")
        setLoad(false)
        setVal('')
      }
    }).catch((err) => {
      if (err.request.status === 402) {
        window.location.href = "https://efone.ca/pricing"
        localStorage.clear()
        setLoad(false)
      }
    })
  }

  const onClickChooseNumberType = () => {
    setVal('2')
    setLoad1(true)
    var numberType: any = {}
    numberType["numberType"] = "3"
    SupportServices.patchNumberType(numberType, localStorage.getItem("token")).then((res) => {
      if (res.success) {
        history.push({ pathname: '/signup/cdn1/phone-number/port/number', state: "Numbers" })
        setVal('')
        setLoad1(false)
      }
    }).catch((err) => {
      if (err.request.status === 402) {
        window.location.href = "https://efone.ca/pricing"
        localStorage.clear()
        setLoad1(false)
      }
    })
  }

  React.useEffect(() => {
    setLoading(true);
    axiosInstance.get(`/sign-up?token=${localStorage.getItem("token")}`).then((res: any) => {
      if (res.data.data) {
        setFetSignupDetails(res.data?.data?.[0])
      } else {
        setLoading(false)
      }
    }).catch((err: any) => {
      setLoading(false)
      if (err.request.status === 402) {
        window.location.href = "https://efone.ca/pricing"
        localStorage.clear()
      }
    })
  }, [])
  var number = getSignupDetails?.phoneNumber
  if (number) {
    var cleaned = ('' + number).replace(/\D/g, '');
    var match: any = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    number = ['(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  var newNumber =
    getSignupDetails?.numberType === 3
      ? getSignupDetails?.temporaryNumber && getSignupDetails?.temporaryNumber
      : "";
  if (newNumber) {
    var cleaned = ("" + newNumber).replace(/\D/g, "");
    var match: any = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    newNumber = ["(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return (
    <>
      <div>
        <div className="fixed">
        </div>
        <div>
          {getSignupDetails?.phoneNumber && getSignupDetails.numberType === 1 ? <TextScroller text={number} val="phone" /> : ""}
          {getSignupDetails?.temporaryNumber && getSignupDetails.numberType === 3 ? <TextScroller text={newNumber} val="temp" /> : ""}

        </div>
        <CardContent>
          {/* <IoLocationOutline /> */}
          {getSignupDetails?.numberType === 3 ?
            <CardContent style={{ marginTop: '10px', marginLeft: 30, display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <MobileScreenShareIcon style={{ color: "#377dff" }} />
                <Typography className={classes.type}>Transfer a Number</Typography>
              </div>
              <Typography className={classes.type} style={{ float: "right" }}>{getSignupDetails.numberType === 3 && getSignupDetails?.temporaryNumber ? newNumber : number}</Typography>
            </CardContent> :
            <CardContent style={{ marginTop: '10px', marginLeft: 30, display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <FmdGoodOutlinedIcon style={{ color: "#377dff" }} />
                <Typography className={classes.type}>Local Number</Typography>
              </div>
              <Typography className={classes.type} style={{ float: "right" }}>{number}</Typography>
            </CardContent>}
        </CardContent>

        <div style={{ textAlign: 'center', marginTop: '8%' }}>
          <div>
            <Button
              className={val === "1" ? classes.localNumberActive : classes.localNumber}
              onClick={onClickChooseLocalType}
              style={{ backgroundColor: load ? '#4D72F8' : "white", color: load ? 'white' : "#4D72F8" }}
              startIcon={load ? <img src={Loader} alt="loading..." style={{ height: "20px", marginTop: "7px", color: "lightgray" }} /> : <FmdGoodOutlinedIcon style={{ color: "black" }} />}
            >Local Number</Button>
          </div>
          <div>
            <Button
              startIcon={load1 ? <img src={Loader} alt="loading..." style={{ height: "20px", marginTop: "7px", color: "lightgray" }} /> : <MobileScreenShareIcon style={{ color: "black" }} />}
              style={{ backgroundColor: load1 ? '#4D72F8' : "white", color: load1 ? 'white' : "#4D72F8" }}
              className={classes.transNumber}
              onClick={onClickChooseNumberType}
            >Transfer a Number
            </Button>
          </div>
        </div>
        {/* <FooterComponent/> */}
        <div className="number-block-btm">
          <Grid container spacing={4} style={{ position: "sticky" }}>
            <Grid item lg={4}>
              <IconButton className={classes.nextIconActive}
                onClick={() => history.push(`/signup/cdn1/email`)}
              >
                <GrFormPreviousLink className={classes.icon} />
              </IconButton>
            </Grid>
            <Grid item lg={4}>
              <div className="number-dots ">
                <div className="number-dot-item dot-blue">
                  <BsCheckCircleFill />
                </div>
                <div className="number-dot-item active">
                  <BsCheckCircleFill />
                </div>
                <div className="number-dot-item">
                  <BsCheckCircleFill />
                </div>
                <div className="number-dot-item ">
                  <BsCheckCircleFill />
                </div>
                <div className="number-dot-item">
                  <BsCheckCircleFill />
                </div>
              </div>

            </Grid>
            <Grid item lg={4}>

            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}

export default SupportPage

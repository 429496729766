import React from 'react'
import { Card, Tab, Tabs } from '@mui/material'
import { Button, Container, Grid, Radio, TextField, Tooltip, Typography } from "@material-ui/core"
import { useStyles } from './style'
import { TabContext, TabPanel } from '@material-ui/lab';
import ReactSelect from 'react-select'
import StepTwo from '../Steps/StepTwo'
import ExistingNumbers from '../ExistingNumbers/ExistingNumbers';
import SignupServices from '../SignupServices';
import ReNumbers from '../ReNumbers/ReNumbers';
import { useHistory } from 'react-router-dom';
import './myst.css'
import ReactTelephoneInput from 'react-telephone-input'
import logo from '../../../assets/images/logo.png'
import Flags from '../../../assets/flags.png'
const countryOptions = [
    { label: "canada", value: 'CAN' },
    { label: "united states", value: 'USA' },
];
import { BiEdit } from 'react-icons/bi';
import { signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth'
import { auth } from '../firebase/firebase';
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal';
import otp from '../../../assets/images/otp.png'
import PhoneNumberOtp from './PhoneNumberOtp/PhoneNumberOtp';
import v5axiosInstaces from '../../../environment/v5axiosInstances'
import * as popUpActionCreator from "../../../store/Actions/popup-actionCreator";
import { connect } from "react-redux";
import SuccessFullPage from './SuccessFullPage';


function ReserveNumbers(props: any) {
    const history = useHistory();
    const [value, setValue] = React.useState("2")

    const classes = useStyles();
    const [city, setCity] = React.useState<any>([]);
    const [country, setCountry] = React.useState<any>({ label: "Canada", value: 'CAN' });
    const [cities, setCities] = React.useState([]);
    const [area, setArea] = React.useState<any>([]);
    const [state, setState] = React.useState<any>([]);
    const [states, setStates] = React.useState([]);
    const [localNumbers, setLocalNUmbers] = React.useState<any>([]);
    const [repeatNumber, setRepaetNumber] = React.useState<any>([]);
    const [index, setIndex] = React.useState(0)
    const [loading, setLoading] = React.useState(false)
    const [Repaetindex, setRepeatIndex] = React.useState(0)
    const [id, setId] = React.useState('')
    const [citiesOptions, setCityOptions] = React.useState([]);
    const [Number, setSelectedNumber] = React.useState();
    const [reserveNumber, setreserveNumber] = React.useState(false);
    // const handleSelectedNumber = (number: any) => {
    //     setSelectedNumber(number)
    // }
    const handleTabs = (e: any, newValue: any) => {
        setValue(newValue);
    };
    const [openModal, setOpenModal] = React.useState(false)
    const [number, setNumber] = React.useState<any>('')
    const [error, setError] = React.useState("")
    const [otp, setOtp] = React.useState("")
    const [flag, setFlag] = React.useState(false)
    const [confirmObj, setConfirmObj] = React.useState<any>("")
    const [token, setToken] = React.useState<any>("")
    function handleInputBlur(telNumber: any, selectedCountry: any) {
        console.log(
            'Focus off the ReactTelephoneInput component. Tel number entered is: ',
            telNumber,
            ' selected country is: ',
            selectedCountry
        )
    }
    const handleVerifyModal = () => {
        setOpenModal(!openModal)
    }

    //firebase authentication
    const verifyCaptcha = (number: any) => {
        const recaptchaVerifier = new RecaptchaVerifier(
            'recaptcha-container',
            {},
            auth
        );
        recaptchaVerifier.render()
        return signInWithPhoneNumber(auth, number, recaptchaVerifier)

    }

    const getOtp = async () => {
        // // setError("")
        // // if (number === "" || number === undefined) return setError("please enter valid Phone Number")
        try {
            const response: any = await verifyCaptcha(number)
            setConfirmObj(response)
            setFlag(true)
        } catch (err) {
            console.log('err', err);
            // setError(err)
        }
    }

    const verifyOtp = async () => {
        // if (otp === "" || otp === undefined) {
        //     return;
        // }
        try {
           const res= await confirmObj.confirm(otp)
            const id = res?._tokenResponse?.idToken
            setToken(id)
            verifyToken(id)
            // history.push('/v1/signup/chooseplan')
        } catch (err) {
            console.log('error', err);
        }
    }

    const verifyToken = (token:any) => {
        const sendToken : any = {
            accountId:id,
            idToken: token
        }
        v5axiosInstaces.post('/auth/web-verify-id-token', sendToken).then((res:any) => {
            console.log('kkk',res);
            if(res.status === 200) {        
                history.push('/v1/signup/successfull')
            }else{
                props.popUpAlert(res.data?.message, "UPDATE_AGENT_SUCCESS", "error");
            }
        }).catch((err:any) => {
            props.popUpAlert(err.response?.data?.message, "UPDATE_AGENT_SUCCESS", "error");

        })
    }

    React.useEffect(() => {
        const Id: any = localStorage.getItem("accountId")
        setId(Id)
        if (Id) {
            const newId = JSON.parse(Id)
            setId(newId)
        }
    }, [])

    // console.log('token', token);
    



    return (
        <>
            {/* {
                openModal && (
                    <div>
                        <Backdrop handlePopupModal={handleVerifyModal} />
                        <Modal handlePopupModal={handleVerifyModal} >
                            <div className="add_user_popup" style={{ width: "500px" }}>
                                <Grid className='mike' container spacing={2}>
                                    <Grid item md={12} sm={12} xs={12} style={{ padding: '20px' }}>
                                        <div>
                                            <div className='modalimgholder'>
                                                <img className='otpimg' alt='otp' src={otp} />
                                                <span>Otp Verification</span>
                                            </div>
                                            <PhoneNumberOtp
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Modal>
                    </div>

                )
            } */}

            <div className={classes.logo}>
                <img alt='logo' className='logoimgmob' src={logo} />
            </div>
            <div className='pagetit reservepage'>
                <h1>Register</h1>
                <span>Stay connected with the best VoIP call application from anywhere using cost-effective plans.</span>
            </div>
            <StepTwo />
            <Container>
                <div className={classes.cardPadding + " " + "reserveholder"}>
                    <TabContext value={value}>
                        <Card className='reservecard'>
                            <Tabs
                                className={classes.tabs + " " + "mobtab"}
                                value={value}
                                onChange={handleTabs}
                                variant="fullWidth"
                                TabIndicatorProps={{
                                    style: {
                                        height: "0px",
                                    },
                                }}
                            >
                            <Tab style={{ textTransform: 'capitalize', fontWeight: 500, fontSize: '14px' }}
                                    label="Use your own number"
                                    value="2"
                                    className={value === "2" ? classes.tab + " " + "mobtabnotactive" : classes.tabActive + " " + "mobtabactive"}
                                />
                            <Tab style={{ textTransform: 'capitalize', fontWeight: 500, fontSize: '14px' }}
                                    label="Buy number"
                                    value="1"
                                    className={value === "1" ? classes.tab + " " + "mobtabnotactive" : classes.tabActive + " " + "mobtabactive"}
                                />

                            </Tabs>

                            <TabPanel value="1">
                                <ReNumbers />
                            </TabPanel>
                            <TabPanel value="2">
                                <div className={classes.mt} style={{ display: !flag ? "block" : "none" }}>
                                    <ReactTelephoneInput
                                        variant="outlined"
                                        size='medium'
                                        max={10}
                                        style={{ width: "100%" }}
                                        value={number}
                                        defaultCountry="us"
                                        flagsImagePath={Flags}
                                        onChange={setNumber}
                                        onBlur={handleInputBlur}
                                    />
                                    <div id="recaptcha-container" style={{ marginTop: 5 }} />
                                    <Button
                                        onClick={getOtp}
                                        className={classes.btn}
                                        variant='outlined'
                                        size='medium'>
                                        Send OTP
                                    </Button>
                                </div>
                                <div className={classes.mt} style={{ display: flag ? "block" : "none" }}>
                                    <div >
                                        <TextField
                                            variant="outlined"
                                            size='small'
                                            placeholder='Enter otp'
                                            type="otp"
                                            value={otp}
                                            onChange={(e: any) => setOtp(e.target.value)}
                                        />
                                        <Button
                                            onClick={verifyOtp}
                                            className={classes.verifybtn}
                                            variant='outlined'
                                            size='medium'>
                                            Verify OTP
                                        </Button>
                                    </div>
                                </div>

                            </TabPanel>
                            {/* <ExistingNumbers
                            countryOptions={countryOptions} onChangeStateType={onChangeStateType}
                            states={states} state={state}
                            country={country}
                            citiesOptions={citiesOptions}
                            onChangeCountryType={onChangeCountryType} onChangeCity={onChangeCity}
                            city={city} localNumbers={localNumbers}
                            fetchNumberLists={props.fetchNumberLists}
                            loading={loading}
                            handleChangeNumbers={handleChangeNumbers}
                            index={index}
                            selectedNumber={props.selectedNumber}
                            handleSelectedNumber={handleSelectedNumber}
                            reserveNumber={reserveNumber}
                        /> */}
                        </Card>
                    </TabContext>

                </div>
            </Container>
        </>
    )
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        popUpAlert: (err: any, instance: any, type: any) => dispatch(popUpActionCreator.initglobalPopup(err, instance, type))
    }
}
export default connect(null, mapDispatchToProps)(ReserveNumbers)


import React from "react";
import {
  Card,
  Tabs,
  Tab,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  CardContent,
  Divider,
  Button,
  IconButton,
} from "@material-ui/core";

import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import ReactSelect from "react-select";
import PhoneIcon from "@material-ui/icons/Phone";
import { GrFormNextLink } from "react-icons/gr";
import { BiRefresh } from 'react-icons/bi'
import { useStyles } from "../../style";


function CountryStates(props: any) {
  const classes = useStyles();
  const [coutry, setcoutry] = React.useState()
  React.useEffect(() => {
    if (props.country) {
      const filtercountry = props.countryOptions.filter((itm: any) => itm.value === props.country)
      setcoutry(filtercountry)
    }
  }, [props.country])
  return (
    <div>
      <Grid container>
        <Grid item md={12} xs={12}>
          <Typography className={classes.header}>Country</Typography>
          <FormControl fullWidth size="small" variant="outlined">
            <ReactSelect
              fullWidth
              options={props.countryOptions}
              native
              value={props.country}
              placeholder="Select Country"
              inputProps={{ "aria-label": "Without label" }}
              onChange={props.onChangeCountryType}
            />
          </FormControl>
          <Typography className={classes.headerOne}>State</Typography>
          <FormControl fullWidth size="small" variant="outlined">
            <ReactSelect
              native
              fullWidth
              options={props.states}
              value={props.state}
              placeholder="Select State"
              inputProps={{ "aria-label": "Without label" }}
              onChange={props.onChangeStateType}
            ></ReactSelect>
          </FormControl>
        </Grid>
      </Grid>
    </div>

  )
}

export default CountryStates;
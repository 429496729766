import { Grid, TextField, Typography, Button, Container } from '@material-ui/core'
import { Google, Password } from '@mui/icons-material';
import { Card, InputAdornment } from '@mui/material';
import { FcGoogle } from 'react-icons/fc'
import logo from '../../../assets/images/logo.png'
import otp from '../../../assets/images/otp.png'
import regmobbanner from '../../../assets/images/reg1.jpg'

import React from 'react'
import { FiEye, FiEyeOff } from 'react-icons/fi';
import image2 from '../../../assets/images/image2.png';
import StepOne from '../Steps/StepOne';
import { useStyles } from './style'
import './myst.css'
import * as popUpActionCreator from "../../../store/Actions/popup-actionCreator";
import { connect } from "react-redux";


// import './styles.css'
import {
    createUserWithEmailAndPassword,
    sendEmailVerification,
    sendPasswordResetEmail,
    onAuthStateChanged,
    applyActionCode,
    GoogleAuthProvider,
    signInWithPopup,
    getAuth,
    signOut,
    signInWithEmailLink
} from "firebase/auth"
import { auth } from '../firebase/firebase'
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal';
import SidebarPopUpModal from '../../../components/SidebarPopUpModel/SidebarPopUpModal';
import VerifyLink from '../VrifyLink/VerifyLink';
import axios from 'axios';
import axiosInstance from '../../../environment/axiosInstances'
import v5axiosInstance from '../../../environment/v5axiosInstances'
import { useHistory } from 'react-router-dom';
import SignupServices from '../SignupServices';

const formGroup: any = [
    {
        field: 'email',
        valid: true,
        message: ''
    },
    {
        field: 'password',
        valid: true,
        message: ''
    },
  
]

function Login(props: any) {
    const classes = useStyles();
    const history = useHistory()
    const [showPw, setShowPw] = React.useState(false)
    const [showConfirmPw, setShowConfirmPw] = React.useState(false)
    const [openModal, setOpenModal] = React.useState(false)
    const [userId, setUserId] = React.useState('')
    const [sendOtp, setSendOtp] = React.useState('')
    const [formGroupData, setFormgroup] = React.useState<any>(formGroup)
    const [data, setData] = React.useState<any>({
        email: "",
        password: "",
    })

    const habdleshowPW = () => {
        setShowPw(!showPw)
    }

    const habdleshowConfirmPW = () => {
        setShowConfirmPw(!showConfirmPw)
    }

    //signup api
    const handleClick = () => {
        const sendData: any = {
            email: data.email,
            password: data.password,
            fullName: data.fullName,
            confirmPassword: data.confirmPassword
        }
        validateForm({ property: 'email', value: data.email })

        validateForm({ property: 'password', value: data.password })
        const [email, password] = formGroupData;
        if (email.valid && password.valid) {
            v5axiosInstance.post('/auth/send-email-otp', sendData).then((res: any) => {
                if (res.status === 201) {
                    const id = res.data.Data[0].userId
                    localStorage.setItem('verifiedId', JSON.stringify(id))
                    const otp = res.data.Data[0].otp
                    setSendOtp(otp)
                    setUserId(id)
                    handleVerifyModal()
                    props.popUpAlert(res.data.message, "UPDATE_AGENT_SUCCESS", "success");
                } else {
                    props.popUpAlert(res.data.message, "UPDATE_AGENT_SUCCESS", "success");
                }
            }).catch(err => {
                props.popUpAlert(err.message, "UPDATE_AGENT_SUCCESS", "error");
            })
        }
    }

    //validations
    const validateForm = (dataToValidate: any) => {
        let formgroupCopy = [...formGroupData];
        function validateRegexEmail(email: any) {
            // eslint-disable-next-line
            const re: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }

        if (dataToValidate.property === 'email') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'email') {
                        formG.valid = false;
                        formG.message = 'Email should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else if (dataToValidate.value !== '' && !validateRegexEmail(dataToValidate.value)) {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'email') {
                        formG.valid = false;
                        formG.message = 'Please provide valid email address'
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'email') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        //fullname
        if (dataToValidate.property === 'fullName') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'fullName') {
                        formG.valid = false;
                        formG.message = 'FullName should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            }
            else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'fullName') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'password') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'password') {
                        formG.valid = false;
                        formG.message = 'Password should not be empty'
                    }
                })
                setFormgroup(formgroupCopy)
            } else if (dataToValidate.value !== '' && dataToValidate.value.length <= 5) {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'password') {
                        formG.valid = false;
                        formG.message = 'Password must be greater than or equal to  6 characters'
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'password') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        // if (dataToValidate.property === 'confirmPassword') {
        //   if (dataToValidate.value === '') {
        //     formgroupCopy.forEach((formG: any) => {
        //       if (formG.field === 'confirmPassword') {
        //         formG.valid = false;
        //         formG.message = 'Password should not be empty'
        //       }
        //     })
        //     setFormgroup(formgroupCopy)
        //   } else if (dataToValidate.value !== '' && dataToValidate.value.length <= 5) {
        //     formgroupCopy.forEach((formG: any) => {
        //       if (formG.field === 'confirmPassword') {
        //         formG.valid = false;
        //         formG.message = 'Password must be greater than or equal to  6 characters'
        //       }
        //     })
        //     setFormgroup(formgroupCopy)
        //   } else {
        //     formgroupCopy.forEach((formG: any) => {
        //       if (formG.field === 'password') {
        //         formG.valid = true;
        //         formG.message = ''
        //       }
        //     })
        //     setFormgroup(formgroupCopy)
        //   }
        // }
    }


    const handleChange = (e: any) => {
        const val = e.target.value
        if (val !== " ") {
            setData((prev: any) => {
                return {
                    ...data,
                    [e.target.name]: e.target.value
                }
            })
        }
        validateForm({ property: e.target.name, value: e.target.value })
    }





    const handleVerifyModal = () => {
        setOpenModal(!openModal)
    }

    const getUserData = (id: any) => {
        SignupServices.fetchUserData(id).then((res: any) => {
            if (res.success) {
                setData((prev: any) => {
                    return {
                        email: res.data?.email,
                        fullName: res.data.fullName,
                        // password: res.data?.password,
                    }
                })
            } else {

            }
        }).catch((err: any) => {

        });
    }

    React.useEffect(() => {
        const userid: any = localStorage.getItem("verifiedId")
        if (userid !== "undefined") {
            getUserData(JSON.parse(userid))
        }

    }, [])

    return (
        <>
            <div className={classes.logo}>
                <img className='logoimgmob' alt='logo' src={logo} />
            </div>
            <div className='regmobbanner'>
                <img className='regmobbannerimg' alt='logo' src={regmobbanner} />

            </div>
            <div className='pagetit'>
                <h1>Login</h1>
                <span className='regsubtitmob'>Stay connected with the best VoIP call application from anywhere using cost-effective plans.</span>
            </div>
            <div className={classes.margings}>
                {/* <StepOne /> */}
                <Container>
                    <Card className={classes.card + " " + "regcardmob"}>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12} className='leftcard'>
                                <Typography className={classes.email}>Email</Typography>
                                <TextField
                                    className={classes.textfield}
                                    size='small'
                                    variant='outlined'
                                    placeholder='Enter email'
                                    name='email'
                                    value={data?.email}
                                    onChange={handleChange}
                                />
                                <p className={classes.err}>{formGroupData.map((fileds: any) => {
                                    if (fileds.valid === false && fileds.field === 'email') {
                                        return fileds.message
                                    } else {
                                        return ''
                                    }
                                })}</p>
                                <div className={classes.div2}>
                                    <Typography className={classes.Password}>Password</Typography>
                                    <TextField
                                        type={showPw ? "text" : "password"}
                                        size='small'
                                        className={classes.create}
                                        name="password"
                                        value={data?.password}
                                        onChange={handleChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    {showPw ? <FiEye onClick={habdleshowPW} style={{ cursor: "pointer", marginLeft: "-24px", marginTop: "-5px" }} /> :
                                                        <FiEyeOff onClick={habdleshowPW} style={{ cursor: "pointer", marginLeft: "-24px", marginTop: "-5px", color: '#4D72F8' }} />}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <p className={classes.err}>{formGroupData.map((fileds: any) => {
                                        if (fileds.valid === false && fileds.field === 'password') {
                                            return fileds.message
                                        } else {
                                            return ''
                                        }
                                    })}</p>
                                </div>
                           

                                <Button
                                    size='small'
                                    fullWidth variant='outlined'
                                    className={classes.continue}
                                    onClick={handleClick}
                                >
                                    Submit
                                </Button>
                            </Grid>
                            
                        </Grid>
                    </Card>
                </Container>
            </div>
            <div className='foot'>© 2023 eFone. All rights reserved.</div>
        </>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        popUpAlert: (err: any, instance: any, type: any) => dispatch(popUpActionCreator.initglobalPopup(err, instance, type))
    }
}
export default connect(null, mapDispatchToProps)(Login)


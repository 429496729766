import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    dflex: {
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        backgroundColor: "#32dcd3",
        height: '100vh'
    },
    maincontainer: {
        width: 550,
        height: 350,
        borderRadius: 4,
        border: '3px dashed #595053',
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        background:''
    },
    title1: {
        fontFamily: "Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
        fontSize:25,
        fontWeight:800,
        color:'#a4540c',
        fontStyle:"italic"
    },
    title2: {
        fontFamily: "Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
        fontSize:18,
        fontWeight:800,
        
    }
}))
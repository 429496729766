import { Grid, TextField, Typography, Button, Container } from '@material-ui/core'
import { Google, Password } from '@mui/icons-material';
import { Card, InputAdornment } from '@mui/material';
import { FcGoogle } from 'react-icons/fc'
import logo from '../../../assets/images/logo.png'
import otp from '../../../assets/images/otp.png'
import regmobbanner from '../../../assets/images/reg1.png'

import React from 'react'
import { FiEye, FiEyeOff } from 'react-icons/fi';
import image2 from '../../../assets/images/image2.png';
import StepOne from '../Steps/StepOne';
import { useStyles } from './style'
import './myst.css'
import * as popUpActionCreator from "../../../store/Actions/popup-actionCreator";
import { connect } from "react-redux";


// import './styles.css'
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  onAuthStateChanged,
  applyActionCode,
  GoogleAuthProvider,
  signInWithPopup,
  getAuth,
  signOut,
  signInWithEmailLink
} from "firebase/auth"
import { auth } from '../firebase/firebase'
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal';
import SidebarPopUpModal from '../../../components/SidebarPopUpModel/SidebarPopUpModal';
import VerifyLink from '../VrifyLink/VerifyLink';
import axios from 'axios';
import axiosInstance from '../../../environment/axiosInstances'
import v5axiosInstance from '../../../environment/v5axiosInstances'
import { useHistory } from 'react-router-dom';
import VerifyOtp from './VerifyOtp/VerifyOtp';
import SignupServices from '../SignupServices';

const formGroup: any = [
  {
    field: 'email',
    valid: true,
    message: ''
  },
  {
    field: 'fullName',
    valid: true,
    message: ''
  },
  {
    field: 'password',
    valid: true,
    message: ''
  },
  {
    field: 'confirmPassword',
    valid: true,
    message: ''
  },
]

function Register(props: any) {
  const classes = useStyles();
  const history = useHistory()
  const [showPw, setShowPw] = React.useState(false)
  const [showConfirmPw, setShowConfirmPw] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(false)
  const [userId, setUserId] = React.useState('')
  const [sendOtp, setSendOtp] = React.useState('')
  const [formGroupData, setFormgroup] = React.useState<any>(formGroup)
  const [data, setData] = React.useState<any>({
    email: "",
    fullName: "",
    password: "",
    confirmPassword: "",
  })

  const habdleshowPW = () => {
    setShowPw(!showPw)
  }

  const habdleshowConfirmPW = () => {
    setShowConfirmPw(!showConfirmPw)
  }

  //signup api
  const handleClick = () => {
    const sendData: any = {
      email: data.email,
      password: data.password,
      fullName: data.fullName,
      confirmPassword: data.confirmPassword
    }
    validateForm({ property: 'email', value: data.email })
    validateForm({ property: 'fullName', value: data.fullName })
    validateForm({ property: 'password', value: data.password })
    validateForm({ property: 'confirmPassword', value: data.confirmPassword })
    const [email, password, fullName, confirmPassword] = formGroupData;
    if (email.valid && fullName.valid && password.valid && confirmPassword.valid) {
      v5axiosInstance.post('/auth/email-request', sendData).then((res: any) => {
        if (res.data?.statusCode === 201) {
          const id = res.data.data.accountId
          localStorage.setItem('accountId', JSON.stringify(id))
          // const otp = res.data.Data[0].otp
          // setSendOtp(otp)
          // setUserId(id)
          // handleVerifyModal()
          history.push(`/v1/signup/reservenumber`)
          props.popUpAlert(res.data.message, "UPDATE_AGENT_SUCCESS", "success");
        } 
        else {
          props.popUpAlert(res.data.message, "UPDATE_AGENT_SUCCESS", "success");
        }
      }).catch(err => {
        if(err.response?.data){
          props.popUpAlert(err.response?.data.message, "UPDATE_AGENT_SUCCESS", "error"); 
        }else{
          props.popUpAlert(err.message, "UPDATE_AGENT_SUCCESS", "error");
        }
      })
    }
  }

  //validations
  const validateForm = (dataToValidate: any) => {
    let formgroupCopy = [...formGroupData];
    function validateRegexEmail(email: any) {
      // eslint-disable-next-line
      const re: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }

    if (dataToValidate.property === 'email') {
      if (dataToValidate.value === '') {
        formgroupCopy.forEach((formG: any) => {
          if (formG.field === 'email') {
            formG.valid = false;
            formG.message = 'Email should not be empty';
          }
        })
        setFormgroup(formgroupCopy)
      } else if (dataToValidate.value !== '' && !validateRegexEmail(dataToValidate.value)) {
        formgroupCopy.forEach((formG: any) => {
          if (formG.field === 'email') {
            formG.valid = false;
            formG.message = 'Please provide valid email address'
          }
        })
        setFormgroup(formgroupCopy)
      } else {
        formgroupCopy.forEach((formG: any) => {
          if (formG.field === 'email') {
            formG.valid = true;
            formG.message = ''
          }
        })
        setFormgroup(formgroupCopy)
      }
    }
    //fullname
    if (dataToValidate.property === 'fullName') {
      if (dataToValidate.value === '') {
        formgroupCopy.forEach((formG: any) => {
          if (formG.field === 'fullName') {
            formG.valid = false;
            formG.message = 'FullName should not be empty';
          }
        })
        setFormgroup(formgroupCopy)
      }
      else {
        formgroupCopy.forEach((formG: any) => {
          if (formG.field === 'fullName') {
            formG.valid = true;
            formG.message = ''
          }
        })
        setFormgroup(formgroupCopy)
      }
    }
    if (dataToValidate.property === 'password') {
      if (dataToValidate.value === '') {
        formgroupCopy.forEach((formG: any) => {
          if (formG.field === 'password') {
            formG.valid = false;
            formG.message = 'Password should not be empty'
          }
        })
        setFormgroup(formgroupCopy)
      } else if (dataToValidate.value !== '' && dataToValidate.value.length <= 5) {
        formgroupCopy.forEach((formG: any) => {
          if (formG.field === 'password') {
            formG.valid = false;
            formG.message = 'Password must be greater than or equal to  6 characters'
          }
        })
        setFormgroup(formgroupCopy)
      } else {
        formgroupCopy.forEach((formG: any) => {
          if (formG.field === 'password') {
            formG.valid = true;
            formG.message = ''
          }
        })
        setFormgroup(formgroupCopy)
      }
    }
    // if (dataToValidate.property === 'confirmPassword') {
    //   if (dataToValidate.value === '') {
    //     formgroupCopy.forEach((formG: any) => {
    //       if (formG.field === 'confirmPassword') {
    //         formG.valid = false;
    //         formG.message = 'Password should not be empty'
    //       }
    //     })
    //     setFormgroup(formgroupCopy)
    //   } else if (dataToValidate.value !== '' && dataToValidate.value.length <= 5) {
    //     formgroupCopy.forEach((formG: any) => {
    //       if (formG.field === 'confirmPassword') {
    //         formG.valid = false;
    //         formG.message = 'Password must be greater than or equal to  6 characters'
    //       }
    //     })
    //     setFormgroup(formgroupCopy)
    //   } else {
    //     formgroupCopy.forEach((formG: any) => {
    //       if (formG.field === 'password') {
    //         formG.valid = true;
    //         formG.message = ''
    //       }
    //     })
    //     setFormgroup(formgroupCopy)
    //   }
    // }
  }


  const handleChange = (e: any) => {
    const val = e.target.value
    if (val !== " ") {
      setData((prev: any) => {
        return {
          ...data,
          [e.target.name]: e.target.value
        }
      })
    }
    validateForm({ property: e.target.name, value: e.target.value })
  }



  

  const handleVerifyModal = () => {
    setOpenModal(!openModal)
  }

  const getUserData = (id: any) => {
    SignupServices.fetchUserData(id).then((res: any) => {
      if (res.success) {
        setData((prev: any) => {
          return {
            email: res.data?.email,
            fullName: res.data.fullName,
            // password: res.data?.password,
          }
        })
      } else {

      }
    }).catch((err: any) => {

    });
  }

  React.useEffect(() => {
    const userid: any = localStorage.getItem("verifiedId")
    if (userid !== "undefined") {
      getUserData(JSON.parse(userid))
    }
    
  }, [])

  return (
    <>
      {
        openModal && (
          <div>
            <Backdrop handlePopupModal={handleVerifyModal} />
            <Modal handlePopupModal={handleVerifyModal} >
              <div className="add_user_popup" style={{ width: "500px" }}>
                <Grid className='mike' container spacing={2}>
                  <Grid item md={12} sm={12} xs={12} style={{ padding: '20px' }}>
                    <div>
                      <div className='modalimgholder'>
                        <img className='otpimg' alt='otp' src={otp} />
                        <span>Email Verification</span>
                        <p>Enter OTP code  sent to {data.email} </p>
                      </div>
                      <VerifyOtp
                        userId={userId}
                        // email={data.email}
                        sendOtp={sendOtp}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Modal>
          </div>

        )
      }

      <div className={classes.logo}>
        <img className='logoimgmob' alt='logo' src={logo} />
      </div>
      <div className='regmobbanner'>
        <img className='regmobbannerimg' alt='logo' src={regmobbanner} />
      </div>
      <div className='pagetit'>
        <h1>Register</h1>
        <span className='regsubtitmob'>Stay connected with the best VoIP call application from anywhere using cost-effective plans.</span>
      </div>
      <div className={classes.margings}>
        <StepOne />
        <Container>
          <Card className={classes.card + " " + "regcardmob"}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12} className='leftcard'>
                {/* <Typography className={classes.email}>Email</Typography> */}
                <TextField
                  className={classes.textfield}
                  size='small'
                  variant='outlined'
                  placeholder='Enter email'
                  name='email'
                  value={data?.email}
                  onChange={handleChange}
                />
                <p className={classes.err}>{formGroupData.map((fileds: any) => {
                  if (fileds.valid === false && fileds.field === 'email') {
                    return fileds.message
                  } else {
                    return ''
                  }
                })}</p>
                {/* <Typography className={classes.email}>Full Name</Typography> */}
                <TextField
                  className={classes.textfield}
                  //fullWidth
                  size='small'
                  variant='outlined'
                  placeholder='Enter full Name'
                  name='fullName'
                  value={data?.fullName}
                  onChange={handleChange}
                />
                <p className={classes.err}>{formGroupData.map((fileds: any) => {
                  if (fileds.valid === false && fileds.field === 'fullName') {
                    return fileds.message
                  } else {
                    return ''
                  }
                })}</p>
                <div className={classes.div2}>
                  {/* <Typography className={classes.Password}>Password</Typography> */}
                  <TextField
                    type={showPw ? "text" : "password"}
                    size='small'
                    className={classes.create}
                    name="password"
                    placeholder='Password'
                    value={data?.password}
                    onChange={handleChange}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="start">
                          {showPw ? <FiEye onClick={habdleshowPW} style={{ cursor: "pointer", marginLeft: "-24px", marginTop: "-5px" }} /> :
                            <FiEyeOff onClick={habdleshowPW} style={{ cursor: "pointer", marginLeft: "-24px", marginTop: "-5px", color: '#ffffff73' }} />}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <p className={classes.err}>{formGroupData.map((fileds: any) => {
                    if (fileds.valid === false && fileds.field === 'password') {
                      return fileds.message
                    } else {
                      return ''
                    }
                  })}</p>
                </div>
                <div className={classes.div2}>
                  {/* <Typography className={classes.Password}>Confirm Password</Typography> */}
                  <TextField
                    type={showConfirmPw ? "text" : "password"}
                    size='small'
                    className={classes.create}
                    name="confirmPassword"
                    value={data?.confirmPassword}
                    placeholder="Confirm password"
                    onChange={handleChange}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="start">
                          {showConfirmPw ? <FiEye onClick={habdleshowConfirmPW} style={{ cursor: "pointer", marginLeft: "-24px", marginTop: "-5px" }} /> :
                            <FiEyeOff onClick={habdleshowConfirmPW} style={{ cursor: "pointer", marginLeft: "-24px", marginTop: "-5px", color: '#ffffff73' }} />}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <p className={classes.err}>{formGroupData.map((fileds: any) => {
                    if (fileds.valid === false && fileds.field === 'confirmPassword') {
                      return fileds.message
                    } else {
                      return ''
                    }
                  })}</p>
                </div>

                <Button
                  size='small'
                  fullWidth variant='outlined'
                  className={classes.continue}
                  onClick={handleClick}
                >
                  Continue
                </Button>
                <div className="header__center">Or sign up with</div>
                <Button
                  className={classes.google}
                  startIcon={<FcGoogle />}
                >
                  Google
                </Button>
                <Typography className={classes.account}>Already have an account? <u 
                  // onClick={() => history.push('/v1/signup/login')}
                  >Login</u></Typography>
                <Typography className={classes.creating}> By creating an account, you agree to our <u>Terms of Service</u> and <u>Privacy Policy</u></Typography>

              </Grid>
              <Grid item md={6} style={{}} className='rightcard'>
                <img src={image2} alt='Belle carpeter' className={classes.imgStyles} />
              </Grid>
            </Grid>
          </Card>
        </Container>
      </div>
      <div className='foot'>© 2023 eFone. All rights reserved.</div>
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    popUpAlert: (err: any, instance: any, type: any) => dispatch(popUpActionCreator.initglobalPopup(err, instance, type))
  }
}
export default connect(null, mapDispatchToProps)(Register)


import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

const TextScroller = ({ text, val }: any) => {
    const [key, setKey] = useState(1);

    const scrolling = useSpring({
        from: { transform: "translate(60%,0)", color: "red", marginBottom: "15px" },
        to: { transform: "translate(-60%,0)" },
        config: { duration: 7000 },
        reset: true,


        //reverse: key % 2 == 0,
        onRest: () => {
            setKey(key + 1);
        }
    });

    return (
        <div key={key}>
            {val === "phone" &&
                <animated.div style={scrolling}>The Selected Number {text} Reserved For 2 Hours</animated.div>
            }
            {val === "temp" &&
                <animated.div style={scrolling}>The Temporary Number {text} Reserved For 2 Hours</animated.div>}
        </div>
    )
}

export default TextScroller;

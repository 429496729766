import { globalPopupActions } from '../Actions/Actions'

interface globalErrorProps {
    error: Boolean,
    message: String,
    instanceFrom: String,
    popuptype: String
}

const globalPopupState: globalErrorProps = {
    error: false,
    message: '',
    instanceFrom: '',
    popuptype: ''
}

export const globalPopupReducerData = (state = globalPopupState, action: any) => {
    switch(action.type){
        case globalPopupActions.INIT_GLOBAL_ERROR:
            return {
                ...state,
                error: action.status,
                message: action.message,
                instance: action.instance,
                popuptype: action.popuptype
            }
        case globalPopupActions.CLEAR_GLOBAL_ERROR:
            return {
                ...state,
                error: false,
                message: '',
                popuptype: ''
            }
        default:
            return state
    }
}
import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { CardContent, Typography, Card, TextField, Grid, Tabs, Tab, Button, IconButton } from '@material-ui/core'
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import { BsCheckCircleFill } from 'react-icons/bs'
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import { useHistory } from 'react-router-dom'
import ReactSelect from 'react-select'
import './style.css'
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import { useStyles } from './style'
import 'react-telephone-input/css/default.css'
import { connect } from "react-redux";
import * as popUpActionCreator from "../../store/Actions/popup-actionCreator";
import transferNumberServices from './services'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import DepartmentServices from '../../container/LocalNumber/services'
import axiosInstance from '../../environment/axiosInstances'
import Billing from './Billing';
import Authorisation from './Authorisation';
import DialogActions from '@mui/material/DialogActions';
import MuiPhoneNumber from "material-ui-phone-number";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import TextScroller from '../Layout/Textscroll'
import Moment from 'moment'

const formGroup: any = [

    {
        field: 'billingName',
        valid: true,
        message: ''
    },
    {
        field: 'contactNumber',
        valid: true,
        message: ''
    },

    {
        field: 'apartment',
        valid: true,
        message: ''
    },

    {
        field: 'city',
        valid: true,
        message: ''
    },
    {
        field: 'province',
        valid: true,
        message: ''
    },
    {
        field: 'postalCode',
        valid: true,
        message: ''
    },
    {
        field: 'country',
        valid: true,
        message: ''
    },
    {
        field: 'existingAccountNumber',
        valid: true,
        message: ''
    },
]
function TransferNumber(props: any) {
    const classes = useStyles();
    const history = useHistory()
    const [value, setValue] = React.useState("1");
    const [numberModal, setNumberModal] = React.useState(true)
    const [modal, setModal] = React.useState(false)
    const [formGroupData, setFormgroup] = React.useState<any>(formGroup)
    const [billingModal, setBillingModal] = React.useState(false)
    const [getSignupDetails, setFetSignupDetails] = React.useState<any>({})
    const [response, setResponse] = React.useState<any>('')
    const [portNumber, setPortNumber] = React.useState({
        phoneNumber: "",
        carrierName: "",
        portOptions: 1
    })
    const [billingData, setBillingData] = React.useState({
        apartment: "",
        billingName: "",
        city: "",
        contactNumber: "",
        country: "",
        existingAccountNumber: "",
        postalCode: "",
        province: "",
        app: ""
    })
    const [authorisationData, setAuthorisationData] = React.useState<any>({
        confirmPort: false,
        desiredPortDate: "",
        autorizedSignature: "",
    })
    const [carierOptions, setCarieroptions] = React.useState<any>([])
    const validateForm = (dataToValidate: any) => {
        let formgroupCopy = [...formGroupData];
        if (dataToValidate.property === 'contactNumber') {
            const mobile = billingData.contactNumber.replace(/[^a-zA-Z0-9]/g, '')
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'contactNumber') {
                        formG.valid = false;
                        formG.message = 'contactNumber should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            }
            //  else if (!/^[0-9\b]+$/.test(dataToValidate.value)) {
            //     formgroupCopy.forEach((formG: any) => {
            //         if (formG.field === 'contactNumber') {
            //             formG.valid = false;
            //             formG.message = 'contactNumber should be numbers';
            //         }
            //     })
            //     setFormgroup(formgroupCopy)
            // } 
            else if (mobile.length < 10 || mobile.length > 15) {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'contactNumber') {
                        formG.valid = false;
                        formG.message = 'Please enter Numbers between 10 to 15 digits';
                    }
                })
                setFormgroup(formgroupCopy)
            }
            else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'contactNumber') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'existingAccountNumber') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'existingAccountNumber') {
                        formG.valid = false;
                        formG.message = 'existingAccountNumber should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            }
            // else if (!/^[0-9\b]+$/.test(dataToValidate.value)) {
            //     formgroupCopy.forEach((formG: any) => {
            //         if (formG.field === 'existingAccountNumber') {
            //             formG.valid = false;
            //             formG.message = 'existingAccountNumber should be numbers';
            //         }
            //     })
            //     setFormgroup(formgroupCopy)
            // }
            else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'existingAccountNumber') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }

        if (dataToValidate.property === 'billingName') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'billingName') {
                        formG.valid = false;
                        formG.message = 'billingName should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'billingName') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'apartment') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'apartment') {
                        formG.valid = false;
                        formG.message = 'address should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'apartment') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }

        if (dataToValidate.property === 'city') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'city') {
                        formG.valid = false;
                        formG.message = 'city should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'city') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'province') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'province') {
                        formG.valid = false;
                        formG.message = 'province should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'province') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'postalCode') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'postalCode') {
                        formG.valid = false;
                        formG.message = 'postalCode should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'postalCode') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'country') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'country') {
                        formG.valid = false;
                        formG.message = 'country should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'country') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }



    }

    const handleTabs = (e: any, newValue: any) => {
        setValue(newValue);
    };
    const handleChangeAuthorOption = (e: any) => {
        setAuthorisationData((prev: any) => {
            return {
                ...authorisationData,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleCallBack = (callBack: any) => {
        setValue(callBack)
    }

    const Transition = React.forwardRef(function Transition(props: any, ref: any) {
        return <Slide direction="down" ref={ref} {...props} />;
    });

    const handleClose = () => {
        setNumberModal(!numberModal)
    };

    const handleBillingClose = () => {
        setBillingModal(false)
    }

    const handleInputChange = (value: any) => {
        const numberr = value.replace(/[^a-zA-Z0-9]/g, '').substring(1)
        setPortNumber((prev: any) => {
            return {
                ...portNumber,
                phoneNumber: numberr,
            }
        })
        if (portNumber.phoneNumber.length === 9) {
            onChangePortNumber(portNumber.phoneNumber)
        }
    }

    const handleChangeAuthorDate = (e: any) => {
        console.log("---date", e.target.value)
        setAuthorisationData((prev: any) => {
            return {
                ...authorisationData,
                [e.target.name]: e.target.value
            }
        })
    }


    const onChangeCheckAuthorbox = (e: any) => {
        setAuthorisationData((prev: any) => {
            return {
                ...authorisationData,
                [e.target.name]: e.target.checked
            }
        })
    }
    const handleCarrier = (e: any) => {
        setPortNumber((prev: any) => {
            return {
                ...portNumber,
                carrierName: e.value
            }
        })
    }
    const handleChangeOption = (e: any) => {
        setPortNumber((prev: any) => {
            return {
                ...portNumber,
                [e.target.name]: e.target.value
            }
        })
    }
    const onChangePortNumber = (data: any) => {
        transferNumberServices.fetchPortNumber(data)
            .then((res: any) => {
                if (res.success) {
                    setResponse(res.data.response)
                }

            }).catch((err: any) => {
            })
    }

    const fetchCarriers = () => {
        transferNumberServices.getCarriers(localStorage.getItem("token"))
            .then((res) => {
                if (res.success) {
                    const data = res.data.map((item: any) => {
                        return { label: item.value, value: item.id }
                    })
                    setCarieroptions(data)
                }
            }).catch(err => {

            })
    }



    const submitTransferNumber = (data: any) => {
        var object: any = {}
        object["phoneNumber"] = data.phoneNumber;
        var transferPortData: any = {}
        transferPortData["phoneNumber"] = data.phoneNumber.replace(/[^0-9,.]+/g, "");
        transferPortData["carrierName"] = data.carrierName;
        transferPortData["portOptions"] = data.portOptions;
        DepartmentServices.postNumberList(object, localStorage.getItem("token")).then((res: any) => {
            if (res.success) {
                transferNumberServices.SubmitNumber(localStorage.getItem("token"), transferPortData)
                    .then((res) => {
                        if (res.success) {
                            history.push({ pathname: '/signup/cdn1/phone-number/port/number', state: "Billing" })
                            setBillingModal(true)
                            getSignUpDetails()
                        }
                        else {
                            props.popUpAlert(res.message, "UPDATE_AGENT_SUCCESS", "error");
                        }
                    })
            }
            else {
                props.popUpAlert(res.message, "UPDATE_AGENT_SUCCESS", "error");
            }
        })
    }
    const submitTransferAuthorNumber = (data: any) => {
        if (data.confirmPort === true) {
            transferNumberServices.SubmitAuthorisationDetails(localStorage.getItem("token"), data).then((res: any) => {
                if (res.success) {
                    history.push("/signup/cdn1/profile")
                    getSignUpDetails()
                }
                else {
                    props.popUpAlert(res.message, "UPDATE_AGENT_SUCCESS", "error");
                }
            }).catch((err: any) => {
                if (err.request.status === 402) {
                    window.location.href = "https://efone.ca/pricing"
                    localStorage.clear()
                }
            })
        } else {
            props.popUpAlert("Please check", "UPDATE_AGENT_SUCCESS", "error");
        }
    }
    const handleChangebilling = (newData: any) => {
        setBillingData(() => {
            return {
                ...billingData,
                apartment: newData
            }
        });
    };

    const handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then((results) => {
                var myResults = results[0]
                var city = ""
                //var state = ""
                var country = ""
                var postalCode = ""
                var province = ""

                getLatLng(myResults).then((latLong) => {
                    myResults.address_components.map((item) => {
                        if (item.types.includes("locality")) {
                            city = item.long_name
                        }
                        // if (item.types.includes("administrative_area_level_1")) {
                        //   state = item.long_name
                        // }
                        if (item.types.includes("country")) {
                            country = item.long_name
                        }
                        if (item.types.includes("postal_code")) {
                            postalCode = item.long_name
                        }
                        if (item.types.includes("administrative_area_level_1")) {
                            province = item.short_name
                        }

                    })
                    setBillingData(() => {
                        return {
                            ...billingData,
                            apartment: myResults.formatted_address,
                            city: city,
                            country: country,
                            postalCode: postalCode,
                            province: province
                        }
                    });
                })
            })
            .catch(error => console.error('Error', error));
    };

    // React.useEffect(() => {
    //     if (getSignupDetails) {
    //         setPortNumber({
    //             phoneNumber: getSignupDetails?.phoneNumber ? getSignupDetails?.phoneNumber : "",
    //             carrierName: getSignupDetails?.carrierName ? getSignupDetails?.carrierName : "",
    //             portOptions: getSignupDetails?.portOptions ? getSignupDetails?.portOptions : ""
    //         })
    //     }
    // }, [getSignupDetails])

    React.useEffect(() => {
        if (portNumber.phoneNumber) {
            onChangePortNumber(portNumber.phoneNumber)
        }
    }, [portNumber.phoneNumber.length >= 9])

    // React.useEffect(() => {
    //     if (getSignupDetails) {
    //         setBillingData({
    //             apartment: getSignupDetails?.apartment ? getSignupDetails?.apartment : "",
    //             billingName: getSignupDetails?.billingName ? getSignupDetails?.billingName : "",
    //             city: getSignupDetails?.city ? getSignupDetails?.city : "",
    //             contactNumber: getSignupDetails?.contactNumber ? getSignupDetails?.contactNumber : "",
    //             country: getSignupDetails?.country ? getSignupDetails?.country : "",
    //             existingAccountNumber: getSignupDetails?.existingAccountNumber ? getSignupDetails?.existingAccountNumber : "",
    //             postalCode: getSignupDetails?.postalCode ? getSignupDetails?.postalCode : "",
    //             province: getSignupDetails?.province ? getSignupDetails?.province : ""
    //         })
    //     }
    // }, [getSignupDetails])

    React.useEffect(() => {
        if (getSignupDetails) {
            var date = new Date();
            date.setDate(date.getDate() + 7);
            setAuthorisationData({
                confirmPort: getSignupDetails?.confirmPort ? getSignupDetails?.confirmPort : "",
                desiredPortDate: getSignupDetails?.desiredPortDate ? getSignupDetails?.desiredPortDate : date,
                autorizedSignature: getSignupDetails?.autorizedSignature ? getSignupDetails?.autorizedSignature : ""
            })
            setBillingData({
                apartment: getSignupDetails?.apartment ? getSignupDetails?.apartment : "",
                billingName: getSignupDetails?.billingName ? getSignupDetails?.billingName : "",
                city: getSignupDetails?.city ? getSignupDetails?.city : "",
                contactNumber: getSignupDetails?.contactNumber ? getSignupDetails?.contactNumber : "",
                country: getSignupDetails?.country ? getSignupDetails?.country : "",
                existingAccountNumber: getSignupDetails?.existingAccountNumber ? getSignupDetails?.existingAccountNumber : "",
                postalCode: getSignupDetails?.postalCode ? getSignupDetails?.postalCode : "",
                province: getSignupDetails?.province ? getSignupDetails?.province : "",
                app: ""
            })
            setPortNumber({
                phoneNumber: getSignupDetails?.phoneNumber ? getSignupDetails?.phoneNumber : "",
                carrierName: getSignupDetails?.carrierName ? getSignupDetails?.carrierName : "",
                portOptions: getSignupDetails?.portOptions ? getSignupDetails?.portOptions : ""
            })
        }
    }, [getSignupDetails])

    React.useEffect(() => {
        setModal(true)
        fetchCarriers()
        getSignUpDetails()
    }, [])

    const getSignUpDetails = () => {
        axiosInstance.get(`/sign-up?token=${localStorage.getItem("token")}`).then((res: any) => {
            if (res.data.data) {
                setFetSignupDetails(res.data?.data?.[0])
            } else {
            }
        }).catch((err: any) => {
            if (err.request.status === 402) {
                window.location.href = "https://efone.ca/pricing"
                localStorage.clear()
            }
        })
    }


    React.useEffect(() => {
        if (props.location.state) {
            if (props.location.state === "Authorisation") {
                setValue("3");
            }
            else if (props.location.state === "Numbers") {
                setValue("1");
            }
            else if (props.location.state === "Billing") {
                setValue("2");
            }

        }
    }, [props.location.state])


    const submitBillingNumber = (data: any) => {
        const sendData = {
            apartment: data.apartment,
            billingName: data.billingName,
            city: data.city,
            contactNumber: data.contactNumber,
            country: data.country,
            existingAccountNumber: data.existingAccountNumber,
            postalCode: data.postalCode,
            province: data.province
        }

        validateForm({ property: 'contactNumber', value: data.contactNumber })
        validateForm({ property: 'apartment', value: data.apartment })
        validateForm({ property: 'city', value: data.city })
        validateForm({ property: 'province', value: data.province })
        validateForm({ property: 'postalCode', value: data.postalCode })
        validateForm({ property: 'country', value: data.country })
        validateForm({ property: 'billingName', value: data.billingName })
        validateForm({ property: 'existingAccountNumber', value: data.existingAccountNumber })
        const [contactNumber, apartment, city, province, postalCode, country, billingName, existingAccountNumber] = formGroupData;
        if (contactNumber.valid && apartment.valid && city.valid && province.valid && postalCode.valid && country.valid && billingName.valid && existingAccountNumber.valid) {
            transferNumberServices.SubmitBillingDetails(localStorage.getItem("token"), sendData).then((res: any) => {
                if (res.success) {
                    const Newdata = res.data.data
                    const desiredDate = Newdata?.desiredPortDate
                    var datadate = parseInt(desiredDate)
                    var dataa = Moment(datadate).format('YYYY-MM-DD')

                    setAuthorisationData((prev: any) => {
                        return {
                            ...authorisationData,
                            desiredPortDate: dataa
                        }
                    })

                    history.push({ pathname: '/signup/cdn1/phone-number/port/number', state: "Authorisation" })
                    getSignUpDetails()
                }
                else {
                    props.popUpAlert(res.message, "UPDATE_AGENT_SUCCESS", "error");
                }
            }).catch((err: any) => {
                if (err.request.status === 402) {
                    window.location.href = "https://efone.ca/pricing"
                    localStorage.clear()
                }
            })
        }
    }

    const handleChangeBillingOption = (e: any) => {
        const val = e.target.value
        if (val !== " ") {
            setBillingData((prev: any) => {
                return {
                    ...billingData,
                    [e.target.name]: e.target.value
                }
            })
        }

    }
    const billingNumber = (val: any) => {
        setBillingData((prev: any) => {
            return {
                ...billingData,
                contactNumber: val
            }
        })
    }
    var number = getSignupDetails?.phoneNumber
    if (number) {
        var cleaned = ('' + number).replace(/\D/g, '');
        var match: any = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        number = ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    var newNumber = getSignupDetails.numberType === 3 ? getSignupDetails && getSignupDetails.temporaryNumber ? getSignupDetails.temporaryNumber : "" : ""
    if (newNumber) {
        var cleaned = ('' + newNumber).replace(/\D/g, '');
        var match: any = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        newNumber = ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return (
        <>
            <div>
                {
                    numberModal && (
                        <>
                            <Dialog
                                open={numberModal && modal}
                                keepMounted
                                onClose={handleClose}
                                aria-describedby="alert-dialog-slide-description"

                            >
                                <DialogContent>
                                    <DialogContentText className={classes.dilText} style={{ color: '#484848', fontWeight: 600, marginBottom: "5px" }}>eFone is Not a Cell Phone Carrier...</DialogContentText>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        You cannot port your cell phone number to eFone and continue to use it on your cell phone for
                                        calling, texting, and data services. In order to continue using calling, texting, and data services
                                        on your current cell phone, you will need to add another
                                        phone number with your current carrier before porting the original number to eFone.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose}
                                        variant="outlined" size="small" fullWidth
                                        className={classes.okButton}>Ok,Got it</Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    )
                }
                {
                    billingModal && (
                        <>
                            <Dialog
                                open={billingModal}
                                keepMounted
                                onClose={handleBillingClose}
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogContent>
                                    <DialogTitle>{"Important information below"}</DialogTitle>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        The billing information you are about to enter must watch the billing information on file with your current carries,
                                        exactly as listed on your bill
                                    </DialogContentText>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        If you are unsure of how your information is listed with your current carrier,please consult your most frecent bill
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleBillingClose} variant="outlined" size="small" fullWidth
                                        className={classes.okButton}
                                    >Ok,Got it
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    )
                }
                <div>
                    {getSignupDetails?.phoneNumber && getSignupDetails.numberType === 1 ? <TextScroller text={number} val="phone" /> : ""}
                    {getSignupDetails?.temporaryNumber && getSignupDetails.numberType === 3 ? <TextScroller text={newNumber} val="temp" /> : ""}
                </div>
                <CardContent style={{ display: "flex", justifyContent: "space-between" }}>

                    <div className={classes.transferNum}>
                        <MobileScreenShareIcon style={{ color: "#377dff" }} />
                        <Typography>Transfer a Number</Typography>
                    </div>

                    {getSignupDetails?.phoneNumber && getSignupDetails?.numberType === 3 && getSignupDetails.carrierName ?
                        <Typography style={{ float: "right" }}>{getSignupDetails.temporaryNumber ? newNumber : number}</Typography> : ""}
                </CardContent>
                <div >
                    <Grid container spacing={2}>

                        <Grid item xl={6} lg={6} md={8} sm={8} xs={11} style={{ margin: '0px auto' }}>
                            <TabContext value={value}>
                                <Card style={{ background: '#F2F2F2' }}>
                                    <Tabs
                                        className={classes.tabs}
                                        value={value}
                                        onChange={handleTabs}
                                        variant="fullWidth"
                                        TabIndicatorProps={{
                                            style: {
                                                height: "0px",
                                            },
                                        }}
                                    >
                                        <Tab
                                            label="Numbers"
                                            value="1"
                                            disabled={value === "2" || value === "3"}
                                            className={value === "1" ? classes.tab : classes.tabActive}
                                        />
                                        <Tab
                                            label="Billing"
                                            value="2"
                                            disabled={value === "1" || value === "3"}
                                            className={value === "2" ? classes.tab : classes.tabActive}
                                        />
                                        <Tab
                                            label="Authorization"
                                            value="3"
                                            disabled={value === "1" || value === "2"}
                                            className={value === "3" ? classes.tab : classes.tabActive}
                                        />
                                    </Tabs>
                                </Card>
                                <TabPanel value='1'>
                                    <Grid container style={{ marginTop: 15 }}>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Typography className={classes.header} >Phone Number to Ports</Typography>
                                            <MuiPhoneNumber
                                                defaultCountry={"us"}
                                                value={getSignupDetails?.numberType === 3 && getSignupDetails.carrierName ? portNumber.phoneNumber : ""}
                                                onChange={handleInputChange}
                                            />
                                            {response === 0 && <div style={{ color: 'red' }}> This number cannot be ported into eFone.</div>}
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Typography>Current Carrier</Typography>
                                            <ReactSelect
                                                className={classes.selectField}
                                                size="small"
                                                options={carierOptions}
                                                onChange={handleCarrier}
                                                name="carrierName"
                                                value={
                                                    carierOptions.filter((option: any) =>
                                                        option.value === portNumber.carrierName)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <FormControl style={{ marginTop: 20 }}>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            value={portNumber.portOptions}
                                            name="portOptions"
                                            onChange={handleChangeOption}
                                        >
                                            <FormControlLabel value={1} control={<Radio size='small' />} label="This is only number on this account." />
                                            <FormControlLabel value={2} control={<Radio size='small' />}
                                                label="I have other numbers on this account and do not want to port"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                    {/* <Button variant='outlined' onClick={submitTransferNumber}>Click</Button> */}
                                </TabPanel>

                                <TabPanel value='2'>
                                    <Billing
                                        value={value}
                                        billingData={billingData}
                                        handleChangeOption={handleChangeBillingOption}
                                        submitTransferNumber={submitTransferNumber}
                                        handleCallBack={handleCallBack}
                                        billingNumber={billingNumber}
                                        handleSelect={handleSelect}
                                        handleChange={handleChangebilling}
                                        formGroupData={formGroupData}
                                    />
                                </TabPanel>

                                <TabPanel value='3'>
                                    <Authorisation
                                        value={value}
                                        authorisationData={authorisationData}
                                        onChangeCheckbox={onChangeCheckAuthorbox}
                                        handleChangeDate={handleChangeAuthorDate}
                                        handleChangeOption={handleChangeAuthorOption} />
                                </TabPanel>
                            </TabContext>
                        </Grid>
                    </Grid>
                </div>

            </div>
            {value === "1" &&

                <Grid container style={{ position: 'fixed', bottom: 0, width: '100%', background: '#f5f5f5' }} >
                    <Grid item lg={4}>


                        <IconButton className={classes.nextIconActive}
                            onClick={() => history.push(`/signup/cdn1/phone-number/type`)}
                        >
                            <GrFormPreviousLink className={classes.icon} />
                        </IconButton>


                    </Grid>
                    <Grid item lg={4}>
                        <div className="number-dots ">
                            <div className="number-dot-item dot-blue">
                                <BsCheckCircleFill />
                            </div>
                            <div className="number-dot-item dot-blue">
                                <BsCheckCircleFill />
                            </div>
                            <div className="number-dot-item active">
                                <BsCheckCircleFill />
                            </div>
                            <div className="number-dot-item ">
                                <BsCheckCircleFill />
                            </div>
                            <div className="number-dot-item">
                                <BsCheckCircleFill />
                            </div>
                        </div>

                    </Grid>
                    <Grid item lg={4}>
                        {portNumber.carrierName !== '' && portNumber.phoneNumber !== '' && response === 1 &&
                            < div >
                                <IconButton className={classes.nextIconActive1}
                                    onClick={() => submitTransferNumber(portNumber)} style={{ float: "right" }}>
                                    <GrFormNextLink className={classes.icon} />
                                </IconButton>
                            </div>}

                    </Grid>
                </Grid>

            }
            {value === "2" &&
                <Grid container spacing={4} style={{ position: "sticky" }}>
                    <Grid item lg={4}>


                        <IconButton className={classes.nextIconActive}
                            // onClick={props.handleCallBack("1")}
                            onClick={() => history.push({ pathname: '/signup/cdn1/phone-number/port/number', state: "Numbers" })}
                        >
                            <GrFormPreviousLink className={classes.icon} />
                        </IconButton>


                    </Grid>
                    <Grid item lg={4}>
                        <div className="number-dots ">
                            <div className="number-dot-item dot-blue">
                                <BsCheckCircleFill />
                            </div>
                            <div className="number-dot-item dot-blue">
                                <BsCheckCircleFill />
                            </div>
                            <div className="number-dot-item active">
                                <BsCheckCircleFill />
                            </div>
                            <div className="number-dot-item ">
                                <BsCheckCircleFill />
                            </div>
                            <div className="number-dot-item">
                                <BsCheckCircleFill />
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={4}>
                        {billingData.province !== "" && billingData.postalCode !== "" && billingData.existingAccountNumber !== "" && billingData.country !== "" && billingData.contactNumber !== "" && billingData.apartment !== '' && billingData.billingName !== '' && billingData.city !== '' &&
                            < div >
                                <IconButton className={classes.nextIconActive1}
                                    onClick={() => submitBillingNumber(billingData)} style={{ float: "right" }}>
                                    <GrFormNextLink className={classes.icon} />
                                </IconButton>
                            </div>
                        }

                    </Grid>
                </Grid>
            }
            {value === "3" &&
                <Grid container spacing={4} style={{ position: "sticky" }}>
                    <Grid item lg={4}>


                        <IconButton className={classes.nextIconActive}
                            // onClick={props.handleCallBack("1")}
                            onClick={() => history.push({ pathname: '/signup/cdn1/phone-number/port/number', state: "Billing" })}
                        >
                            <GrFormPreviousLink className={classes.icon} />
                        </IconButton>


                    </Grid>
                    <Grid item lg={4}>
                        <div className="number-dots ">
                            <div className="number-dot-item dot-blue">
                                <BsCheckCircleFill />
                            </div>
                            <div className="number-dot-item dot-blue">
                                <BsCheckCircleFill />
                            </div>
                            <div className="number-dot-item active">
                                <BsCheckCircleFill />
                            </div>
                            <div className="number-dot-item ">
                                <BsCheckCircleFill />
                            </div>
                            <div className="number-dot-item">
                                <BsCheckCircleFill />
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={4}>
                        {value === "3" && authorisationData.autorizedSignature !== "" && authorisationData.confirmPort !== false && authorisationData.desiredPortDate !== "" &&
                            <div>
                                <IconButton
                                    className={classes.nextIconActive1}
                                    style={{ float: "right" }}
                                    onClick={() => submitTransferAuthorNumber(authorisationData)}>
                                    <GrFormNextLink className={classes.icon} />
                                </IconButton>
                            </div>
                        }


                    </Grid>
                </Grid>
            }


        </>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        popUpAlert: (err: any, instance: any, type: any) => dispatch(popUpActionCreator.initglobalPopup(err, instance, type))
    }
}

export default connect(null, mapDispatchToProps)(TransferNumber)

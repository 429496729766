import { Divider, Typography } from '@material-ui/core'
import React from 'react'
import './style.css'
import { useStyles } from './styles'

function StepThree() {
    const classes = useStyles()
    return (
        <>
            <div className='alingnments three'>
                <div className='flexDiv'>
                    <div className='stepbox'>
                        <span className='stepnum one active'>1</span>
                        <Typography className='headerOne '><span className='innum'>1.</span>REGISTER</Typography>
                        <Divider className='dividerOne mobhid' />
                    </div>
                    <div className='stepbox'>
                        <span className='stepnum two active'>2</span>
                        <Typography className='headerOne'><span className='innum'>2.</span>PHONE NUMBER</Typography>
                        <Divider className='dividerTwo active mobhid' />
                    </div>
                    <div className='stepbox'>
                        <span className='stepnum three active'>3</span>
                        <Typography id="back" className='headerOne'><span className='innum'>3.</span>CHOOSE PLAN</Typography>
                        <Divider className='dividerThree active mobhid'
                        />
                    </div>
                    <div className='stepbox'>
                        <span className='stepnum'>4</span>
                        <Typography className='headerOne nonactive'><span className='innum'>4.</span>CHECKOUT</Typography>
                        <Divider className='dividerFour mobhid' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default StepThree
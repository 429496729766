import { makeStyles } from '@material-ui/core/styles';
import { padding } from '@mui/system';

export const useStyles = makeStyles(() => ({
  card: {
    // width: '1100px',
    // height: '769px',
    // left: '86px',
    // top: '32px',
    // marginleft: '-100px',


    // background:'#0000001A'

  },
  reserve: {
    marginleft: '100px',

  },
  tabActive: {
    background: "#FFFFFF",
    boxSizing: "border-box",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 18,
    //padding:"16px 0",
    //color: "#00407B",
    textTransform: "capitalize",
  },
  tab: {
    textTransform: "capitalize",
    background: "#FFFFFF",
    boxSizing: "border-box",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 18,
    color: "#8392AB",

  },
  tabPad: {
    padding: 0,
    marginTop: 30,
  },
  cardPadding: {
    margin: '10px 14%',
    background: '#FAFAFA !important',
  },
  tabCard: {
    borderRadius: '50px',
    // height: 49,
    background: '#FFFFFF',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
  },
  tabs: {
    borderRadius: '50px',
    marginTop: '60px',
    // textAlign: 'center',
    // height: 49,
    background: '#FFFFFF',
    boxShadow: '0px 3px 10px  3px rgba(0, 0, 0, 0.07)',
    textTransform: 'capitalize',
    width: '726px',
    marginLeft: '180px',
    fontWeight: 700


  },
  typography: {
    color: 'white',
    fontSize: '16px',
    textAlign: 'center',
    fontWeight: 500
  },
  newnumber: {
    fontSize: '18px',
    fontWeight: 600,
    color: 'white',
    fontFamily: 'Century Gothic',
    textAlign: 'center',
    fontStyle: 'normal'
  },
  phonenumber: {
    color: '#4DA5B2',
    fontSize: '18px',
    // background: '#FAFAFA',
    textAlign: 'center'
  },
  div: {
    marginTop: '30px'
  },
  name: {
    '&:hover': {
      background: '#FFFFFF',
      color: '#000000B2',
    },
  },
  button: {
    background: '#4D72F8',
    color: '#FFFFFF',
    borderRadius: '8px',
    padding:'5px 25px',
    border: "1px solid rgba(0, 0, 0, 0.2)",
    textTransform: 'capitalize',
    fontSize: '16px',
    fontFamily: 'Century Gothic',
    fontStyle: 'normal',
    letterSpacing: ' 0.02em',
    marginLeft: "450px",
    marginTop: '20px',
    marginBottom: '22px',
    '&:hover': {
      background: '#4D72F8',
      color: '#FFFFFF',

    },
  },
  // grid: {

  //   background: '#FFFFFF',

  //   borderRadius: '3px',
  //   padding: '30px 120px',
  //   marginTop: -15
  // },
  gridStyles: {
    //margin:'2px 3%'
    // paddingLeft:'182px',
    // paddingRight:200,
    marginTop:8
  },
  canada: {
    color: '#B6B5B5',
    width:'100%',

  },
  statee:{
    width:'100%',

  },
  cityy:{
    width:'100%',
  },
  areacode:{
    color:'#333333!important',
  },
  canada1:{
color:'black!important'
  },
  choose: {
    fontFamily: 'Calibri',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight:400,
    color: 'rgba(0, 0, 0, 0.5)',
    marginLeft: '182px',
    marginTop:20,
    marginBottom:20

  },
  radio: {
    display: 'flex',
    // justifyContent:'space-between',
    // marginLeft:'20px'
    marginTop: '20px',
    gap: 5,
    alignItems: 'center'
  },
  item: {
    // padding: '10px 100px',
    // marginLeft: '5px
    paddingLeft:'175px',
    paddingRight:'118px'
  },
  number: {
    fontFamily: 'arial',
    fontStyle: 'normal',
    fontSize: '14px',
    letterSpacing: '0.1px',
    color: '#929292'
  },
  city: {
    marginLeft: "400px",
    color: 'red',
    // textTransform: 'none',
    marginTop: '8px',

  },


}))
import React from "react";
import {
  Card,
  Tabs,
  Tab,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  CardContent,
  Divider,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";

import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import ReactSelect from "react-select";
import PhoneIcon from "@material-ui/icons/Phone";
// import { GrFormNextLink } from "react-icons/gr";
// import { BiRefresh } from 'react-icons/bi'
import { useStyles } from "./style";
// import ProfileServices from "../../../../Profile/ProfileServices/ProfileServices";
// import ProfileServices from "../../../../Profile/ProfileServices/ProfileServices"

function CountryAreaCode(props: any) {
  const classes = useStyles();


  return (
    <div>

      <Grid container spacing={2} className={classes.gridStyles}>

        <Grid item md={4} className={classes.canada}>
          {/* <ReactSelect
            placeholder="Canada"
          /> */}
          <ReactSelect
            fullWidth
            options={props.countryOptions}
            value={props.country}
            placeholder="Select Country"
            inputProps={{ "aria-label": "Without label" }}
            onChange={props.onChangeCountryType}
          />
        </Grid>
        <Grid item md={4}>
        <TextField className={classes.areacode}
                style={{ width: '100%', background: 'white',color:'#333333!important' }}
                size='small'
                variant='outlined'
                placeholder='Enter area code'
                value={props.areaCode.area}
                name="otp"
                onChange={props.onChangeAreaCode}
                error={false}

            />
        </Grid>
      </Grid>
    </div>

  )
}

export default CountryAreaCode;
import React from 'react';
import Layout from './container/Layout/Layout'
import { Route, Switch, Redirect } from 'react-router-dom'
import Routes from './services/Routes'
import ProtectedRoutes from './components/protectedRoutes/ProtectedRoutes';
import './App.css';
import PopupAlert from './components/Popup-alert/Popup-alert';
import LogPage from './container/SigninPage/SigninPage'
import Register from './container/NewSignUp/Register/Register'
// import { UserContextProvider } from './container/NewSignUp/context/UserContect';
import ReserveNumbers from './container/NewSignUp/ReserveNumbers/ReserveNumbers';
import Vefify from '../src/container/NewSignUp/VrifyLink/VerifyLink'
import Login from './container/NewSignUp/Login/Login';




function App(props: any) {
  return (
    <div className="App">
      <div className="alert_messages">
        <PopupAlert />
      </div>
      {/* <UserContextProvider> */}
      <Switch>
        <Route exact path="/">
          <Redirect to="/v1/signup/register" />
        </Route>
        <Route exact path={`/v1/signup/register`} component={Register} />
        <Route path={`/v1/signup/login`} component={Login} />
        <Route path={`/v1/signup/verifyemail`} component={Vefify} />
        {/* <Route path={`/v1/signup/reservenumber`} component={ReserveNumbers} /> */}
        <Layout>
          {
            Routes.map((route: any, i: any) => (
              <ProtectedRoutes path={route?.path} Component={route.Component} routes={route.routes} key={i} />
            ))
          }
        </Layout>

      </Switch>
      {/* </UserContextProvider> */}
    </div>
  );
}


export default App

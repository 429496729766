import { Divider, Typography } from '@material-ui/core'
import React from 'react'
import './style.css'

function StepFour() {
    return (
        <>
            <div className='alingnments'>
                <div className='flexDiv'>
                    <div>
                        <Typography className='headerOne'>1.REGISTER</Typography>
                        <Divider className='divider' />
                    </div>
                    <div>
                        <Typography className='headerTwo'>2.RESERVE NUMBERS</Typography>
                        <Divider className='dividerStepTwo' />
                    </div>
                    {/* <div>
                        <Typography className='headerTwo'>3.CHOOSE PLAN</Typography>
                        <Divider className='dividerStepThree'
                        />
                    </div>
                    <div >
                        <Typography className='headerTwo'>4.CHECKOUT</Typography>
                        <Divider className='dividerStepFourA' />
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default StepFour
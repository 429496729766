import * as React from 'react';
import './style.css'
import { Typography, Card, TextField, Grid, Tabs, Tab, Button, IconButton } from '@material-ui/core'
import { useStyles } from './style'
import { useHistory } from 'react-router-dom'
import transferNumberServices from './services'
import Flags from '../../assets/flags.png'
import ReactTelephoneInput from 'react-telephone-input'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

function Billing(props: any) {
  const classes = useStyles();
  const history = useHistory()
  function handleInputBlur(telNumber: any, selectedCountry: any) {
    console.log(
      'Focus off the ReactTelephoneInput component. Tel number entered is: ',
      telNumber,
      ' selected country is: ',
      selectedCountry
    )
  }
  function handleInputChange(telNumber: any, selectedCountry: any) {
    // setData((prev: any) => {
    //     return {
    //         ...data,
    //         mobileNumber: telNumber
    //     }
    // });
    props.billingNumber(telNumber)
  }

  return (
    <>
      <div style={{ marginTop: 50, width: '100%', margin: '0px auto' }}>
        <Grid container style={{ marginTop: 10 }}>
          <Grid item md={6} sm={6} xs={12}>
            <Typography className={classes.header} >Existing Account Number</Typography>
            <TextField
              variant="outlined"
              fullWidth size='small'
              value={props.billingData.existingAccountNumber}
              placeholder='Enter Account number'
              name="existingAccountNumber"
              onChange={props.handleChangeOption}
              className={classes.selectField} />
            <p className={classes.err}>{props.formGroupData.map((fileds: any) => {
              if (fileds.valid === false && fileds.field === 'existingAccountNumber') {
                return fileds.message
              } else {
                return ''
              }
            })}</p>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Typography>Billing Name on Account</Typography>
            <TextField
              variant="outlined"
              name="billingName"
              placeholder='Enter Billing  Name'
              value={props.billingData.billingName}
              onChange={props.handleChangeOption}
              fullWidth size='small' className={classes.selectField} />
            <p className={classes.err}>{props.formGroupData.map((fileds: any) => {
              if (fileds.valid === false && fileds.field === 'billingName') {
                return fileds.message
              } else {
                return ''
              }
            })}</p>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 10 }}>
          <Grid item md={6} sm={6} xs={12}>
            <Typography className={classes.header} >Contact Number</Typography>
            <ReactTelephoneInput
              variant="outlined"
              className={classes.selectField}
              size='medium'
              max={10}
              style={{ width: "100%" }}
              value={props.billingData.contactNumber}
              defaultCountry="us"
              flagsImagePath={Flags}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
            />
            {/* <TextField
              variant="outlined"
              fullWidth size='small'
              value={props.billingData.contactNumber}
              name="contactNumber"
              onChange={props.handleChangeOption}
              placeholder='Contact number'
              className={classes.selectField} /> */}
            <p className={classes.err}>{props.formGroupData.map((fileds: any) => {
              if (fileds.valid === false && fileds.field === 'contactNumber') {
                return fileds.message
              } else {
                return ''
              }
            })}</p>
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={{ marginTop: "-12px" }}>
            <Typography className={classes.headerOne} >Address</Typography>
            <PlacesAutocomplete
              onChange={props.handleChange}
              onSelect={props.handleSelect}
              value={props.billingData.apartment}

            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
                <div>
                  <TextField
                    // style={{ marginTop: '12px' }}
                    fullWidth
                    variant="outlined"
                    size="small"
                    {...getInputProps({
                      placeholder: 'Search address',
                      className: 'location-search-input',
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion: any) => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div style={{ display: "flex", border: "1px solid #d4d5d9", padding: 14 }} >
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            <p className={classes.err}>{props.formGroupData.map((fileds: any) => {
              if (fileds.valid === false && fileds.field === 'apartment') {
                return fileds.message
              } else {
                return ''
              }
            })}</p>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 5 }}>
          <Grid item md={6} sm={6} xs={12}>
            <Typography className={classes.header} >App/suite</Typography>
            <TextField
              variant="outlined"
              fullWidth size='small'
              name="app"
              placeholder="Enter Apartment No."
              value={props.billingData.app}
              onChange={props.handleChangeOption}
              className={classes.selectField} />

            <Typography className={classes.headerOne}>Province/State</Typography>
            <TextField
              variant="outlined"
              onChange={props.handleChangeOption}
              value={props.billingData.province}
              fullWidth size='small'
              name="province"
              placeholder='Enter province'
              className={classes.selectField} />
            <p className={classes.err}>{props.formGroupData.map((fileds: any) => {
              if (fileds.valid === false && fileds.field === 'province') {
                return fileds.message
              } else {
                return ''
              }
            })}</p>
            <Typography className={classes.headerOne}>Country</Typography>
            <TextField
              variant="outlined"
              onChange={props.handleChangeOption}
              placeholder='Enter country'
              value={props.billingData.country}
              fullWidth size='small'
              name="country"
              className={classes.selectField} />
            <p className={classes.err}>{props.formGroupData.map((fileds: any) => {
              if (fileds.valid === false && fileds.field === 'country') {
                return fileds.message
              } else {
                return ''
              }
            })}</p>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Typography>City</Typography>
            <TextField
              variant="outlined"
              fullWidth size='small'
              name="city"
              onChange={props.handleChangeOption}
              value={props.billingData.city}
              className={classes.selectField}
              placeholder='Enter city'
            />
            <p className={classes.err}>{props.formGroupData.map((fileds: any) => {
              if (fileds.valid === false && fileds.field === 'city') {
                return fileds.message
              } else {
                return ''
              }
            })}</p>
            <Typography style={{ marginTop: "14px" }}>Postal Code</Typography>
            <TextField
              variant="outlined"
              fullWidth size='small'
              name="postalCode"
              onChange={props.handleChangeOption}
              value={props.billingData.postalCode}
              className={classes.selectField}
              placeholder='Enter postalCode'
            />
            <p className={classes.err}>{props.formGroupData.map((fileds: any) => {
              if (fileds.valid === false && fileds.field === 'postalCode') {
                return fileds.message
              } else {
                return ''
              }
            })}</p>
          </Grid>
        </Grid>

      </div>
    </>
  )
}

export default Billing;

import {
  Button,
  Checkbox,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  IconButton,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { useStyles } from "./style";
import axiosInstance from "../../environment/axiosInstances";
import App from "./StripeContainer";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import CircularProgress from "@mui/material/CircularProgress";
import TextScroller from "../Layout/Textscroll";
function OrderSummary(props) {
  const classes = useStyles();
  const history = useHistory();
  const [getOrderSummary, setOrderSummary] = React.useState({});
  const [premiumAddons, setPremiumAddons] = React.useState([]);
  const [freeAddons, setFreeAddons] = React.useState([]);
  const [callBacklData, setCallBackData] = React.useState(false);
  const [getSignupDetails, setFetSignupDetails] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const handleCallBack = (callBack) => {
    setCallBackData(callBack);
  };
  const handleLoading = (callback) => {
    setLoading(callback);
  };

  React.useEffect(() => {
    axiosInstance
      .get(`/sign-up/payment-summary?token=${localStorage.getItem("token")}`)
      .then((res) => {
        if (res.data.data) {
          setOrderSummary(res.data?.data);
          setPremiumAddons(res?.data?.data?.premiumAddons);
          setFreeAddons(res?.data?.data?.freeAddons);
        } else {
        }
      })
      .catch((err) => {
        if (err.request.status === 402) {
          window.location.href = "https://efone.ca/pricing";
          localStorage.removeItem("token");
          localStorage.removeItem("localNumbers");
        }
      });
  }, []);

  React.useEffect(() => {
    axiosInstance
      .get(`/sign-up?token=${localStorage.getItem("token")}`)
      .then((res) => {
        if (res.data.data) {
          setFetSignupDetails(res.data?.data?.[0]);
        } else {
        }
      })
      .catch((err) => {
        if (err.request.status === 402) {
          window.location.href = "https://efone.ca/pricing";
          localStorage.removeItem("token");
          localStorage.removeItem("localNumbers");
        }
      });
  }, []);
  var number = getSignupDetails?.phoneNumber;
  if (number) {
    var cleaned = ("" + number).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    number = ["(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  var newNumber =
    getSignupDetails.numberType === 3
      ? getSignupDetails?.temporaryNumber && getSignupDetails?.temporaryNumber
      : getSignupDetails?.temporaryNumber;
  if (newNumber) {
    var cleaned = ("" + newNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    newNumber = ["(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return (
    <div>
      <div>
        {getSignupDetails?.phoneNumber && getSignupDetails.numberType === 1 ? (
          <TextScroller text={number} val="phone" />
        ) : (
          ""
        )}
        {getSignupDetails?.temporaryNumber &&
        getSignupDetails.numberType === 3 ? (
          <TextScroller text={newNumber} val="temp" />
        ) : (
          ""
        )}
        {/* {getSignupDetails?.phoneNumber ? (
          <TextScroller
            text={
              getSignupDetails.numberType === 3 &&
              getSignupDetails.temporaryNumber
                ? newNumber
                : number
            }
          />
        ) : (
          ""
        )} */}
      </div>
      <Grid>
        <Paper
          elevation={5}
          className={classes.paper}
          style={{ width: "856px" }}
        >
          <>
            <Grid className={classes.dFlex}>
              <Typography>Order Summary</Typography>
              <Grid style={{ float: "right" }}>
                <Typography>
                  <b>Phone Number </b>: {number}
                </Typography>
                {getSignupDetails.numberType === 3 &&
                  getSignupDetails?.temporaryNumber && (
                    <Typography>
                      <b>Temporary Number</b> : {newNumber}
                    </Typography>
                  )}
              </Grid>
            </Grid>
          </>

          <Divider className={classes.divider} />
          <Grid container spacing={4}>
            <Grid item md={6}>
              <App
                classses={classes}
                parentCall={handleLoading}
                getOrderSummary={getOrderSummary}
              />
              {/* <TextField variant="outlined" size='small' className={classes.creditCard}/> */}
              {/* <Typography className={classes.text}>Your Credit card will be automatically charged $185.78 annually,
                            as well as applicable taxes and fees.
                        </Typography>
                        <Typography className={classes.smallText}>We Offer 30 days Money Back Guarantee for Your Satisfaction</Typography>
                        <div className={classes.conditions}>
                            <Checkbox size='small' />
                            <Typography className={classes.terms}>I accept Terms and Conditions</Typography>
                        </div>
                        <Button size='small' 
                        fullWidth variant='outlined' 
                        onClick={onSubmitCheckout}
                        className={classes.activeButton}> Start Your Activation - Lets's Go!</Button> */}
            </Grid>
            <Grid item md={6}>
              <div className={classes.dFlexOne}>
                <Typography className={classes.size}>
                  Subscription Type
                </Typography>
                <Typography className={classes.sizeOne}>
                  {getOrderSummary.subscriptionType === 2
                    ? "Annual "
                    : "Monthly "}
                </Typography>
              </div>
              {getOrderSummary.subscriptionType === 2 ? (
                <div className={classes.dFlexOneTwo}>
                  <Typography className={classes.size}>Annual Cost</Typography>
                  <Typography className={classes.size}>
                    ${getOrderSummary?.primaryAddons?.addonAnnualCost}
                  </Typography>
                </div>
              ) : (
                <div className={classes.dFlexOneTwo}>
                  <Typography className={classes.size}>Monthly Cost</Typography>
                  <Typography className={classes.size}>
                    ${getOrderSummary?.primaryAddons?.addonCost}
                  </Typography>
                </div>
              )}
              <Divider className={classes.divider} />
              <Typography className={classes.mTop} style={{ fontWeight: 700 }}>
                {premiumAddons.length > 0 ? "Premium Add-ons" : ""}{" "}
              </Typography>
              {premiumAddons.length > 0 && (
                <Divider className={classes.dividerOne} />
              )}
              {premiumAddons.length > 0 &&
                premiumAddons?.map((item) => {
                  return (
                    <div className={classes.dFlexOne}>
                      <Typography className={classes.size}>
                        {item.addonName}
                      </Typography>
                      <Typography className={classes.size}>
                        $
                        {getOrderSummary.subscriptionType === 2
                          ? item.addonAnnualCost
                          : item.addonCost}
                      </Typography>
                    </div>
                  );
                })}
              {premiumAddons.length > 0 ? (
                <Divider className={classes.divider} />
              ) : (
                ""
              )}
              <Typography className={classes.mTop} style={{ fontWeight: 700 }}>
                {getOrderSummary.freeAddons ? "Included Add-ons" : ""}
              </Typography>
              {freeAddons.length > 0 ? (
                <Divider className={classes.dividerOne} />
              ) : (
                ""
              )}
              {freeAddons.length > 0 &&
                freeAddons?.map((item) => {
                  return (
                    <div className={classes.dFlexOneTwo}>
                      <Typography className={classes.size}>
                        {item.addonName}
                      </Typography>
                      <Typography className={classes.sizeThree}>
                        FREE
                      </Typography>
                    </div>
                  );
                })}
              {freeAddons.length > 0 ? (
                <Divider className={classes.divider} />
              ) : (
                ""
              )}
              <div className={classes.dFlexOneTwo}>
                <Typography className={classes.mTopOne}>
                  Item Subtotal
                </Typography>
                <Typography className={classes.mTopOne}>
                  ${getOrderSummary?.total}
                </Typography>
              </div>
              <div className={classes.dFlexOneTwo}>
                <Typography className={classes.size}>HST</Typography>
                <Typography className={classes.size}>
                  ${getOrderSummary?.hst}
                </Typography>
              </div>
              <Divider className={classes.dividerOne} />
              <div className={classes.dFlexOneTwo}>
                <Typography className={classes.mTopOne}>
                  Item Subtotal
                </Typography>
                <Typography className={classes.mTopOne}>
                  ${getOrderSummary?.grandTotal}
                </Typography>
              </div>
              <Divider className={classes.dividerOne} />
              {/* <div>
                            <Typography className={classes.coupon}>Coupon</Typography>
                            <TextField size='small' variant="outlined" placeholder='Enter coupon' />
                         </div> */}
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <div className="number-block-btm">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <IconButton
              className={classes.nextIconActive}
              onClick={() => history.push("/signup/cdn1/profile")}
            >
              <GrFormPreviousLink
                className={classes.icon}
                color="#4D72F8"
                className="additional-class-name"
              />
            </IconButton>
          </div>
        </div>

        {/* <div>
                    <IconButton className={classes.nextIconActive}
                    // onClick={handleClick}
                    >
                        <GrFormNextLink className={classes.icon} />
                    </IconButton>
                </div> */}
      </div>
    </div>
  );
}

export default OrderSummary;

import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
    tabs: {
        padding: 5,
        paddingBottom: 5,
        // width: '50%',
        '@media (max-width:426px)':{
            marginBottom:'-4px'
        }
    },
    tab: {
        minHeight: 10,
        // height: 30,
        borderRadius: 5,
        background: '#2645AD',
        boxSizing: 'border-box',
        fontFamily: 'work sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        color: '#FFFFFF',
        textTransform: 'none',
    },
    tabActive: {
        minHeight: 10,
        // height: 30,
        borderRadius: 5,
        background: '#F2F2F2',
        boxSizing: 'border-box',
        fontFamily: 'work sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        color: '#C9C9C9',
        textTransform: 'none',
    },
    header: {
        fontSize: 16,
        fontFamily: 'work sans',
        color: '#464646',
        fontWeight: 500,
    },
    selectField: {
        marginTop: 5
    },
    headerOne: {
        fontSize: 16,
        fontFamily: 'work sans',
        color: '#464646',
        fontWeight: 500,
        marginTop: 10,
    },
    bFlex: {
        display: 'flex',
        justifyContent:'space-between',
        alignItems: 'center',
        marginTop: '15px'
    },
    availableButton: {
        fontSize: 16,
        textTransform: 'capitalize',
        fontFamily: 'work sans',
        padding: '3px 20px',
    },
    numButtonOne: {
        marginTop: 20,
    },
    numButton: {
        marginTop: 10,
    }
})
import { Button, Card, Tabs, Tab, Typography, Container, Radio } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import React from 'react'
import { useStyles } from './styles'
import CheckTwoToneIcon from '@material-ui/icons/CheckTwoTone';
import StepThree from '../Steps/StepThree';
import SignupServices from '../SignupServices';
import { Any } from '@react-spring/types';
import { useHistory } from 'react-router-dom';
import './planstyle.css';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import logo from '../../../assets/images/logo.png'
import PlanData from './PlanandFeatures';
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal';
import ReNumbers from '../ReNumbers/ReNumbers';
import v5axiosInstance from '../../../environment/v5axiosInstances'


function ChoosePlan(props: any) {
  const history = useHistory();
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const classes = useStyles()
  const [value, setValue] = React.useState<any>('1')
  const [packageData, setpackageData] = React.useState<any>([])
  const [selectedIndex, setselectedIndex] = React.useState<any>(null)
  const [id, setId] = React.useState('')
  const [alreadyselectedFeatures, setalreadyselectedFeatures] = React.useState<any>([])
  const [selectedFeauters, setSelectedFeatures] = React.useState([]);
  const [planDetails, setPlanDetails] = React.useState<any>([]);
  const [features, setFeatures] = React.useState([])
  const [openNumberModal, setOpenNumberModal] = React.useState(false)
  const [planId, setPlanId] = React.useState('')
  const [resNum, setResNum] = React.useState('')


  // const packIds = packageData[selectedIndex]?._id
  console.log('packid', planId)

  const handleClickNumber = () => {
    setOpenNumberModal(!openNumberModal)
  }

  // React.useEffect(() => {
  //   setSelectedFeatures([])
  // }, [selectedIndex])

  const selectedFeatures = (data: any) => {
    const copyFeatures: any = [...selectedFeauters];
    const test: any = copyFeatures.some((fae: any) => fae.featureId === data.featureId)
    console.log('tt__', test, data, copyFeatures)
    if (test) {
      console.log('tt__', copyFeatures.filter((cpyDT: any) => cpyDT.featureId !== data.featureId))
      setSelectedFeatures(copyFeatures.filter((cpyDT: any) => cpyDT.featureId !== data.featureId))
      return;
    } else {
      copyFeatures.push(data)
      setSelectedFeatures(copyFeatures)
      return;
    }
    // console.log('dt11 dt2____', copyFeatures, test)

  }
  const index = 1
  // var data = packageData
  // console.log('data',data);

  // let index: any = data?.findIndex((item: any) => {
  //   console.log('item',item);

  //   if (item?._id === selectedPackage?.packageId) {
  //     return item;
  //   }
  //   setselectedIndex(index)

  // })
  // console.log("result", index)
  const handleCardSelect = (id: any) => {
    setselectedIndex(id)
  }

  React.useEffect(() => {
    if (packageData) {
      setselectedIndex(0)
    }
  }, [packageData])


  const handleChange = (event: any, newValue: any) => {
    setValue(newValue)
  }
  const fetchPlans = () => {
    SignupServices.fetchPackageDetails().then((res: any) => {
      console.log('res', res)
      if (res.success) {
        const data = res.data;
        const planId = data.planDetails[0]._id
        console.log('plnId');
        setPlanId(planId)
        setPlanDetails(data)
        // setSelectedFeatures(data.planDetails[0].features)
        const categoryRes = res.data.categoryWiseFeatures
        setFeatures(categoryRes)
        // console.log('categoryRes', categoryRes);
      } else {

      }
    }).catch((err: any) => {

    });
  }


  const getUserData = (id: any) => {
    SignupServices.fetchUserData(id).then((res: any) => {
      if (res.success) {
        const dataa = res.data.reserveNumber
        setResNum(dataa)
        const data = res.data.featureData;
        setalreadyselectedFeatures(data)
        setSelectedFeatures(data)
        // setselectedPackage(data)
      } else {

      }
    }).catch((err: any) => {

    });
  }
  React.useEffect(() => {
    const id: any = localStorage.getItem("verifiedId")
    getUserData(JSON.parse(id))
  }, [id])

  React.useEffect(() => {
    fetchPlans()
  }, [])



  const handleSelectedPackage = () => {
    if (selectedIndex === null) {
      alert("Haven't selected any plan")
      return;
    }

    const filterArr: any = selectedFeauters.map((feature: any) => feature?.featureId).filter((fea1: any) => !alreadyselectedFeatures.some((fea2: any) => fea2.featureId === fea1))
    const uniqueArr: any = new Set(filterArr)
    const data = {
      userId: id,
      planId: planDetails?.planDetails[0]?._id,
      featureIds: [...uniqueArr].filter((arr: any) => arr !== undefined)
    }

    console.log('dt__', [...uniqueArr])
    // return
    SignupServices.savePackageDetails(data).then((res: any) => {
      // console.log('sttt',res)
      if (res.success) {
        // console.log("data", res.data)
        history.push("/v1/signup/checkout")
        // setpackageData(res.data)
      } else {

      }
    }).catch((err: any) => {

    });
  }

  React.useEffect(() => {
    const Id: any = localStorage.getItem("verifiedId")
    setId(Id)
    if (Id) {
      const newId = JSON.parse(Id)
      setId(newId)
    }
  }, [])

  // const featureIds = selectedFeauters.map((feature: any) => feature.featureId)
  // React.useEffect(() => {
  //   localStorage.setItem("featureIds", JSON.stringify(featureIds))
  //   localStorage.setItem("packIds", JSON.stringify(packIds))

  // }, [featureIds, packIds])

  const handleChoosePlan = () => {
    handleSelectedPackage()
    history.push("/v1/signup/checkout")
  }

  const createSignUp = () => {
    const userid: any = localStorage.getItem("verifiedId")
    const payLoad: any = {
      userId: JSON.parse(userid),
      planId: planId,
    }
    v5axiosInstance.post('/auth/checkout', payLoad).then((res: any) => {
      if (res.status === 200) {
        localStorage.removeItem("verifiedId")
        history.push("/v1/signup/register")
      }else{

      }
    }).catch((err: any) => {
      props.popUpAlert(err.response.data.message, "UPDATE_AGENT_SUCCESS", "error");

    })
  }

  return (
    <div>
      {
        openNumberModal && (
          <div>
            <Backdrop handlePopupModal={handleClickNumber} />
            <Modal title="Add Number" handlePopupModal={handleClickNumber}>
              <div className=''>
                <ReNumbers handleClickNumber={handleClickNumber} />
              </div>
            </Modal>
          </div>
        )
      }
      <div className={classes.logo}>
        <img className='logoimgmob' alt='logo' src={logo} />
      </div>
      <div className={classes.margings}>
        <StepThree />
        <div className='chooseplanheading'>
          <h3>Choose Plan</h3>
        </div>
        <Container className='planholder'>
          <TabContext value={value}>
            {/* <Tabs className={classes.tabs + " " + "choosetabsmob"}
              onChange={handleChange}
              value={value}
              variant="standard"
              TabIndicatorProps={{
                style: {
                  height: "0px",
                },
              }}
            >
              <Tab style={{ fontWeight: '500' }} label="Monthly Bill" value="1" className={value === "1" ? classes.tabActive : classes.tab} />
              <Tab style={{ fontWeight: '500' }} label="Yearly Bill" value="2" className={value === "2" ? classes.tabActive : classes.tab} />
            </Tabs> */}
            {/* <TabPanel value='1'> */}
            {/* <StepperOne/> */}

            {/* <div className={classes.tww}>
                {
                  packageData?.map((data: any, i: any) => {
                    if (i <= 0) {
                      return (
                        <Card className={selectedIndex === i || index == i ? classes.newcard : classes.onenewcard} key={i} onClick={() => handleCardSelect(i)}>
                          <Typography className={selectedIndex === i || index == i ? classes.protypo : classes.freetypo}>{data.packageName}</Typography>
                          {
                            value === '1' ?
                              <div style={{}}>
                                <Typography className={selectedIndex === i || index == i ? classes.numberMonthly : classes.numberfree}>${data.cost}</Typography>
                                <Typography className={selectedIndex === i || index == i ? classes.monthly : classes.nummonthly}>Monthly </Typography>
                              </div>
                              :
                              " "
                          }

                          <Typography className={selectedIndex === i || index == i ? classes.twotool : classes.onetool}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do<br></br>eiusmod tempor incididunt ut labore et dolore magna aliqua.<br></br>Ut enim ad minim veniam
                          </Typography>
                          <Grid className='freefeatures' container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid className='free1' item xs={12} sm={4} md={4} lg={4}>
                              <ul>
                                <li> User To User Calling</li>
                                <li>User To User Messaging</li>
                                <li>User To User Group Messaging</li>
                                <li>Mutliple Devices - Management</li>
                                <li>Privacy-Settings</li>

                              </ul>
                            </Grid>
                            <Grid className='free2' item xs={12} sm={4} md={4} lg={4}>
                              <ul>

                                <li>Notication Settings</li>
                                <li>Status ( Text & Video)</li>
                                <li>Contact Upload</li>
                                <li>User To User Group Call</li>

                              </ul>
                            </Grid>
                            <Grid className='free3' item xs={12} sm={4} md={4} lg={4}>
                              <ul>
                                <li>Schedule Messages</li>
                                <li>Disapperaring Message</li>
                                <li>Group Poll In Side Messaging</li>
                              </ul>
                            </Grid>
                          </Grid>
                          <div className={classes.div}>
                            {
                              data?.PackageFeatures?.map((item: any) => (
                                <div className={classes.righticons}>
                                  <span className={selectedIndex === i || index == i ? classes.twoicons : classes.oneicons}><CheckTwoToneIcon /></span>
                                  <Typography className={selectedIndex === i || index == i ? classes.twotool : classes.onetool}>{item.description}</Typography>
                                </div>
                              ))
                            }
                          </div>
                          <Button
                            size='small'
                            variant='outlined'
                            className={selectedIndex === i || index == i ? classes.twobutton : classes.onebutton}
                            onClick={() => handleSelectedPackage(data._id)}
                            style={{ textTransform: 'none' }}

                          >
                            Subscribe
                          </Button>

                        </Card>
                      )
                    }

                  })
                }

              </div> */}
            {/* </TabPanel> */}
            {/* <TabPanel value='2'>
              <div className={classes.tww}>
                {
                  packageData?.map((data: any, i: any) => {
                    if (i <= 2) {
                      return (
                        <Card className={selectedIndex === i ? classes.newcard : classes.onenewcard} key={i} onClick={() => handleCardSelect(i)}>
                          <Typography className={selectedIndex === i ? classes.protypo : classes.freetypo}>{data.packageName}</Typography>
                          {
                            value === '2' ?
                              <div style={{ display: 'flex' }}>
                                <Typography className={selectedIndex === i || index == i ? classes.numberMonthly : classes.numberfree}>${data.cost}</Typography>
                                <Typography className={selectedIndex === i || index == i ? classes.monthly : classes.nummonthly}>Yearly </Typography>
                              </div>
                              :
                              " "
                          }


                          <Typography className={selectedIndex === i ? classes.twotool : classes.onetool}>
                            For Anyone validating Calls as a
                            Professional prototyping Tool.
                          </Typography>
                          <div className={classes.div}>
                            {
                              data.PackageFeatures?.map((item: any) => (
                                <div className={classes.righticons}>
                                  <span className={selectedIndex === i ? classes.twoicons : classes.oneicons}><CheckTwoToneIcon /></span>
                                  <Typography className={selectedIndex === i ? classes.twotool : classes.onetool}>{item.description}</Typography>
                                </div>
                              ))
                            }
                          </div>
                          <Button
                            size='small'
                            variant='outlined'
                            className={selectedIndex === i ? classes.twobutton : classes.onebutton}

                            style={{ textTransform: 'none' }}

                          >
                            Subscribe
                          </Button>

                        </Card>
                      )
                    }

                  })
                }


              </div>
            </TabPanel> */}


            {/* new code  */}
            <div className={classes.tww}>
              {/* {
                planDetails?.map((data: any, i: any) => {
                  if (true) {
                    return ( */}
              <PlanData
                planDetails={planDetails}
                // handleSelectedPackage={handleSelectedPackage}
                // selectedFeaturesFun={selectedFeatures}
                selectedFeauters={selectedFeauters}
              // selectedIndex={selectedIndex}
              // index={i}
              // handleCardSelect={handleCardSelect}
              // selectedPackage={selectedPackage}
              />
              {/* )
                  }

                })
              } */}

            </div>

          </TabContext>
        </Container>

        <Container>
          <div className='addonholder'>
            <div style={{}}>
              <h2>Add ons</h2>
              <span className='expandadd'>Expand your system as needed</span>
            </div>
            {/* <Grid className='addongrid' container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}> */}
            {
              features.length > 0 && (
                features.map((feature: any) => (
                  feature.features.length > 0 && (
                    <div>
                      <h3> {feature.cateroryName}</h3>
                      <div style={{ display: 'flex', gap: 15 }}>
                        {
                          feature.features.map((fea: any) => {
                            const selectedId: any = selectedFeauters?.some((featu: any) => featu.featureId === fea.featureId)
                            console.log('selectedId', selectedId)
                            return (
                              <Grid className='addon' style={{ padding: 10 }} item xs={6} sm={6} md={4} lg={4} onClick={feature.cateroryName === "Virtual Phones" ? () => handleClickNumber() : () => { }}>
                                <span className='addonheading'>{fea.featureName}</span>
                                <div className='chkicon'>
                                  {/* {selectedId && 'selected'} */}
                                  {/* <Checkbox  {...label} icon={<CircleOutlinedIcon />} checkedIcon={<Radio checked={selectedId}/>} onClick={() => selectedFeatures(fea)} /> */}
                                  <Radio checked={selectedId} onClick={() => selectedFeatures(fea)} />
                                </div>
                                <span className='addonsub'>Make and receive calls using any number, including conference calls with up to 5 people.</span>
                                <Typography className='addonprice'>${fea.featureCost}</Typography>

                              </Grid>
                            )
                          })
                        }
                      </div>
                      {
                        feature.cateroryName === "Virtual Phones" &&
                        <Typography style={{marginTop:20}}><b>ReservedNumber:</b> {resNum}</Typography>

                      }

                    </div>
                  )
                ))
              )
            }
            {/* </Grid> */}
          </div>
        </Container>

        <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
          <Button
            variant='outlined'
            size='small'
            className={classes.back}
            onClick={() => history.push("/v1/signup/reservenumber")}
          >
            Back
          </Button>
          <div>
            {
              <div>
                {
                  selectedFeauters?.length > 0 ?
                    <>
                      <Button
                        variant='outlined'
                        size='small'
                        className={classes.button}
                        onClick={handleChoosePlan}
                      // onClick={() => history.push("/v1/signup/checkout")}
                      >
                        Next
                      </Button>
                    </>
                    :
                    <Button
                      variant='outlined'
                      size='small'
                      className={classes.button}
                      onClick={createSignUp}
                    >
                      Carate SignUp
                    </Button>

                }
              </div>
            }
          </div>

        </div>
      </div>
    </div>
  )
}

export default ChoosePlan
import { Divider, Typography } from '@material-ui/core'
import React from 'react'
import './style.css'

function SetepOne() {
    return (
        <>
            <div className='alingnments two three four'>
                <div className='flexDiv'>
                <div className='stepbox'>
                <span className='stepnum one active'>1</span>
                <Typography className='headerOne '><span className='innum'>1.</span>REGISTER</Typography>
                <Divider className='dividerOne mobhid'/>
                    </div>
                    <div className='stepbox'>
                    <span className='stepnum two active'>2</span>
                    <Typography className='headerOne'><span className='innum'>2.</span>PHONE NUMBERS</Typography>
                <Divider className='dividerTwo active mobhid'/>
                    </div>
                    <div className='stepbox'>
                    <span className='stepnum'>3</span>
                    <Typography className='headerOne nonactive'><span className='innum'>3.</span>CHOOSE PLAN</Typography>
                <Divider className='dividerThree mobhid'
                />
                    </div>
                    <div className='stepbox'>
                    <span className='stepnum'>4</span>
                    <Typography className='headerOne nonactive'><span className='innum'>4.</span>CHECKOUT</Typography>
                <Divider className='dividerFour mobhid' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SetepOne
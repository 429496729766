import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
    localNumber: {
        boxShadow: 'rgb(226 226 226) 0px 0px 0.5em',
        fontSize: 16,
        textTransform: 'capitalize',
        fontWeight: 700,
        color: "#377dff",
        fontFamily: 'work sans',
        padding: '18px 60px'
    },
    localNumberActive: {
        boxShadow: 'rgb(226 226 226) 0px 0px 0.5em',
        fontSize: 16,
        textTransform: 'capitalize',
        fontWeight: 700,
        color: "#377dff",
        fontFamily: 'work sans',
        padding: '18px 60px',
        backgroundColor: '#4D72F8',
    },
    type: {
        fontWeight: 600
    },
    icon: {
        // background: '#4D72F8',
        borderRadius: 14,
        color: 'white'
    },
    nextIconActive: {
        border: "5px solid #4D72F8",
        // float: 'right',
        margin: 10,
        '&:hover': {
            border: "5px solid #4D72F8",
        },
        "&:active": {
            backgroundColor: "blue",
        },
    },
    nextIconActive1: {
        backgroundColor: '#4D72F8',
        border: "5px solid #4D72F8",
        // float: 'right',
        margin: 10,
        '&:hover': {
            backgroundColor: '#4D72F8',
        },
        "&:active": {
            backgroundColor: "blue",
        },
    },
    transNumber: {
        marginTop: 20,
        boxShadow: 'rgb(226 226 226) 0px 0px 0.5em',
        fontSize: 16,
        textTransform: 'capitalize',
        fontWeight: 700,
        color: "#377dff",
        fontFamily: 'work sans',
        padding: '18px 40px'
    }
})
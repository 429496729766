import React from "react";
import {
  Card,
  Tabs,
  Tab,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  CardContent,
  Divider,
  Button,
  IconButton,
} from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import ReactSelect from "react-select";
import PhoneIcon from "@material-ui/icons/Phone";
import { GrFormNextLink } from "react-icons/gr";
import { BiRefresh } from 'react-icons/bi'
import { AiOutlineCheck } from 'react-icons/ai'
import { useStyles } from "../styles";
import { useHistory } from "react-router-dom";
import DepartmentServices from '../../services';
import CountryStates from "../CountryStates/CountryStates";
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import * as popUpActionCreator from "../../../../store/Actions/popup-actionCreator";
import CircularProgress from '@mui/material/CircularProgress';
import { connect } from "react-redux";
import { BsCheckLg } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { $CombinedState } from "redux";
import logo from '../../../../assets/images/Lodingg.gif'
import Loader from '../../../../assets/images/ZZ5H.gif'
const countryOptions = [
  { label: "canada", value: 1 },
  { label: "united states", value: 2 },
];
function Default(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState("1");
  const [loading, setLoading] = React.useState(false)
  const [numToggle, setNumToggle] = React.useState(false)
  const [index, setIndex] = React.useState("0")
  const [id, setId] = React.useState("")
  const [load, setLoad] = React.useState(false)
  const [addData, setAddData] = React.useState<any>(
    { "phoneNumber": "" })



  const handleSelectedNumbers = (data: any) => {
    DepartmentServices.postNumberList(data, localStorage.getItem("token")).then((res: any) => {

      if (res.success) {
        props.popUpAlert(res.data.message, "UPDATE_AGENT_SUCCESS", "success");
        history.push("/signup/cdn1/profile")
      } else {
        props.popUpAlert(res.message, "UPDATE_AGENT_SUCCESS", "error");
      }
    }).catch((err: any) => {
      // send error
      props.popUpAlert(err.data.message, "UPDATE_AGENT_SUCCESS", "error");
    })

  }
  // React.useEffect(() => {
  //   if (props.city) {
  //     props.onChangeCity()
  //   }
  // }, [props.city])



  const handleSelectedNumber = (num: any, id: any) => {
    setLoad(true)
    props.parentCall(num)
    setNumToggle(!numToggle)
    setId(id)
    setTimeout(() => {
      setLoad(false)
    }, 1000);
  }
  return (
    <div>
      <TabPanel value="1" classes={{ root: classes.padding }}>
        <CountryStates country={props.country} countryOptions={props.countryOptions} onChangeStateType={props.onChangeStateType} states={props.states} state={props.state}
          onChangeCountryType={props.onChangeCountryType} />
        <Grid container >
          <Grid item md={12} className={classes.gridItem}>
            {/* <Typography className={classes.text}>Country</Typography>
            <FormControl fullWidth size="small" variant="outlined">
              <ReactSelect
                fullWidth
                options={countryOptions}
                displayEmpty
                placeholder="Select Country"
                inputProps={{ "aria-label": "Without label" }}
                onChange={onChangeCountryType}
              />
            </FormControl>  */}

            <Typography className={classes.headerOne}>City</Typography>
            <FormControl fullWidth size="small" variant="outlined">
              <ReactSelect
                fullWidth
                displayEmpty
                options={props.citiesOptions}
                value={props.city}
                placeholder="Select City"
                inputProps={{ "aria-label": "Without label" }}
                onChange={props.onChangeCity}
              />
            </FormControl>
          </Grid>
          <Grid container style={{ width: "100%", marginLeft: "46px", margin: '0 auto' }}>
            <Grid item md={12} style={{ margin: '0 auto' }} >
              <div className={classes.bFlex} >
                <div>
                  <Button size='small' className={classes.availableButton}>Available Numbers</Button>
                </div>
                <div>
                  <Button className={classes.availableButton}
                    // style={{ marginLeft: "128px" }}
                    onClick={props.handleChangeNumbers}
                    startIcon={<BiRefresh />}>Refresh Numbers</Button>
                </div>
              </div>
              {props.loading ?
                <div style={{ margin: 'auto', alignItems: "center", color: "lightgray", marginBottom: "80px", textAlign: 'center', width: "100%", height: "80px", borderStyle: "dotted" }}>
                  {/* <CircularProgress disableShrink /> */}
                  <img src={logo} alt="loading..." style={{ height: "50px", marginTop: "7px" }} />
                </div> :
                // <Card className={classes.availableCard} style={{ marginBottom: 75 }}>
                <CardContent>


                  <div>
                    <div className={classes.Numbers}>

                      {props.localNUmbers?.length > 0 ? (

                        props.localNUmbers?.slice(props.index, props.index + 5).map((loc: any, i: any) => (
                          <div
                            style={{ width: "100%", display: "flex", marginBottom: "20px", alignItems: "center", justifyContent: "space-between", cursor: "ponter", border: "0.125rem solid #d5d5d5" }}
                            className={props.addData?.phoneNumber === loc?.number ? classes.numButtonOneActive : classes.numButtonOne}
                            onClick={() => handleSelectedNumber(loc?.number, i)}
                          >
                            <div style={{ alignContent: "center" }}>
                              <Button fullWidth
                                className={props.addData?.phoneNumber === loc?.number ? classes.numButtontwoActive : classes.numButtontwo}
                                style={{ marginLeft: "92px", width: "100%", pointerEvents: "none" }}
                                onClick={() => handleSelectedNumber(loc?.number, i)}>
                                <CallRoundedIcon className={props.addData?.phoneNumber === loc?.number ? classes.callIconActive : classes.callIcon} />&nbsp;&nbsp;  {loc?.number}
                              </Button>
                            </div>
                            <div>
                              {load && props.addData?.phoneNumber === loc?.number ? <img src={Loader} alt="loading..." style={{ height: "20px", marginTop: "7px", color: "lightgray", marginRight: "18px" }} /> : props.addData?.phoneNumber === loc?.number && <BsCheckLg style={{ marginRight: "18px", fontWeight: "600px" }} />}
                            </div>
                          </div>
                          // <Button fullWidth startIcon={<CallRoundedIcon />} variant="outlined"
                          //   className={props.addData?.phoneNumber === loc?.number ? classes.numButtonOneActive : classes.numButtonOne}
                          //   onClick={() => handleSelectedNumber(loc?.number, i)} endIcon={props.addData?.phoneNumber === loc?.number && <AiOutlineCheck style={{ float: "right" }} />}> {loc?.number}
                          // </Button>
                        ))
                      ) :
                        !props.loading &&
                        <Card className={classes.availableCard} style={{ marginBottom: "25px" }}>
                          <div className={classes.errMsg}>
                            {
                              props.city ?
                                <>
                                  <p style={{ textAlign: 'center' }}>We don't have any  {props.city?.label} numbers available at the moment.Would you please select any other nearby city or contact us for backorder status.</p>

                                </>
                                :
                                <>
                                  <p style={{ textAlign: 'center',marginLeft:120}}>Please Select City</p>
                                </>
                            }

                            {/* {
                              if(props.city){
                                <p style={{ textAlign: 'center' }}>We don't have any  {props.city?.label} numbers available at the moment.Would you please select any other nearby city or contact us for backorder status.</p>
                              } else {
                                <p style={{ textAlign: 'center' }}>Please Select City</p>
                              }
                            } */}


                          </div>
                        </Card>


                      }
                    </div>

                  </div>

                </CardContent>
                // </Card>
              }
            </Grid>

          </Grid>


        </Grid>

      </TabPanel>

    </div >
  );
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    popUpAlert: (err: any, instance: any, type: any) => dispatch(popUpActionCreator.initglobalPopup(err, instance, type))
  }
}

export default connect(null, mapDispatchToProps)(Default)


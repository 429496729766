import React from "react";
import {
  Card,
  Tabs,
  Tab,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  CardContent,
  Divider,
  Button,
  IconButton,
  Radio,
  CircularProgress,
} from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import TabPanel from "@material-ui/lab/TabPanel";
import ReactSelect from "react-select";
import PhoneIcon from "@material-ui/icons/Phone";
import { GrFormNextLink } from "react-icons/gr";
import { BiRefresh } from 'react-icons/bi'
import { useStyles } from "./style";
import './style.css';
// import UserNumberServices from '../../../userNumbersServices/usersNumbersServices'
// import UserNumberServices from '../../../../UsersNumbers/userNumbersServices/usersNumbersServices'
import CountryStates from "../CountryStates/CountryStates";
import { useSelector } from 'react-redux'
import { $CombinedState } from "redux";
// import logo from '../../../../../assets/images/Lodingg.gif'
import './style'

const numbersdata: any = [

  { id: 5873286593, npanxx: "587328", ratecenter: "CALGARY", state: "AB", match: "exact" },
  { id: 5873286588, npanxx: "587328", ratecenter: "CALGARY", state: "AB", match: "exact" },

  { id: 5873286585, npanxx: "587328", ratecenter: "CALGARY", state: "AB", match: "exact" },

  { id: 5873286580, npanxx: "587328", ratecenter: "CALGARY", state: "AB", match: "exact" },

  { id: 5873286579, npanxx: "587328", ratecenter: "CALGARY", state: "AB", match: "exact" },

  { id: 5873286576, npanxx: "587328", ratecenter: "CALGARY", state: "AB", match: "exact" },

  { id: 5873286571, npanxx: "587328", ratecenter: "CALGARY", state: "AB", match: "exact" },

  { id: 5873286570, npanxx: "587328", ratecenter: "CALGARY", state: "AB", match: "exact" },

  { id: 5873286569, npanxx: "587328", ratecenter: "CALGARY", state: "AB", match: "exact" },

  { id: 5873286567, npanxx: "587328", ratecenter: "CALGARY", state: "AB", match: "exact" },

  { id: 5873286565, npanxx: "587328", ratecenter: "CALGARY", state: "AB", match: "exact" }

]

const countryOptions = [
  { label: "canada", value: 1 },
  { label: "united states", value: 2 },
];

function Default(props: any) {
  const classes = useStyles();
  const [value, setValue] = React.useState("1");
  const [index, setIndex] = React.useState("0");
  const [addData, setAddData] = React.useState<any>({
    phoneNumber: "",
  })

  const [currentData, setCurrentdata] = React.useState([])
  const [data, setData] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState<any>(null)
  const noofElements = 12;
  const paginate = (page: number) => {
    const startIndex = Math.floor(page * noofElements);
    const endindex = (page + 1) * noofElements;
    const splitData = currentData.slice(startIndex, endindex)
    setData([...splitData])
  }

  console.log("currentData", currentData, data,addData)

  const handleChangeNumbers = () => {
    setIndex("4")
  }
  const handleSelectedNumbers = () => {
    const sendData: any = {
      phoneNumber: addData.phoneNumber
    }
    // UserNumberServices.PostNumbers(sendData).then((res: any) => {
    //   if (res.success) {
    //     props.handlePopupModal()
    //     const data = res.data;
    //     props.selectedNumber(data)
    //   }
    //   else {

    //   }
    // }).catch((err: any) => {
    //   // send error
    // })

  }

  const [number, setNumber] = React.useState<any>()

  const handleNumber = (number: any) => {
    setNumber(number)
    props.handleSelectedNumber(number)
  }

  React.useEffect(() => {
    paginate(currentPage)
  }, [currentPage, currentData])

  React.useEffect(() => {
    console.log(props.localNumbers)
    setCurrentdata(props.localNumbers)
    setCurrentPage(0)
    //setData([])
  }, [props.localNumbers, props.localNumbers.length])

  // console.log('data splitted', currentPage, Math.ceil(currentData.length/noofElements), currentData)


  return (
    <div>
      {
        props?.reserveNumber ?
          ""
          :
          <div>
            <CountryStates countryOptions={props.countryOptions}
              country={props.country}
              onChangeStateType={props.onChangeStateType}
              states={props.states}
              state={props.state}
              onChangeCountryType={props.onChangeCountryType}
              onChangeCity={props.onChangeCity}
              city={props.city}
              cities={props.citiesOptions}
            />
            <div>
              {props.loading ?
                <div style={{ margin: 'auto', alignItems: "center", color: "lightgray", textAlign: 'center', width: "100%", height: "80px" }}>
                  <CircularProgress disableShrink />
                  {/* <img src={logo} alt="loading..." style={{ height: "50px", marginTop: "7px" }} /> */}
                </div> :
                <Grid container className="reserveinnerholder" style={{ display: 'flex',alignItems:'center'}}>
                  <Grid item md={1} >
                      {
                        data?.length > 0 && currentPage !== 0 ?
                          <Button size="small" style={{}}
                          >
                            <div onClick={() => {
                              setCurrentPage(currentPage - 1)
                            }} style={{
                              width: 45, height: 45, background: "#4D72F8",
                              display: 'grid', justifyContent: 'center', borderRadius: "50%", color: 'white',
                              alignItems: 'center'
                            }}><BiChevronLeft fontSize={25} /></div>
                          </Button>
                          : " "
                      }
                   
                  </Grid>
                  <Grid container md={10} lg={10} xl={8} >
                    <ul style={{ textDecoration: "none", listStyleType: 'none', display: 'flex', flexWrap: 'wrap' }}>
                      {
                        data?.length > 0 ? (
                          data.map((loc: any, i: any) => {
                            return (
                              <Grid item md={5} lg={4} xl={4} >
                                {/* <li key={i} style={{ textDecoration: "none", marginBottom: 20 }} onClick={() => handleNumber(loc?.number)}>
                                  <input type="radio" id={i} name="number" value={loc?.number} style={{ marginRight: 20, marginLeft: -16, accentColor: 'green' }} />
                                  <label htmlFor={i}
                                    style={{ fontFamily: 'Arial', fontStyle: 'normal', fontWeight: 400, fontSize: 14, letterSpacing: '0.1px', color: '#929292' }}
                                  >{loc?.number} </label>
                                </li> */}

                                <Button
                                  key={i}
                                  className={number === loc.didSummary ? classes.numberButtonActive : classes.numberButton}
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  onClick={() => handleNumber(loc?.didSummary)}
                                  // startIcon={<Radio />}
                                >
                                  {loc?.didSummary}
                                </Button>
                              </Grid>

                            )
                          })
                        ) : (
                            <Typography className={classes.choose} style={{marginLeft:150, textAlign: 'center', padding: '0px',color:'white'  }}>Choose Options</Typography>
                        )
                      }
                    </ul>
                    {/* <Typography className={classes.choose} style={{ textAlign: 'center'}}>Choose Options</Typography> */}
                  </Grid>

                  <Grid item md={1}>
                    <Button size="small" style={{ float: 'right' }}
                    >
                      <div onClick={() => {
                        setCurrentPage(currentPage + 1)
                      }} style={{
                        width: 45, height: 45, background: "white",
                        display: 'grid', justifyContent: 'center', borderRadius: "50%", color: '#0B1B37',
                        alignItems: 'center'
                      }}><BiChevronRight fontSize={25} /></div>
                    </Button>
                  </Grid>
                </Grid>

              }
            </div>
          </div>
      }

    </div>
  )
}

export default Default;

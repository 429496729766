import React from "react";
import {
  Card,
  Tabs,
  Tab,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  CardContent,
  Divider,
  Button,
  IconButton,
} from "@material-ui/core";
import CircularProgress from '@mui/material/CircularProgress';
import TabContext from "@material-ui/lab/TabContext";
import { BiRefresh } from 'react-icons/bi'
import TabPanel from "@material-ui/lab/TabPanel";
import ReactSelect from "react-select";
import PhoneIcon from "@material-ui/icons/Phone";
import { useHistory } from "react-router-dom";
import { GrFormNextLink } from "react-icons/gr";
import { useStyles } from "../styles";
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import DepartmentServices from '../../services';
import CountryStates from "../CountryStates/CountryStates";
import { useSelector } from 'react-redux'
import * as popUpActionCreator from "../../../../store/Actions/popup-actionCreator";
import { connect } from "react-redux";
import logo from '../../../../assets/images/Lodingg.gif'
import Loader from '../../../../assets/images/ZZ5H.gif'
import { BsCheckLg } from 'react-icons/bs'
import { AiOutlineCheck } from 'react-icons/ai'
const countryOptions = [
  { label: "canada", value: 1 },
  { label: "united states", value: 2 },
];
function Repeating(props: any) {
  const classes = useStyles();
  const [value, setValue] = React.useState("1");
  const history = useHistory();
  const [numToggle, setNumToggle] = React.useState(false)
  const [index, setIndex] = React.useState("0")
  const [id, setId] = React.useState("")
  const [load, setLoad] = React.useState(false)
  const [addData, setAddData] = React.useState<any>(
    { "phoneNumber": "" })


  const handleSelectedNumbers = (data: any) => {
    if (addData?.departmentId) {
      DepartmentServices.postNumberList(data, localStorage.getItem("token")).then((res: any) => {

        if (res.success) {
          props.popUpAlert(res.data.message, "UPDATE_AGENT_SUCCESS", "success");
          history.push("/signup/cdn1/phone-number/port/number")
        } else {
          props.popUpAlert(res.message, "UPDATE_AGENT_SUCCESS", "error");
        }
      }).catch((err: any) => {
        // send error
        props.popUpAlert(err.data.message, "UPDATE_AGENT_SUCCESS", "error");
      })
    } else {
      //should send error user id required
    }
  }
  const handleSelectedNumber = (num: any, id: any) => {
    setLoad(true)
    props.parentCall(num)
    setNumToggle(!numToggle)
    setId(id)
    setTimeout(() => {
      setLoad(false)
    }, 1000);
  }
  return (
    <div>
      <TabPanel value="2" classes={{ root: classes.padding }}>
        <CountryStates countryOptions={props.countryOptions} country={props.country}
          onChangeStateType={props.onChangeStateType} states={props.states} state={props.state}
          onChangeCountryType={props.onChangeCountryType} />
        <Grid container >
          <Grid item md={12} className={classes.gridItem}>
            <Typography className={classes.text}>
              City 
            </Typography>
            <FormControl fullWidth size="small" variant="outlined">
              <ReactSelect
                fullWidth
                displayEmpty
                options={props.citiesOptions}
                placeholder="Select City"
                value={props.city}
                inputProps={{ "aria-label": "Without label" }}
                onChange={props.onChangeCity}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container style={{ width: "100%", marginLeft: "46px", margin: '0 auto' }}>
          <Grid item md={12} style={{ margin: '0 auto' }} >
            <div className={classes.bFlex} >
              <div>
                <Button size='small' className={classes.availableButton}>Available Numbers</Button>
              </div>
              <div>
                <Button className={classes.availableButton}
                  // style={{ marginLeft: "128px" }}
                  onClick={props.handleChangeNumbers}
                  startIcon={<BiRefresh />}>Refresh Numbers</Button>
              </div>
            </div>
            {props.loading ?
              <div style={{ margin: 'auto', alignItems: "center", color: "lightgray", marginBottom: "80px", textAlign: 'center', width: "100%", height: "80px", borderStyle: "dotted" }}>
                {/* <CircularProgress disableShrink /> */}
                <img src={logo} alt="loading..." style={{ height: "50px", marginTop: "7px" }} />
              </div> :
              // <Card className={classes.availableCard} style={{ marginBottom: 75 }}>
              <CardContent>

                <div>
                  <div className={classes.Numbers}>

                    {props.localNUmbers?.length > 0 ? (

                      props.localNUmbers?.slice(props.index, props.index + 5).map((loc: any, i: any) => (
                        <div
                          style={{ width: "100%", display: "flex", marginBottom: "20px", alignItems: "center", justifyContent: "space-between", cursor: "ponter", border: "0.125rem solid #d5d5d5" }}
                          className={props.addData?.phoneNumber === loc?.number ? classes.numButtonOneActive : classes.numButtonOne}
                          onClick={() => handleSelectedNumber(loc?.number, i)}
                        >
                          <div style={{ alignContent: "center" }}>
                            <Button fullWidth
                              className={props.addData?.phoneNumber === loc?.number ? classes.numButtontwoActive : classes.numButtontwo}
                              style={{ marginLeft: "92px", width: "100%", pointerEvents: "none" }}
                              onClick={() => handleSelectedNumber(loc?.number, i)}>
                              <CallRoundedIcon className={props.addData?.phoneNumber === loc?.number ? classes.callIconActive : classes.callIcon} />&nbsp;&nbsp;  {loc?.number}
                            </Button>
                          </div>
                          <div>
                            {load && props.addData?.phoneNumber === loc?.number ? <img src={Loader} alt="loading..." style={{ height: "20px", marginTop: "7px", color: "lightgray", marginRight: "18px" }} /> : props.addData?.phoneNumber === loc?.number && <BsCheckLg style={{ marginRight: "18px", fontWeight: "600px" }} />}
                          </div>
                        </div>

                        // <Button fullWidth startIcon={<CallRoundedIcon />} variant="outlined"
                        //   className={props.addData?.phoneNumber === loc?.number ? classes.numButtonOneActive : classes.numButtonOne}
                        //   onClick={() => props.parentCall(loc?.number)}
                        //   endIcon={props.addData?.phoneNumber === loc?.number && <AiOutlineCheck style={{ float: "right" }} />}> {loc?.number}</Button>
                      ))
                    ) :
                      !props.loading &&
                      <Card className={classes.availableCard} style={{ marginBottom: "25px" }}>
                        <div className={classes.errMsg}>
                        {
                              props.city ?
                                <>
                                    <p style={{ textAlign: 'center' }}>We don't have any repeating numbers in the {props.city?.label} area code  </p>

                                </>
                                :
                                <>
                                  <p style={{ textAlign: 'center',marginLeft:120 }}>Please Select City </p>
                                </>
                            }

                          
                        
                        </div>
                      </Card>


                    }
                  </div>

                </div>

              </CardContent>
              // </Card>
            }
          </Grid>

        </Grid>
      </TabPanel>

    </div>
  );
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    popUpAlert: (err: any, instance: any, type: any) => dispatch(popUpActionCreator.initglobalPopup(err, instance, type))
  }
}

export default connect(null, mapDispatchToProps)(Repeating)



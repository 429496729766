import { Divider, Typography } from '@material-ui/core'
import React from 'react'
import './style.css'
function StepOne() {
  return (
    <>
    <div className='alingnments'>
        <div className='flexDiv'>
            <div className='stepbox'>
                <span className='stepnum one active'>1</span>
                <Typography className='headerOne'><span className='innum'>1.</span>REGISTER</Typography>
                <Divider className='dividerOne mobhid'/>
            </div>
            <div className='stepbox two'>
            <span className='stepnum'>2</span>
                <Typography className='headerOne nonactive'><span className='innum'>2.</span>RESERVE NUMBERS</Typography>
                <Divider className='dividerTwo mobhid'/>
            </div>
            <div className='stepbox three'>
            <span className='stepnum'>3</span>
                <Typography className='headerOne nonactive'><span className='innum'>3.</span>CHOOSE PLAN</Typography>
                <Divider className='dividerThree mobhid'
                />
            </div>
            <div className='stepbox four'>
            <span className='stepnum'>4</span>
                <Typography className='headerOne nonactive'><span className='innum'>4.</span>CHECKOUT</Typography>
                <Divider className='dividerFour mobhid' />
            </div>
        </div>
    </div>
</>
  )
}

export default StepOne
// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyCRm-liPze9UVRiiVFY6bkOy7smjWY8c7E",
  authDomain: "efone-v5.firebaseapp.com",
  projectId: "efone-v5",
  storageBucket: "efone-v5.appspot.com",
  messagingSenderId: "652059821839",
  appId: "1:652059821839:web:c5455c8795386f4552a07a",
  measurementId: "G-4X440E474B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth : any = getAuth(app)
export default app;





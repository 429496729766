import { Button, Typography } from '@material-ui/core'
import {
    onAuthStateChanged,
    applyActionCode
} from "firebase/auth"
import React from 'react'
import { auth } from '../firebase/firebase'
import {getAuth} from 'firebase/auth'

function VerifyLink(props: any) {
    //  const data = getAuth()
    //  console.log('data____',data);
     
    React.useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        const oobCode: any = queryParams.get("oobCode")
        applyActionCode(auth, oobCode).then((res: any) => {
            //alert('verify1111')
            // console.log('resp', res)
            const user = auth.currentUser;
            // auth().currentUser.reload();
            console.log('resp', user)

            //auth().currentUser.emailVerified === true;

        }).catch((error) => {
            console.log('actionCode');
            alert('tttt')

        });
    }, [])


    console.log('props', props)
    return (

        <div>
            <Typography>Your email hasbeen Verified</Typography>
            {/* <Button onClick={()=>handleVerifyEmail(auth,'')}>Verify</Button> */}
        </div>
    )
}

export default VerifyLink
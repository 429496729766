import React from 'react'
import { useStyles } from './styles'
import CheckTwoToneIcon from '@material-ui/icons/CheckTwoTone';
import StepThree from '../Steps/StepThree';
import SignupServices from '../SignupServices';
import { Any } from '@react-spring/types';
import { useHistory } from 'react-router-dom';
import './planstyle.css';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import logo from '../../../assets/images/logo.png'
import { Button, Card, Tabs, Tab, Typography, Container } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal';
import ReNumbers from '../ReNumbers/ReNumbers';

function PlanData(props: any) {
  const history = useHistory();
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const classes = useStyles();
  const data = props.planDetails;
  const [openNumberModal, setOpenNumberModal] = React.useState(false)

  // console.log('jjj', data)
  // React.useEffect(() => {

  // })'
  const handleClickNumber = () => {
    setOpenNumberModal(!openNumberModal)
  }
  return (
    <>
      {
        openNumberModal && (
          <div>
            <Backdrop handlePopupModal={handleClickNumber} />
            <Modal title="Add Number" handlePopupModal={handleClickNumber}>
              <div className=''>
                <ReNumbers />
              </div>
            </Modal>
          </div>
        )
      }
      <div
      // style={{display:'flex'}}
      >
        <Container className='planholder'>
          {/* new code  */}
          <div className={classes.twws}>
            <Card className={classes.onenewcard}
            // onClick={() => props.handleCardSelect(props.index)}
            >
              {
                data.planDetails?.map((item: any) => {
                  return (
                    <>
                      <Typography className={classes.freetypo}>{item.planName}</Typography>
                      <Typography className={classes.numberfree}>${item.planCost}</Typography>
                      <Typography className={classes.onetool}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do<br></br>eiusmod tempor incididunt ut labore et dolore magna aliqua.<br></br>Ut enim ad minim veniam
                      </Typography>
                    </>
                  )
                })
              }
              <Grid className='freefeatures' container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <div className={classes.div}>

                  {
                    props.selectedFeauters?.length > 0 && (
                      props.selectedFeauters.map((fea: any, i: any) => (
                        <Grid className='free1' item xs={12} sm={4} md={4} lg={4}>
                          <div className={classes.righticons}>
                            <span className={classes.oneicons}><CheckTwoToneIcon /></span>
                            <Typography className={classes.onetool}>{fea?.featureName}{fea?.name}</Typography>
                          </div>
                        </Grid>
                      ))
                    )
                  }
                </div>
              </Grid>
              {/* <Grid className='freefeatures' container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid className='free1' item xs={12} sm={4} md={4} lg={4}>
                <ul>
                <li</li>
                  // {
                  //   props.selectedIndex === props.index && props.selectedFeauters.map((featu: any) => (
                  //     <li>{featu.featureName}</li>
                  //   ))
                  // }
                </ul>
              </Grid>
            </Grid> */}
              {/* <div className={classes.div}>
                {
                  data?.PackageFeatures?.map((item: any) => (
                    <div className={classes.righticons}>
                      <span className={classes.oneicons}><CheckTwoToneIcon /></span>
                      <Typography className={classes.onetool}>{item.description}</Typography>
                    </div>
                  ))
                }
              </div> */}
              {/* <Button
              size='small'
              variant='outlined'
              className={classes.onebutton}
              onClick={props.handleSelectedPackage}
              style={{ textTransform: 'none' }}

            >
              Subscribe
            </Button> */}

            </Card>

          </div>
        </Container>
        {/* <Container>
          <div className='addonholder'>

            {
              data.features.length > 0 && (
                <>
                      <>
                        <h2>Calls</h2>
                        <span className='expandadd'>Expand your system as needed</span>
                        <Grid className='addongrid' container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          {data.features.map((feature: any) => (

                            <Grid className='addon' item xs={6} sm={6} md={4} lg={4} onClick={() => props.selectedFeaturesFun(feature)}>
                              <span className='addonheading'>{feature.featureName}</span>
                              <div className='chkicon'>
                                <Checkbox
                                  {...label} icon={<CircleOutlinedIcon />}
                                  checkedIcon={<CircleIcon />}
                          
                                />

                              </div>
                              <span className='addonsub'>Make and receive calls using any number, including conference calls with up to 5 people.</span>
                              <span className='addonprice'>$4.99</span>
                            </Grid>

                          ))}
                        </Grid>
                      </>
                </>
              )
            }
          </div>
        </Container> */}
      </div>
    </>
  )
}

export default PlanData
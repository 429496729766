import axiosInstance from "../../environment/axiosInstances";

class UserServices {
    signupDetails = async (token: any) => {
        try {
            const response = await axiosInstance.get(`/sign-up/?token=${token}`);
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message }
            }
        } catch (error: any) {
            if (error.request.status === 402) {
                window.location.href = "https://efone.ca/pricing"
                localStorage.removeItem("token")
                localStorage.removeItem("localNumbers")
              }
            return {
                success: false, message: error.message === "Network Error" ? "Network Error" : error.response.data.message,
            };
        }
    }
    PatchUserDetails = async (token: any, data: any) => {
        try {
            const response = await axiosInstance.patch(`/sign-up/user-profile?token=${token}`, data);
            if (response.data.data) {
                const data = response.data;
                return { success: true, data: data }
            } else {
                return { success: false, message: response.data.message }
            }
        } catch (error: any) {
            if (error.request.status === 402) {
                window.location.href = "https://efone.ca/pricing"
                localStorage.removeItem("token")
                localStorage.removeItem("localNumbers")
              }
            return { success: false, message: error.message === 'Network Error' ? 'Network Error' : error.response.data.message }
        }
    }


}
const userServices = new UserServices();
export default userServices;

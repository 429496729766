import React from "react";
import {
  Card,
  Tabs,
  Tab,
  Grid,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  CardContent,
  Divider,
  Button,
  IconButton,
} from "@material-ui/core";
import { BiRefresh } from 'react-icons/bi'
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import ReactSelect from "react-select";
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import { useStyles } from "../styles";
import DepartmentServices from '../../services';
import CircularProgress from '@mui/material/CircularProgress';
import CountryStates from "../CountryStates/CountryStates";
import { BiSearchAlt2 } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import * as popUpActionCreator from "../../../../store/Actions/popup-actionCreator";
import { connect } from "react-redux";
import { BsCheckLg } from 'react-icons/bs'
import logo from '../../../../assets/images/Lodingg.gif'
import Loader from '../../../../assets/images/ZZ5H.gif'
const VanityOptions = [
  { label: "Begins With", value: "1" },
  { label: "Contains", value: "2" },
  { label: "Ends With", value: "3" },
];
function Vanity(props: any) {
  const classes = useStyles();
  const [textvalue, settextValue] = React.useState<any>("");
  const history = useHistory();
  const [vanity, setVanity] = React.useState(false)
  const [vanityNumber, setVanityNumber] = React.useState<any>([]);
  const [mobile, setmobile] = React.useState("")
  const [Npa, setNpa] = React.useState<any>();
  const [mobileno, setMobileNo] = React.useState("")
  const [isError, setIsError] = React.useState(false);
  const [loading, setLoading] = React.useState(false)
  const [option, setOption] = React.useState('1')
  const [addData, setAddData] = React.useState<any>(
    { "phoneNumber": "" })
  const [numToggle, setNumToggle] = React.useState(false)
  const [index, setIndex] = React.useState(0)
  const [id, setId] = React.useState("")
  const [city, setCity] = React.useState({ label: '', value: "" })
  const [load, setLoad] = React.useState(false)
  // const handleSelectedNumbers = (data: any) => {
  //   DepartmentServices.postNumberList(data, localStorage.getItem("token")).then((res: any) => {
  //     if (res.success) {
  //       props.popUpAlert(res.data.message, "UPDATE_AGENT_SUCCESS", "success");
  //       history.push("/signup/cdn1/profile")
  //     } else {
  //       props.popUpAlert(res.message, "UPDATE_AGENT_SUCCESS", "error");
  //     }
  //   }).catch((err: any) => {
  //     // send error
  //     props.popUpAlert(err.data.message, "UPDATE_AGENT_SUCCESS", "error");
  //   })
  // }

  // const handleOption = (data: any) => {
  //   setOption(data.value)
  //   if (mobile) {
  //     if (mobile.length > 10) {
  //       setIsError(true);
  //     };
  //     if (data.value == "3") {
  //       const val = Npa + mobile
  //       setMobileNo(Npa.padEnd(3, '*') + mobile.padStart(7, '*'))
  //     }
  //     else if (data.value == '1') {
  //       const val = Npa + mobile
  //       setMobileNo(val.padEnd(10, '*'))
  //     }
  //     else if (data.value == "2") {
  //       setMobileNo(Npa.padEnd(4, '*') + mobile.padEnd(6, '*'))

  //     }
  //   }

  // }

  const handleOption = (data: any) => {
    setOption(data.value)
    if (mobile) {
      if (mobile.length > 10) {
        setIsError(true);
      };
      if (data.value == "3") {
        setMobileNo(mobile.padEnd(3, '_') + mobile.padStart(7, '_'))
      }
      else if (data.value == '1') {
        setMobileNo(mobile.padEnd(7, '_'))
      }
      else if (data.value == "2") {
        setMobileNo(mobile.padEnd(4, '_') + mobile.padEnd(3, '_'))

      }
    }

  }
  const handleContactNumber = (e: any) => {
    setmobile(e.target.value);
    if (e.target.value === "") {
      setVanityNumber([])
      setVanity(false)
    }
    if (e.target.value.length > 10) {
      setIsError(true);
    };
    // if (option == "3") {
    //   const val = Npa + e.target.value
    //   setMobileNo(Npa.padEnd(3, '*') + e.target.value.padStart(7, '*'))
    // }
    // else if (option == '1') {
    //   const val = Npa + e.target.value
    //   setMobileNo(val.padEnd(10, '*'))
    // }
    // else if (option == "2") {
    //   setMobileNo(Npa.padEnd(4, '*') + e.target.value.padEnd(6, '*'))

    // }
    if (option == "3") {
      const val = Npa + e.target.value
      setMobileNo(e.target.value.padStart(7, '_'))
    }
    else if (option == '1') {
      const val = e.target.value
      setMobileNo(val.padEnd(7, '_'))
    }
    else if (option == "2") {
      setMobileNo(''.padEnd(3, '_') + e.target.value.padEnd(4, '_'))

    }

  }

  const fetchLocalNumbers = () => {
    setVanity(true)
    setIndex(0)
    let myVanit: any = localStorage.getItem("vanity")
    const npafilter = props.cities.filter((itm: any) => itm.id === props.city.id)

    const filterObject: any = {
      stateValue: props.state?.value,
      // cityValue: props.city.id,
      npa: npafilter[0]?.npa,
      number: mobileno,
      searchType: "3",
      token: localStorage.getItem("token"),
    }
    setLoading(true)
    const vanity = {
      myNumber: mobile,
      myOption: option,
      mobileno: mobileno ? mobileno : JSON.parse(myVanit).mobileno
    }

    DepartmentServices.fetchVanityNumbers(filterObject)
      .then((res: any) => {
        if (res.success) {
          setVanityNumber(res.data);
          setIndex(0)
          localStorage.setItem("vanity", JSON.stringify(vanity))
          setLoading(false)
        } else {
          setLoading(false)

        }
      })
      .catch((err: any) => {
        setLoading(false)

      });
  }
  const handleChangeNumbers = () => {
    setLoading(true)
    var myIndex: any = vanityNumber.length > index + 5 ? index + 5 : 0
    setIndex(myIndex)
    setTimeout(() => {
      setLoading(false)
    }, 1000);

  }

  // console.log('props.city___',props.city)

  React.useEffect(() => {
    if (localStorage.getItem("localNumbers") !== null && localStorage.getItem("localNumbers") !== undefined) {
      let MyObject: any = localStorage.getItem('localNumbers')
      let numbers: any = JSON.parse(MyObject).area
      if (props?.city?.value !== numbers?.value) {
        setmobile("")
      }
    }
  }, [])
  React.useEffect(() => {
    if (localStorage.getItem("vanity") !== null && localStorage.getItem("vanity") !== undefined) {
      let myVanit: any = localStorage.getItem("vanity")
      let vanit: any = JSON.parse(myVanit).myNumber
      setmobile(vanit)
      const myOpt: any = JSON.parse(myVanit).myOption
      setOption(myOpt)
      const vanityNum = JSON.parse(myVanit).mobileno
      setMobileNo(vanityNum)
      if (vanit) {
        fetchLocalNumbers()
      }

    }
  }, [localStorage.getItem("vanity")])


  React.useEffect(() => {
    const npafilter = props.cities.filter((itm: any) => itm.value === props.city.value)
    setCity(props.city)
    setNpa(npafilter[0]?.npa)
  }, [props.city])
  const handleSelectedNumber = (num: any) => {
    setLoad(true)
    props.parentCallnumber(num)
    setTimeout(() => {
      setLoad(false)
    }, 1000);
  }
  return (
    <div>
      <TabPanel value="3" classes={{ root: classes.padding }}>
        <CountryStates countryOptions={props.countryOptions} country={props.country}
          onChangeStateType={props.onChangeStateType} states={props.states} state={props.state}
          onChangeCountryType={props.onChangeCountryType} />
        <Grid container >
          <Grid item md={12} className={classes.gridItem}>
            <Typography className={classes.text}>
              Area Code
            </Typography>
            <FormControl fullWidth size="small" variant="outlined">
              <ReactSelect
                fullWidth
                displayEmpty
                options={props.citiesOptions}
                value={props.city}
                placeholder="Area Code"
                inputProps={{ "aria-label": "Without label" }}
                onChange={props.onChangeCity}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container >
          <Grid item md={12} className={classes.gridItem}>
            <Typography className={classes.text}>
              Vanity Number
            </Typography>
            <FormControl>
              <div className={classes.vanitytext}>
                <ReactSelect
                  className={classes.dropdownselect}
                  displayEmpty
                  options={VanityOptions}
                  value={VanityOptions.filter((opt: any) => opt.value === option)}
                  onChange={handleOption}
                  inputProps={{ "aria-label": "Without label" }}
                // onChange={onChangeVanityNumber}
                />
                <input
                  className={classes.vanityText2}
                  placeholder="Enter Number"
                  type="tel"
                  maxLength={7}
                  value={mobile}
                  onChange={handleContactNumber}
                />
                {/* {mobile.length >= 3 ? */}
                <div>
                  <Button disabled={mobile.length >= 3 ? false : true} onClick={fetchLocalNumbers} style={{ cursor: "pointer", padding: '7px 8px', borderRadius: "10px", marginLeft: "40px", height: "37px", border: '1px solid #abc8ff', backgroundColor: mobile.length >= 3 ? "#4D72F8" : "#dedede", width: "50%" }}>
                    <BiSearchAlt2 />
                  </Button>
                </div>
                {/* : ""} */}
              </div>

            </FormControl>
          </Grid>

        </Grid>
        <Grid container style={{ width: "100%", marginLeft: "46px", margin: '0 auto' }}>
          <Grid item md={12} style={{ margin: '0 auto' }} >
            <div className={classes.bFlex} >
              <div>
                <Button size='small' className={classes.availableButton}>Available Numbers</Button>
              </div>
              <div>
                <Button className={classes.availableButton}
                  // style={{ marginLeft: "128px" }}
                  onClick={handleChangeNumbers}
                  startIcon={<BiRefresh />}>Refresh Numbers</Button>
              </div>
            </div>
            {!vanity &&
              < div style={{ margin: 'auto', alignItems: "center", color: "lightgray", marginBottom: "80px", textAlign: 'center', width: "100%", height: "80px", borderStyle: "dotted" }}>
                {/* <CircularProgress disableShrink /> */}
                <p style={{ textAlign: 'center', marginTop: "23px" }}>Search Result will find here </p>
              </div>}
            {loading ?
              <div style={{ margin: 'auto', alignItems: "center", color: "lightgray", marginBottom: "80px", textAlign: 'center', width: "100%", height: "80px", borderStyle: "dotted" }}>
                {/* <CircularProgress disableShrink /> */}
                <img src={logo} alt="loading..." style={{ height: "50px", marginTop: "7px" }} />
              </div> :

              vanity &&
              // <Card className={classes.availableCard} style={{ marginBottom: "25px" }}>
              <CardContent>

                <div className={classes.Numbers} style={{ marginBottom: "25px" }}>
                  {
                    vanityNumber?.length > 0 ? (

                      vanityNumber?.slice(props.index, props.index + 5).map((loc: any, i: any) => (
                        <div
                          style={{ width: "100%", display: "flex", marginBottom: "20px", alignItems: "center", justifyContent: "space-between", cursor: "ponter", border: "0.125rem solid #d5d5d5" }}
                          className={props.addData?.phoneNumber === loc?.number ? classes.numButtonOneActive : classes.numButtonOne}
                          onClick={() => handleSelectedNumber(loc?.number)}
                        >
                          <div style={{ alignContent: "center" }}>
                            <Button fullWidth
                              className={props.addData?.phoneNumber === loc?.number ? classes.numButtontwoActive : classes.numButtontwo}
                              style={{ marginLeft: "92px", width: "100%", pointerEvents: "none" }}
                              onClick={() => handleSelectedNumber(loc?.number)}>
                              <CallRoundedIcon className={props.addData?.phoneNumber === loc?.number ? classes.callIconActive : classes.callIcon} />&nbsp;&nbsp;  {loc?.number}
                            </Button>
                          </div>
                          <div>
                            {load && props.addData?.phoneNumber === loc?.number ? <img src={Loader} alt="loading..." style={{ height: "20px", marginTop: "7px", color: "lightgray", marginRight: "18px" }} /> : props.addData?.phoneNumber === loc?.number && <BsCheckLg style={{ marginRight: "18px", fontWeight: "600px" }} />}
                          </div>
                        </div>

                        // <Button fullWidth startIcon={<CallRoundedIcon />} variant="outlined"
                        //   className={props.addData?.phoneNumber === loc?.number ? classes.numButtonOneActive : classes.numButtonOne}
                        //   onClick={() => props.parentCallnumber(loc?.number)}> {loc?.number}</Button>
                      ))
                    ) :
                      !loading && vanity &&
                      <Card className={classes.availableCard} style={{ marginBottom: "25px" }}>
                        <div className={classes.errMsg} style={{ borderStyle: "dotted" }}>
                          <p style={{ textAlign: 'center' }}>Sorry, we can't find any numbers that contain {mobile} </p>
                        </div>
                      </Card>


                  }
                </div>



              </CardContent>
              // </Card>
            }
          </Grid>

        </Grid>
      </TabPanel>

    </div >
  );
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    popUpAlert: (err: any, instance: any, type: any) => dispatch(popUpActionCreator.initglobalPopup(err, instance, type))
  }
}

export default connect(null, mapDispatchToProps)(Vanity)


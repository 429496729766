import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import localStorageContent from '../../services/localStorage'

const ProtectedRoutes = (props: any) => {
    const { Component, routes } = props;
    const localAuthData: any = localStorageContent.getLocalUserData()
    // if (!) {
    //     // initGlobalError('Please sign in', 'info')
    // }

    return (
        <Route {...props} render={
            (props) => {
                return <Component {...props} routes={routes} />
            }
        }
        />
    )
}

const mapStateToProps = (state: any) => {
    return {
        authData: state.authState
    }
}

const mapsDispatchToProps = (dispatch: any) => {
    return {
        // initGlobalError: (err, type) => dispatch(globalErrorHandler.initError(err, type))
    }
}

export default connect(mapStateToProps, mapsDispatchToProps)(ProtectedRoutes)

import React from 'react'
import { connect } from 'react-redux'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import * as popupactionCreator from '../../store/Actions/popup-actionCreator'
import './popupalert.css'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 'auto',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
    }),
);

function PopupAlert(props: any) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const { error, message, popuptype } = props.errorState
    const handleClose = (event: any, reason: any) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        props.clearPopup()
    };

    function Alert(props: any) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }


    React.useEffect(() => {
        let errorTime: any;
        if(error){
            setOpen(true)
            errorTime = setTimeout(() => {
                setOpen(false)
                props.clearPopup()
            }, 3000)
        }

        return () => clearTimeout(errorTime);
        // eslint-disable-next-line 
    }, [error])

    return (
        <div className="alert_popup_container">
            <div className={classes.root}>
                <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} style={{ width: '100%', height: 'auto', position: 'fixed', top: '15%', right: '0'}}>
                    <Alert onClose={handleClose} severity={popuptype !== '' ? popuptype : 'error'}>
                        {message}
                    </Alert>
                </Snackbar>
            </div>
        </div>
    )
}

const mapStatToProps = (state: any) => {
    return {
        errorState: state.errorState
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        clearPopup: () => dispatch(popupactionCreator.clearGlobalPopup())
    }
}

export default connect(mapStatToProps, mapDispatchToProps)(PopupAlert)

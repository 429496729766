



class localStorageData {
    setLocalUserData(userData: any) {
        localStorage.setItem('token', userData)


    }

    getLocalUserData() {
        const localUser: any = localStorage.getItem('token')
        return localUser;

    }


}

const localStorageContent = new localStorageData()

export default localStorageContent;
import { Typography } from '@material-ui/core';
import React from 'react'
import {useStyles} from './styles';

function Thankyou() {
    const classes = useStyles()
    React.useEffect(() => {
        // localStorage.removeItem("verifiedId")
    }, [])

    return (
        <div className={classes.dflex}>
            <div className={classes.maincontainer}>
            <Typography className={classes.title1}>Thank You</Typography>
                <Typography className={classes.title1}>You have Succussfully Registered</Typography>
                <Typography className={classes.title2}>
                    <a href='/v1/signup/register' style={{fontSize:'22px'}}>Click Here to Login</a>
                </Typography>
            </div>
        </div>

    )
}

export default Thankyou
import React from 'react'
import { Card, Tab, Tabs } from '@mui/material'
import { Button, Container, Grid, Radio, TextField, Tooltip, Typography } from "@material-ui/core"
import { useStyles } from './style'
import { TabContext, TabPanel } from '@material-ui/lab';
import ReactSelect from 'react-select'
import StepTwo from '../Steps/StepTwo'
import ExistingNumbers from '../ExistingNumbers/ExistingNumbers';
import SignupServices from '../SignupServices';
import ReNumbers from '../ReNumbers/ReNumbers';
import { useHistory } from 'react-router-dom';
import './myst.css'
import ReactTelephoneInput from 'react-telephone-input'
import logo from '../../../assets/images/logo.png'
import Flags from '../../../assets/flags.png'
const countryOptions = [
    { label: "canada", value: 'CAN' },
    { label: "united states", value: 'USA' },
];
import { BiEdit } from 'react-icons/bi';
import { signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth'
import { auth } from '../firebase/firebase';
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal';
import otp from '../../../assets/images/otp.png'
import PhoneNumberOtp from './PhoneNumberOtp/PhoneNumberOtp';
import v5axiosInstaces from '../../../environment/v5axiosInstances'
import * as popUpActionCreator from "../../../store/Actions/popup-actionCreator";
import { connect } from "react-redux";


function Successfull(props: any) {
    const history = useHistory();
    const [value, setValue] = React.useState("2")

    const classes = useStyles();
    const [city, setCity] = React.useState<any>([]);
    const [country, setCountry] = React.useState<any>({ label: "Canada", value: 'CAN' });
    const [cities, setCities] = React.useState([]);
 
    



    return (
        <>
        

            <div className={classes.logo}>
                <p>
                    You have successfully Registered!
                </p>
            </div>
        </>
    )
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        popUpAlert: (err: any, instance: any, type: any) => dispatch(popUpActionCreator.initglobalPopup(err, instance, type))
    }
}
export default connect(null, mapDispatchToProps)(Successfull)


import axiosInstance from "../../environment/axiosInstances";

class localNumberServices {
    fetchStates = async (value: any) => {
        try {
            const response = await axiosInstance.get("/teli/states?country=" + value);
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    fetchCities = async (id: any) => {
        try {
            const response = await axiosInstance.get(
                "/teli/cities?state=" + id
            );
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    fetchAreas = async (id: any) => {
        try {
            const response = await axiosInstance.get(
                "/teli/area-codes?state=" + id
            );
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };

    fetchLocalNumbers = async (data: any) => {
        const SearchType = data.searchType

        try {
            var response= await axiosInstance.get(
                        `/teli/local-numbers?state=${data.stateValue}&ratecenter=${data.cityValue}&npa=${data.npa}&searchType=${data.searchType}&token=${data.token}`
                    );
            // if (SearchType === "1") {
            //     response = await axiosInstance.get(
            //         `/teli/local-numbers?state=${data.stateValue}&ratecenter=${data.cityValue}&npa=${data.npa}&searchType=${data.searchType}&token=${data.token}`
            //     );
            // }
            // else {
            //     response = await axiosInstance.get(
            //         `/teli/local-numbers?state=${data.stateValue}&npa=${data.npa}&searchType=${data.searchType}&token=${data.token}`
            //     );
            // }
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };

    postNumberList = async (data: any, token: any) => {
        try {
            const response = await axiosInstance.patch(`/sign-up/phonenumber?token=${token}`, data);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            if (error.request.status === 402) {
                window.location.href = "https://efone.ca/pricing"
                localStorage.removeItem("token")
                localStorage.removeItem("localNumbers")
            }
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
                status: error.request.status
            };
        }
    };
    fetchVanityNumbers = async (data: any) => {
        try {
            const response = await axiosInstance.get(
                `/teli/local-numbers?state=${data.stateValue}&npa=${data.npa}&search=${data.number}&searchType=${data.searchType}&token=${data.token}`
            );
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
}
const localServices = new localNumberServices();
export default localServices;

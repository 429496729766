import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    paper: {
        padding: '24px',
        width: 900,
        margin: '80px auto',
        borderRadius: 10,
        // background: '#0a55df'
    },
    nextIconActive: {
        border: "5px solid #4D72F8",
        float: 'right',
        margin: 10,
        '&:hover': {
            // background: '#ECECEC',
            border: "5px solid #4D72F8",
        },
        "&:active": {
            backgroundColor: "blue",
        },
    },
    activeButton: {
        textTransform: 'capitalize',
        backgroundColor: '#ECECEC',
        fontFamily: 'work sans',
        fontSize: '16px',
        background: '#ECECEC',
        marginTop: 20,
        color: 'white',
        '&:hover': {
            background: '#ECECEC',
            color: 'white',
        },
        "&:active": {
            backgroundColor: "blue",
        },
    },
    dFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 5
    },
    dFlexOne: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 20
    },
    dFlexOneTwo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10
    },
    divider: {
        background: '1 px solid #cecece',
        marginTop: 8,
    },
    dividerOne: {
        background: '1 px solid #cecece',
        marginTop: 20,
    },
    debit: {
        marginTop: 20,
    },
    creditCard: {
        width: '100%',
        boxShadow: ' 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
        outline: 'none'
    },
    text: {
        marginTop: 20,
        fontSize: 12,
    },
    smallText: {
        marginTop: 20,
        fontSize: 12,
        color: 'red'
    },
    conditions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 15
    },
    terms: {
        fontSize: 12,
    },
    size: {
        fontSize: 14
    },
    mTop: {
        marginTop: 6
    },
    mTopOne: {
        fontWeight: 600,
        fontFamily: 'work sans',
    },
    sizeOne: {
        fontSize: 14,
        color: 'red',
    },
    sizeThree: {
        fontSize: 14,
        color: 'green',
    },
    coupon: {
        marginTop: 10
    },
    paperOne: {
        textAlign: 'center'
    },
    email: {
        color: '#fff',
        fontWeight: 600
    },
    textfield: {
        width: '80%',
        marginTop: 6,
        background: '#fff',
        //overflow: ''
    },
    confirmemail: {
        marginTop: '20px',
        color: '#fff',
        fontWeight: 600

    },
    dbg: {
        background: '#fff',
        marginTop: 20,
        padding: 8,
        width: '80%',
    },
    divFloat: {
        float: 'right',
        marginBottom: 20
    },
    avatarBg: {
        border: '4px solid #abc8ff',
        background: '#0a55df'
    },
    // icon: {
    //     borderRadius: 50,
    //     color: '#4D72F8',
    //     fontWeight: 600
    // },
    textColor: {
        color: '#0a55df',
        marginTop: 10
    }
}))
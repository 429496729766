import { Button, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { classNames } from 'react-select/src/utils';
import axiosInstance from '../../../../environment/axiosInstances'
import * as popUpActionCreator from "../../../../store/Actions/popup-actionCreator";
import v5axiosInstance from '../../../../environment/v5axiosInstances'
import { useStyles } from '../style'

function VerifyOtp(props: any) {
    const classes = useStyles()
    const history = useHistory()
    const [isValidotp, setIsValidotp] = React.useState(false)
    const userId: any = props.userId;
    const [veriyOtps, setVerifyOtp] = React.useState<any>({
        // email: props.email,
        userId: props.userId,
        otp: ''
    })

    // console.log('userId', userId);
    


    const validateOTPPost = () => {
        // if (veriyOtps.otp !== "" && veriyOtps.otp?.length > 4) {
        v5axiosInstance.post('/auth/verify-email-otp', veriyOtps).then((res: any) => {
            if (res.status === 200) {
                history.push(`/v1/signup/reservenumber`)
                setIsValidotp(false)
               
                props.popUpAlert(res.data.message, "UPDATE_AGENT_SUCCESS", "success");
            } else {
                props.popUpAlert(res.data.message, "UPDATE_AGENT_SUCCESS", "success");
            }
        }).catch((err: any) => {
            props.popUpAlert(err.response.data.message, "UPDATE_AGENT_SUCCESS", "error");
        })

        // }
        // else{
        //     props.popUpAlert("Please enter otp", "UPDATE_AGENT_SUCCESS", "error");
        // }
    }


    const onChangeOTP = (e: any) => {
        setVerifyOtp((prev: any) => {
            return {
                ...prev,
                otp: e.target?.value
            }
        })
    }
    return (
        <div className='www'>
            <TextField
                style={{ width: '100%', background: '#4D72F825' }}
                size='small'
                variant='outlined'
                placeholder='Enter otp'
                value={veriyOtps.otp}
                name="otp"
                onChange={onChangeOTP}
                error={isValidotp}

            />
            <Button size="medium"
                variant='outlined'
                className={classes.button}
                onClick={validateOTPPost}>Verify & Proceed</Button>
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        popUpAlert: (err: any, instance: any, type: any) => dispatch(popUpActionCreator.initglobalPopup(err, instance, type))
    }
}
export default connect(null, mapDispatchToProps)(VerifyOtp)

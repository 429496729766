import axiosInstance from '../../environment/axiosInstances';
import { toast } from 'react-toastify'

class transferNumberServices {
     fetchPortNumber = async (number: any) => {
        try {
            const response = await axiosInstance.get("/iristel/port-ability?number=" + number);
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    spatchPortNumber= async (data:any,token:any) => {
        try {
        const response = await axiosInstance.patch(`/sign-up/port?token=${token}`,data);
        if(response.data.data) {
            const data = response.data.data;
            return { success: true, data: data };
        }else{
            return { success: false, message: response.data.message }
        }
        } catch (error:any) {
            if (error.request.status === 402) {
                window.location.href = "https://efone.ca/pricing"
                localStorage.removeItem("token")
                localStorage.removeItem("localNumbers")
              }
        return {
            uccess: false, message: error.message === "Network Error" ? "Network Error" : error.response.data.message,
        };
        }
    }
    getCarriers = async (token: any) => {
        try {
            const response = await axiosInstance.get(`/sign-up/port/carriers?token=${token}`);
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            if (error.request.status === 402) {
                window.location.href = "https://efone.ca/pricing"
                localStorage.removeItem("token")
                localStorage.removeItem("localNumbers")
              }
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    SubmitNumber= async (token:any,data:any) => {
        try {
            const response = await axiosInstance.patch(`/sign-up/port?token=${token}`,data);
            if(response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            }else{
                toast.error( response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: false, message: response.data.message }
            }
            } catch (error:any) {
                if (error.request.status === 402) {
                    window.location.href = "https://efone.ca/pricing"
                    localStorage.removeItem("token")
                    localStorage.removeItem("localNumbers")
                  }
            return {
                uccess: false, message: error.message === "Network Error" ? "Network Error" : error.response.data.message,
            };
        }
    }

    SubmitBillingDetails= async (token:any,data:any) => {
        try {
            const response = await axiosInstance.patch(`/sign-up/port/account-info?token=${token}`,data);
            if(response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            }else{
                toast.error( response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: false, message: response.data.message }
            }
            } catch (error:any) {
                if (error.request.status === 402) {
                    window.location.href = "https://efone.ca/pricing"
                    localStorage.removeItem("token")
                    localStorage.removeItem("localNumbers")
                  }
            return {
                uccess: false, message: error.message === "Network Error" ? "Network Error" : error.response.data.message,
            };
        }
    }

    SubmitAuthorisationDetails= async (token:any,data:any) => {
        try {
            const response = await axiosInstance.patch(`/sign-up/port/authorization?token=${token}`,data);
            if(response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            }else{
                toast.error( response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: false, message: response.data.message }
            }
            } catch (error:any) {
                if (error.request.status === 402) {
                    window.location.href = "https://efone.ca/pricing"
                    localStorage.removeItem("token")
                    localStorage.removeItem("localNumbers")
                  }
            return {
                uccess: false, message: error.message === "Network Error" ? "Network Error" : error.response.data.message,
            };
        }
    }

}
const transferServices = new transferNumberServices()
export default transferServices;
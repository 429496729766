import axios from 'axios'
import React from 'react'
// const { NODE_ENV } = process.env;
import localStorageContent from '../services/localStorage';
import jwt_decode from "jwt-decode";
import CryptoJS from 'crypto-js';

const getToken = () => {
    var waitTime = 30 * 60 * 1000; // = 30min.
    setTimeout(function () {
        localStorage.clear()
    }, waitTime);
    const localData = localStorageContent.getLocalUserData()
    if (localData === undefined) {
        window.location.href = "https://efone.ca/pricing"
    } else {
        return localData
    }
}



const URL = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

URL.interceptors.request.use(
    async config => {
        config.headers = {
            'Authorization': `Bearer ${getToken()}`
        }
        return config
    },
    error => {
        Promise.reject(error)
    }
)

export default URL

import * as React from 'react';
import { BsCheckCircleFill } from 'react-icons/bs'
import './style.css'
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import { Typography, Card, TextField, Grid, Tabs, Tab, Button, IconButton } from '@material-ui/core'
import { useStyles } from './style'
import { useHistory } from 'react-router-dom'
import transferNumberServices from './services'
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import axiosInstance from '../../environment/axiosInstances'
import FormControlLabel from '@mui/material/FormControlLabel';
function Authorisation(props: any) {
    const classes = useStyles();
    const history = useHistory();
    const [temporaryNumber, setTemporaryNumber] = React.useState<any>("")
    const [signupDetails, setSignUpDetails] = React.useState<any>({})

    React.useEffect(() => {
        axiosInstance.get(`/sign-up?token=${localStorage.getItem("token")}`).then((res: any) => {
            if (res.data.data) {
                setTemporaryNumber(res?.data?.data[0].temporaryNumber)
                var data = res?.data?.data[0]
                setSignUpDetails(data)
            } else {
            }
        }).catch((err: any) => {
            if (err.request.status === 402) {
                window.location.href = "https://efone.ca/pricing"
                localStorage.clear()
            }
        })
    }, [])
    var newNumber = signupDetails.numberType === 3 ? temporaryNumber : ""
    if (newNumber) {
        var cleaned = ('' + newNumber).replace(/\D/g, '');
        var match: any = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        newNumber = ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    const displayDates = (dateString: any) => {
        const dateNow = new Date(dateString);
        const year = dateNow.getFullYear();
        const monthWithOffset = dateNow.getMonth() + 1;
        const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
        const date = dateNow.getDate().toString().length < 2 ? `0${dateNow.getDate()}` : dateNow.getDate();
        const materialDateInput = `${year}-${month}-${date}`;
        return materialDateInput
    }

    return (
        <>
            <div>
                <div style={{ marginTop: 50, width: '100%', margin: '0px auto' }}>
                    <Grid container spacing={4} style={{ marginTop: 10 }}>
                        <Grid item md={12}>
                            <Typography className={classes.textsize}>
                                You can not port your cell phone number to eFone and continue to use it on
                                your cell phone for calling, testing and data services. In order to continue
                                using calling, testing and data services on your current cell phone, you will
                                need to add another phone number with your current carrier before porting the
                                original number to eFone. Type a message
                            </Typography>
                            <FormGroup style={{ marginTop: 25 }} className={classes.label}>
                                <FormControlLabel style={{ fontSize: 12 }}
                                    control={<Checkbox
                                        defaultChecked
                                        size='small'
                                        checked={props.authorisationData.confirmPort}
                                        name="confirmPort"
                                        onChange={props.onChangeCheckbox}
                                        style={{ fontSize: 12 }}
                                        className={classes.label} />}
                                    className={classes.label}
                                    label="You can not port your cell phone number to eFone and continue to use it 
                                on your cell phone for calling, texting and data services. In order to continue using calling, texting"
                                />
                            </FormGroup>
                            {temporaryNumber &&
                                <div className={classes.dFlex}>
                                    <Typography>Temporary Number</Typography>
                                    <Typography>{newNumber}</Typography>
                                </div>}
                            <div>
                                <Typography className={classes.headerTwo}>Desired Port Date</Typography>
                                <TextField variant="outlined"
                                    name="desiredPortDate"
                                    onChange={props.handleChangeDate}
                                    value={displayDates(props.authorisationData.desiredPortDate)}
                                    type="date" fullWidth size='small' className={classes.selectField}
                                    placeholder='19/02/2022'
                                />
                            </div>
                            <div>
                                <Typography
                                    className={classes.headerTwo}>Authorised Signature </Typography>
                                <TextField variant="outlined"
                                    name="autorizedSignature"
                                    onChange={props.handleChangeOption}
                                    value={props.authorisationData.autorizedSignature}
                                    fullWidth size='small' className={classes.selectField}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    )
}

export default Authorisation;

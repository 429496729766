import React from "react";
import {
  Card,
  Grid,
  Typography,
  CardContent,
  Divider,
  Button,
  IconButton,
} from "@material-ui/core";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useStyles } from "./styles";
import './style.css'
import { MdOutlineKeyboardBackspace } from 'react-icons/md'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useHistory } from 'react-router-dom'
import TabContext from "@material-ui/lab/TabContext";
import { MdRadioButtonChecked } from 'react-icons/md'
import TextScroller from '../../Layout/Textscroll'
import { BsCheckCircleFill } from 'react-icons/bs'
import ReactSelect from "react-select";
import PhoneIcon from "@material-ui/icons/Phone";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import Default from "./Default/Default";
import Repeating from "./Repeating/Repeating";
import Vanity from "./Vanity/Vaniity";
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import DepartmentServices from '../services'
import * as popUpActionCreator from "../../../store/Actions/popup-actionCreator";
import { ImRadioUnchecked } from 'react-icons/im'
import { connect } from "react-redux";
import Service from '../../UserProfile/Service'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl'
// import Stepper from '@material-ui/core/Stepper';
// import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const countryOptions = [
  { label: "Canada", value: 1 },
  { label: "United States", value: 2 },
];

function AddEfoneNumber(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState("1");
  const [cityChange, setCityChange] = React.useState(false)
  const [areaChange, setAreaChange] = React.useState(false)
  const [city, setCity] = React.useState<any>(
    // { label: 'SAINT THOMAS(226)', value: 'SAINT THOMAS' }
    );
  const [signupDetails, setSignUpDetails] = React.useState<any>([])
  const [country, setCountry] = React.useState<any>({ label: "canada", value: 1 });
  const [coutry, setcoutry] = React.useState({ label: "canada", value: 1 })
  const [cities, setCities] = React.useState([]);
  const [localstoragedata, setLocalstoragedata] = React.useState<any>({})
  const [citiesOptions, setCityOptions] = React.useState([]);
  const [areaOptions, setAreaOptions] = React.useState([]);
  const [allAreas, setAllAreas] = React.useState([]);
  const [area, setArea] = React.useState<any>(
    // { id: "SAINT THOMAS", value: "SAINT THOMAS(226)", npa: "226" }
    );
  const [states, setStates] = React.useState([]);
  const [goSteps, setGoSteps] = React.useState(1);
  const [state, setState] = React.useState<any>({ label: 'Ontario', value: 'ON' });
  const [localNUmbers, setLocalNUmbers] = React.useState<any>([]);
  const [repeatNumber, setRepaetNumber] = React.useState<any>([]);
  // const [vanityNumber, setVanityNumber] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false)
  const [index, setIndex] = React.useState(0)
  const [Repaetindex, setRepeatIndex] = React.useState(0)
  const [addData, setAddData] = React.useState<any>(
    { "phoneNumber": "" })

  const handleTabs = (e: any, newValue: any) => {
    setValue(newValue);
  };
  const handlecall = (call: any) => {
    setAddData((prev: any) => {
      return {
        ...addData,
        phoneNumber: call,
      };
    })
  }

  React.useEffect(() => {
    var myCountry = country
    var myStates = state
    var myCity = city
    var myArea = area

    var retrievedObject: any = localStorage.getItem('localNumbers')
    var myPermissions: any = JSON.parse(retrievedObject)
    if (localStorage.getItem("localNumbers") !== null && localStorage.getItem("localNumbers") !== undefined) {
      if (Object.keys(myPermissions).length) {
        myCountry = myPermissions.country
        myStates = myPermissions.state
        myCity = myPermissions.cityval
        myArea = myPermissions.area

        setCountry(myCountry)
        setState(myStates)
        setCity(myCity)
        setArea(myArea)

      }
    }
    setLoading(true)
    // console.log("localStorage : " + JSON.stringify(localStorage),)
    DepartmentServices.fetchStates(myCountry.value)
      .then((res: any) => {
        if (res.success) {
          const mappedStateData: any = res.data.map((item: any) => {
            return { label: item.value, value: item.id };
          });
          setStates(mappedStateData);
          if (value === "2" || value === "3") {
            DepartmentServices.fetchAreas(myStates?.value)
              .then((res: any) => {
                if (res.success) {
                  setLoading(false)
                  const mapCitiesDropdown = res.data.map((dt: any) => {
                    return { label: dt.npa, value: dt.value, id: dt.id };
                  });
                  setAllAreas(res.data);
                  setAreaOptions(mapCitiesDropdown);
                  // setArea(mapCitiesDropdown[0])
                  const selectedCity: any = res.data.find((cty: any) => cty.value === myArea.value);
                  if (myCountry && myStates && selectedCity) {
                    const filterObject: any = {
                      stateValue: state?.value,
                      cityValue: selectedCity.id,
                      npa: selectedCity?.npa,
                      searchType: value,
                      token: localStorage.getItem("token"),
                      // stateValue: myStates?.value,
                      // searchType: value,
                      // // cityValue: selectedCity.id,
                      // npa: selectedCity?.npa,
                      // token: localStorage.getItem("token"),
                    };
                    setLoading(true)
                    DepartmentServices.fetchLocalNumbers(filterObject)
                      .then((res: any) => {

                        if (res.success) {
                          setRepaetNumber(res.data);
                          setLoading(false)
                        } else {
                          setLoading(false)
                        }
                      })
                      .catch((err: any) => { setLoading(false) });
                  }

                } else {
                  setLoading(false)
                }
              })
              .catch((err: any) => { setLoading(false) });
          }
          else if(value==="1"){
            DepartmentServices.fetchCities(myStates?.value)
            .then((cityRes: any) => {
              if (cityRes.success) {
                setLoading(false)
                const mapCitiesDropdown = cityRes.data.map((dt: any) => {
                  return { label: dt.value, value: dt.id };
                });
                setCities(cityRes.data);
                setCityOptions(mapCitiesDropdown);
                // setCity({})
                const selectedCity: any = cityRes.data.find((cty: any) => cty.value === myCity.label);
                if (country && myStates && selectedCity) {
                  const filterObject: any = {
                    stateValue: myStates?.value,
                    cityValue: selectedCity.id,
                    npa: selectedCity?.npa,
                    searchType: value,
                    token: localStorage.getItem("token"),
                  };
                  setLoading(true)
                  DepartmentServices.fetchLocalNumbers(filterObject)
                    .then((res: any) => {
                      if (res.success) {
                        setLocalNUmbers(res.data);
                        setLoading(false)
                      } else {
                        setLoading(false)
                      }
                    })
                    .catch((err: any) => { setLoading(false) });
                }
              }
              else {
                setLoading(false)
              }
            })
            .catch((err: any) => { setLoading(false) });

        }
          }
         
      })
      .catch((err: any) => {
        // send error
        setLoading(false)
      });
  }, [value])

  // for fetching states based on country
  const onChangeCountryType = (data: any) => {
    setCountry(data);
    setLoading(true)
    DepartmentServices.fetchStates(data?.value)
      .then((res: any) => {
        if (res.success) {
          setLoading(false)
          const mappedStateData: any = res.data.map((item: any) => {
            return { label: item.value, value: item.id };
          });
          setStates(mappedStateData);
          if (Object.keys(localstoragedata).length === 0) {
            setState(mappedStateData[0])
            if (value === "1") {
              onChangeStateType(mappedStateData[0])
            }
            else if (value === "2" || value === "3") {
              onChangeAreaCodetype(mappedStateData[0])
            }

          }


        } else {
          setLoading(false)
        }
      })
      .catch((err: any) => {
        // send error
        setLoading(false)
      });
  };


  //for fetching local numbers based on Area code
  const onChangeAreaCodetype = (data: any) => {
    // setCities([]);
    // setCity([])
    setLoading(true)
    setState(data);
    DepartmentServices.fetchAreas(data.value)
      .then((res: any) => {
        if (res.success) {
          setLoading(false)
          const mapCitiesDropdown = res.data.map((dt: any) => {
            return { label: dt.npa, value: dt.value, id: dt.id };
          });
          setAllAreas(res.data);
          setAreaOptions(mapCitiesDropdown)

          const newArea = mapCitiesDropdown[0] ? mapCitiesDropdown[0] : {}

          // setArea(newArea);
          // const selectedCity: any = res.data.find((cty: any) => cty.value === newArea.value);

          // if (country && data && selectedCity) {
          //   const filterObject: any = {
          //     stateValue: data?.value,
          //     // cityValue: selectedCity.id,
          //     npa: selectedCity?.npa,
          //     searchType: value,
          //     token: localStorage.getItem("token"),
          //   };
          //   setLoading(true)
          //   setRepeatIndex(0)
          //   DepartmentServices.fetchLocalNumbers(filterObject)
          //     .then((res: any) => {

          //       if (res.success) {
          //         setRepaetNumber(res.data);
          //         setLoading(false)
          //       } else {
          //         setLoading(false)
          //       }
          //     })
          //     .catch((err: any) => { setLoading(false) });
          // } else {
          //   setLocalNUmbers([]);

          // }
        } else {
          setLoading(false)
        }
      })
      .catch((err: any) => { setLoading(false) });
  };
  // for fetching cities based on state
  const onChangeStateType = (data: any) => {
    setCity({});
    setArea({})
    setLocalNUmbers([])
    setRepaetNumber([])
    setState(data);
    setLoading(true)
    DepartmentServices.fetchCities(data?.value)
      .then((res: any) => {
        if (res.success) {
          setLoading(false)
          const mapCitiesDropdown = res.data.map((dt: any) => {
            return { label: dt.value, value: dt.id };
          });
          setCities(res.data);
          setCityOptions(mapCitiesDropdown);

          const Newcity = mapCitiesDropdown[0] ? mapCitiesDropdown[0] : {}
          if (Object.keys(localstoragedata).length === 0) {
            // setCity(Newcity)
            // const selectedCity: any = res.data.find((cty: any) => cty.value === Newcity.label);
            // if (country && data && selectedCity) {
            //   const filterObject: any = {
            //     stateValue: data?.value,
            //     cityValue: selectedCity.id,
            //     npa: selectedCity?.npa,
            //     searchType: value,
            //     token: localStorage.getItem("token"),
            //   };
            //   setLoading(true)
            //   setIndex(0)
            //   DepartmentServices.fetchLocalNumbers(filterObject)
            //     .then((res: any) => {
            //       if (res.success) {
            //         setLocalNUmbers(res.data);
            //         setLoading(false)
            //       } else {
            //         setLoading(false)
            //       }
            //     })
            //     .catch((err: any) => { setLoading(false) });
            // }
            // else {
            //   setLocalNUmbers([]);
            //   setLoading(false)
            // }
          }



          // console.log(res.data);
        } else {
          setLoading(false)
        }
      })
      .catch((err: any) => { setLoading(false) });
  };


  const onChangeCity = (data: any) => {
    setCity(data);
    setIndex(0)
    const selectedCity: any = cities.find((cty: any) => cty.value === data.label);
    if (country && state && selectedCity) {
      const filterObject: any = {
        stateValue: state?.value,
        cityValue: selectedCity.id,
        npa: selectedCity?.npa,
        searchType: value,
        token: localStorage.getItem("token"),
      };
      setLoading(true)
      DepartmentServices.fetchLocalNumbers(filterObject)
        .then((res: any) => {
          if (res.success) {
            setLocalNUmbers(res.data);
            setLoading(false)
          } else {
            setLoading(false)
          }
        })
        .catch((err: any) => { setLoading(false) });
    }
    // console.log("selected city", selectedCity);
  };


  const handleCallback = (childCall: any) => {
    setLoading(true)
    setLocalNUmbers(childCall)
    setLoading(false)
  }

  const onchangingcity = (data: any) => {
    setRepeatIndex(0)
    setArea(data);
  
    const selectedCity: any = allAreas.find((cty: any) => cty.value === data.value);

    if (country && state && selectedCity) {
      const filterObject: any = {
        stateValue: state?.value,
        cityValue: selectedCity.id,
        npa: selectedCity?.npa,
        searchType: value,
        token: localStorage.getItem("token"),
        // stateValue: state?.value,
        // // cityValue: selectedCity.id,
        // searchType: value,
        // npa: selectedCity?.npa,
        // token: localStorage.getItem("token"),
      };
      setLoading(true)
      DepartmentServices.fetchLocalNumbers(filterObject)
        .then((res: any) => {

          if (res.success) {

            setRepaetNumber(res.data)

            setLoading(false)
          } else {
            setLoading(false)
          }
        })
        .catch((err: any) => { setLoading(false) });
    }
    // console.log("selected city", selectedCity);
  };

  const handleSelectedNumbers = (data: any) => {
    const localNumbers: any = {
      country: country,
      state: state,
      cityval: city,
      area: area,
      localnumber: localNUmbers,
      selectNumber: data.phoneNumber,
      value: value
    }
    DepartmentServices.postNumberList(data, localStorage.getItem("token")).then((res: any) => {
      if (res.success) {
        props.popUpAlert(res.data.message, "UPDATE_AGENT_SUCCESS", "success");
        localStorage.setItem("localNumbers", JSON.stringify(localNumbers))
        history.push({ pathname: '/signup/cdn1/profile', state: value })
        localStorage.setItem("index", JSON.stringify(index))
        localStorage.setItem("RepeatingIndex", JSON.stringify(Repaetindex))

      } else {
        props.popUpAlert(res.message, "UPDATE_AGENT_SUCCESS", "error");
      }
    }).catch((err: any) => {
      // send error

      props.popUpAlert(err.response.message, "UPDATE_AGENT_SUCCESS", "error");
    })

  }
  React.useEffect(() => {
    if (localStorage.getItem("localNumbers") !== null && localStorage.getItem("localNumbers") !== undefined) {
      let MyObject: any = localStorage.getItem('localNumbers')
  
      let Valu: any = JSON.parse(MyObject).value
      if (Valu) {
        setValue(Valu)
      }
    }
  }, [])
  React.useEffect(() => {
    if (localStorage.getItem("index") !== null && localStorage.getItem("index") !== undefined) {
      const indexVal: any = localStorage.getItem("index")
      setIndex(parseInt(indexVal))
    }
    else {
      setIndex(0)
    }
  }, [localStorage.getItem("index")])
  React.useEffect(() => {
    if (localStorage.getItem("RepeatingIndex") !== null && localStorage.getItem("RepeatingIndex") !== undefined) {
      const indexRVal: any = localStorage.getItem("RepeatingIndex")
      setRepeatIndex(parseInt(indexRVal))
    }
    else {
      setIndex(0)
    }
  }, [localStorage.getItem("RepeatingIndex")])


  // React.useEffect(() => {
  //   if (localstoragedata) {
  //     const localData = localstoragedata
  //     setCountry(localData?.country);
  //     setState(localData?.state);
  //     setCity(localData?.cityval);
  //     setLocalNUmbers(localData?.localnumber)
  //   }

  // }, [localStorage.getItem("localNumbers")])

  // React.useEffect(() => {
  //   if (city) {
  //     setIndex(0)
  //   }
  // }, [city])
  // React.useEffect(() => {
  //   if (area) {
  //     setRepeatIndex(0)
  //   }
  // }, [area])


  React.useEffect(() => {
    if (props.location.state) {
      setValue(props.location.state)
    }
  }, [props.location.state])

  const handleChangeNumbers = () => {
    setLoading(true)
    var myIndex = localNUmbers.length > index + 5 ? index + 5 : 0
    setIndex(myIndex)
    setTimeout(() => {
      setLoading(false)
    }, 1000);

  }


  const handleChangeRepaetNumbers = () => {
    setLoading(true)
    var myIndexR = repeatNumber.length > Repaetindex + 5 ? Repaetindex + 5 : 0
    setRepeatIndex(myIndexR)
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }


  const getSignUpDetails = () => {
    Service.signupDetails(localStorage.getItem("token")).then((res) => {
      if (res.success) {
        setSignUpDetails(res.data)
      }
    }).catch((err) => {
      if (err.request.status === 402) {
        window.location.href = "https://efone.ca/pricing"
        localStorage.clear()
      }
    })
  }

  React.useEffect(() => {
    var number = signupDetails[0]?.numberType === 1 ? signupDetails[0]?.phoneNumber : ""
    if (number) {
      var cleaned = ('' + number).replace(/\D/g, '');
      var match: any = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      number = ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    setAddData((prev: any) => {
      return {
        ...addData,
        phoneNumber: number
      };
    })
    // setIndex()
  }, [signupDetails[0]])
  var newNumber =
    signupDetails[0]?.numberType === 3
      ? signupDetails[0]?.temporaryNumber && signupDetails[0]?.temporaryNumber
      : "";
  if (newNumber) {
    var cleaned = ("" + newNumber).replace(/\D/g, "");
    var match: any = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    newNumber = ["(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  React.useEffect(() => {
    getSignUpDetails()
  }, [])

  return (
    <>
      <div>
        {signupDetails[0]?.phoneNumber && signupDetails[0].numberType === 1 ? <TextScroller text={addData?.phoneNumber} val="phone" /> : ""}
        {signupDetails[0]?.temporaryNumber && signupDetails[0].numberType === 3 ? <TextScroller text={newNumber} val="temp" /> : ""}
        {/* {signupDetails[0]?.phoneNumber ? <TextScroller text={signupDetails[0]?.numberType === 3 && signupDetails[0].temporaryNumber ? newNumber : signupDetails[0]?.numberType === 1 ? addData?.phoneNumber : ""} /> : ""} */}
      </div>

      <div>

        {signupDetails[0]?.numberType === 1 ?
          <CardContent style={{ display: "flex", justifyContent: "space-between" }}>
            <div className={classes.transferNum}>
              <FmdGoodOutlinedIcon style={{ color: "#377dff" }} />
              <Typography className={classes.type}>Local Number</Typography>
            </div>

            <Typography className={classes.type} style={{ float: "right" }}>{addData?.phoneNumber}</Typography>
          </CardContent>

          :
          signupDetails[0]?.numberType === 3 ?
            <CardContent style={{ marginTop: '30px', marginLeft: 30, display: "flex", justifyContent: "space-between" }}>
              <div className={classes.transferNum}>
                <MobileScreenShareIcon style={{ color: "#377dff" }} />
                <Typography>Transfer a Number</Typography>
              </div>

              <Typography>{signupDetails[0]?.numberType === 3 && signupDetails[0].temporaryNumber ? newNumber : addData?.phoneNumber}</Typography>
            </CardContent>
            : ""
        }

        <div>
          <Grid container>

            <Grid item xl={4} lg={4} md={4} sm={8} xs={11} style={{ margin: '0px auto' }}>
              <TabContext value={value}>
                <Card style={{ background: '#F2F2F2' }}>

                  <Tabs
                    className={classes.tabs}
                    value={value}
                    onChange={handleTabs}
                    variant="fullWidth"
                    TabIndicatorProps={{
                      style: {
                        height: "0px",
                      },
                    }}
                    aria-label="icon position tabs example"
                  >
                    {/* <div className={value === "1" ? classes.tab : classes.tabActive} style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}> */}
                    <Tab
                      label="Default"
                      className={value === "1" ? classes.tab : classes.tabActive}
                      value="1"
                      style={{
                        backgroundColor: value === "1" ? "white" : "", fontFamily: 'work sans',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: 16,
                        color: value === "1" ? "#2645AD" : "#868686"
                      }}
                      icon={value === "1" ? <MdRadioButtonChecked color="#2645AD" style={{ width: "22px", height: "27px" }} /> : <ImRadioUnchecked />}
                      iconPosition="start"

                    />
                    {/* </div> */}
                    {/* <div className={value === "2" ? classes.tab : classes.tabActive} style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}> */}
                    <Tab
                      label="Repeating"
                      value="2"
                      iconPosition="start"
                      style={{
                        backgroundColor: value === "2" ? "white" : "", fontFamily: 'work sans',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: 16,
                        color: value === "2" ? "#2645AD" : "#868686"
                      }}
                      icon={value === "2" ? <MdRadioButtonChecked style={{ color: "#2645AD", width: "22px", height: "27px" }} /> : <ImRadioUnchecked />}
                      className={value === "2" ? classes.tab : classes.tabActive}
                    />
                    {/* </div> */}
                    {/* <div className={value === "3" ? classes.tab : classes.tabActive} style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}> */}
                    <Tab
                      label="Vanity"
                      value="3"
                      iconPosition="start"
                      style={{
                        backgroundColor: value === "3" ? "white" : "", fontFamily: 'work sans',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: 16,
                        color: value === "3" ? "#2645AD" : "#868686"
                      }}
                      icon={value === "3" ? <MdRadioButtonChecked style={{ width: "22px", color: "#2645AD", height: "27px" }} /> : <ImRadioUnchecked />}
                      className={value === "3" ? classes.tab : classes.tabActive}
                    />
                    {/* </div> */}
                  </Tabs>

                </Card>

                <Default
                  countryOptions={countryOptions}
                  fetchNumberLists={props.fetchNumberLists}
                  onChangeStateType={onChangeStateType}
                  Departmentid={props.Departmentid}
                  handlePopupModal={props.handlePopupModal}
                  states={states} state={state}
                  handleChangeNumbers={handleChangeNumbers}
                  parentCall={handlecall}
                  index={index}
                  addData={addData}
                  loading={loading}
                  citiesOptions={citiesOptions}
                  country={country}
                  onChangeCountryType={onChangeCountryType} onChangeCity={onChangeCity}
                  city={city} localNUmbers={localNUmbers} />

                <Repeating countryOptions={countryOptions}
                  fetchNumberLists={props.fetchNumberLists}
                  onChangeStateType={onChangeAreaCodetype}
                  Departmentid={props.Departmentid}
                  handlePopupModal={props.handlePopupModal}
                  states={states} state={state}
                  addData={addData}
                  handleChangeNumbers={handleChangeRepaetNumbers}
                  index={Repaetindex}
                  parentCall={handlecall}
                  country={country}
                  loading={loading}
                  citiesOptions={areaOptions}
                  onChangeCountryType={onChangeCountryType} onChangeCity={onchangingcity}
                  city={area} localNUmbers={repeatNumber} />

                <Vanity countryOptions={countryOptions}
                  parentCall={handleCallback}
                  fetchNumberLists={props.fetchNumberLists}
                  onChangeStateType={onChangeAreaCodetype}
                  country={country}
                  addData={addData}
                  loading={loading}
                  parentCallnumber={handlecall}
                  handleChangeNumbers={handleChangeRepaetNumbers}
                  index={Repaetindex}
                  Departmentid={props.Departmentid}
                  handlePopupModal={props.handlePopupModal}
                  states={states} state={state}
                  citiesOptions={areaOptions} cities={allAreas}
                  onChangeCountryType={onChangeCountryType} onChangeCity={onchangingcity}
                  city={area} localNUmbers={repeatNumber} />

              </TabContext>
            </Grid>

          </Grid>

        </div>

      </div>


      {/* <FooterComponent/> */}
      <div className="number-block-btm">
        <Grid container >
          <Grid item lg={4}>
            <IconButton className={classes.nextIconActive}
              onClick={() => history.push(`/signup/cdn1/phone-number/type`)}
            >
              <GrFormPreviousLink className={classes.icon} />
            </IconButton>
          </Grid>
          <Grid item lg={4}>
            <div className="number-dots ">
              <div className="number-dot-item dot-blue">
                <BsCheckCircleFill />
              </div>
              <div className="number-dot-item dot-blue">
                <BsCheckCircleFill />
              </div>
              <div className="number-dot-item active">
                <BsCheckCircleFill />
              </div>
              <div className="number-dot-item ">
                <BsCheckCircleFill />
              </div>
              <div className="number-dot-item">
                <BsCheckCircleFill />
              </div>
            </div>

          </Grid>
          <Grid item lg={4}>
            {addData?.phoneNumber?.length > 0 &&
              <IconButton className={classes.nextIconActive1}
                onClick={() => handleSelectedNumbers(addData)} style={{ float: 'right' }}>
                <GrFormNextLink className={classes.icon} />
              </IconButton>}
          </Grid>
        </Grid>
      </div>
    </>
  );
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    popUpAlert: (err: any, instance: any, type: any) => dispatch(popUpActionCreator.initglobalPopup(err, instance, type))
  }
}

export default connect(null, mapDispatchToProps)(AddEfoneNumber)


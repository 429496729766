import React from 'react';
import { CardContent, Typography, Card, TextField, Grid, Tabs, Tab, Button, IconButton } from '@material-ui/core'
import ReactSelect from 'react-select'
import Select from 'react-select'
import Flags from '../../assets/flags.png'
import { useStyles } from './style'
import { useHistory } from 'react-router-dom'
import Service from './Service'
import './style.css'
import { BsCheckCircleFill } from 'react-icons/bs'
import { InputAdornment } from '@material-ui/core';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import * as popUpActionCreator from "../../store/Actions/popup-actionCreator";
import { FiEye, FiEyeOff } from 'react-icons/fi'
import 'react-telephone-input/css/default.css'
import ReactTelephoneInput from 'react-telephone-input'
import { connect } from "react-redux";
import TextScroller from '../Layout/Textscroll'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
const formGroup: any = [
    {
        field: 'firstName',
        valid: true,
        message: ''
    },
    {
        field: 'lastName',
        valid: true,
        message: ''
    },
    {
        field: 'mobileNumber',
        valid: true,
        message: ''
    },
    {
        field: 'password',
        valid: true,
        message: ''
    },
    {
        field: 'confirmPassword',
        valid: true,
        message: ''
    },
    {
        field: 'deviceOptions',
        valid: true,
        message: ''
    },
    {
        field: 'modemId',
        valid: true,
        message: ''
    },
    {
        field: 'macAddress',
        valid: true,
        message: ''
    },
    {
        field: 'address',
        valid: true,
        message: ''
    },
    {
        field: 'apartment',
        valid: true,
        message: ''
    },
    {
        field: 'city',
        valid: true,
        message: ''
    },
    {
        field: 'province',
        valid: true,
        message: ''
    },
    {
        field: 'postalCode',
        valid: true,
        message: ''
    },
    {
        field: 'country',
        valid: true,
        message: ''
    },
]
function UserProfile(props: any) {
    const classes = useStyles()
    const history = useHistory()
    const [signupDetails, setSignUpDetails] = React.useState<any>([])
    const [formGroupData, setFormgroup] = React.useState<any>(formGroup)
    const [data, setData] = React.useState<any>({
        firstName: "",
        lastName: "",
        mobileNumber: "",
        password: "",
        confirmPassword: "",
        deviceOptions: 1,
        modemId: "",
        macAddress: "",
        address: "",
        apartment: "",
        city: "",
        province: "",
        postalCode: "",
        country: ""
    })
    const [showPw, setShowPw] = React.useState(false)
    const [showconfirmPw, setShowconfirmPw] = React.useState(false)
    function handleInputChange(telNumber: any, selectedCountry: any) {
        setData((prev: any) => {
            return {
                ...data,
                mobileNumber: telNumber
            }
        });

    }

    function handleInputBlur(telNumber: any, selectedCountry: any) {
        console.log(
            'Focus off the ReactTelephoneInput component. Tel number entered is: ',
            telNumber,
            ' selected country is: ',
            selectedCountry
        )
    }
    const habdleshowPW = () => {
        setShowPw(!showPw)
    }
    const habdleshowconfirmPW = () => {
        setShowconfirmPw(!showconfirmPw)
    }
    const handleChangeaddress = (addr: any) => {
        setData((prev: any) => {
            return {
                ...data,
                address: addr
            }
        });

    };
    const handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then((results) => {
                var myResults = results[0]
                var city = ""
                //var state = ""
                var country = ""
                var postalCode = ""
                var streetNumber = ""
                var streetName = ""
                var province = ""
                var apartment = ""

                getLatLng(myResults).then((latLong) => {
                    myResults.address_components.map((item) => {
                        if (item.types.includes("locality")) {
                            city = item.long_name
                        }
                        // if (item.types.includes("administrative_area_level_1")) {
                        //   state = item.long_name
                        // }
                        if (item.types.includes("country")) {
                            country = item.long_name
                        }
                        if (item.types.includes("postal_code")) {
                            postalCode = item.long_name
                        }
                        if (item.types.includes("street_number")) {
                            streetNumber = item.long_name
                        }
                        if (item.types.includes("apartment")) {
                            apartment = item.long_name
                        }
                        if (item.types.includes("route")) {
                            streetName = item.short_name
                        }
                        if (item.types.includes("administrative_area_level_1")) {
                            province = item.short_name
                        }

                    })

                    setData({
                        ...data,
                        address: myResults.formatted_address,
                        apartment: apartment,
                        city: city,
                        country: country,
                        postalCode: postalCode,
                        province: province
                    });
                })
            })
            .catch(error => console.error('Error', error));
    };
    const validateForm = (dataToValidate: any) => {
        let formgroupCopy = [...formGroupData];

        if (dataToValidate.property === 'firstName') {
            if (!/^[a-z][a-z\s]*$/i.test(dataToValidate.value)) {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'firstName') {
                        formG.valid = false;
                        formG.message = 'firstName allow only characters';
                    }
                })
                setFormgroup(formgroupCopy)
            }
            else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'firstName') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'lastName') {
            // if (dataToValidate.value === '') {
            //     formgroupCopy.forEach((formG: any) => {
            //         if (formG.field === 'lastName') {
            //             formG.valid = false;
            //             formG.message = 'lastName should not be empty';
            //         }
            //     })
            //     setFormgroup(formgroupCopy)
            // } else if (/\s/g.test(dataToValidate.value)) {
            //     formgroupCopy.forEach((formG: any) => {
            //         if (formG.field === 'lastName') {
            //             formG.valid = false;
            //             formG.message = 'lastName should not be empty';
            //         }
            //     })
            //     setFormgroup(formgroupCopy)
            // }
            // else
            if (!/^[a-z][a-z\s]*$/i.test(dataToValidate.value)) {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'lastName') {
                        formG.valid = false;
                        formG.message = 'lastName allow only characters';
                    }
                })
                setFormgroup(formgroupCopy)
            }
            else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'lastName') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'mobileNumber') {
            const mobile = data.mobileNumber.replace(/[^a-zA-Z0-9]/g, '')
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'mobileNumber') {
                        formG.valid = false;
                        formG.message = 'mobileNumber should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else if (mobile.length < 10 || mobile.length > 15) {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'mobileNumber') {
                        formG.valid = false;
                        formG.message = 'Please enter Numbers between 10 to 15 digits';
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'mobileNumber') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'password') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'password') {
                        formG.valid = false;
                        formG.message = 'password should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'password') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'confirmPassword') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'confirmPassword') {
                        formG.valid = false;
                        formG.message = 'confirmPassword should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'confirmPassword') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'deviceOptions') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'deviceOptions') {
                        formG.valid = false;
                        formG.message = 'deviceOptions should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'deviceOptions') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'modemId') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'modemId') {
                        formG.valid = false;
                        formG.message = 'modemId should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'modemId') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'macAddress') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'macAddress') {
                        formG.valid = false;
                        formG.message = 'macAddress should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'macAddress') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'address') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'address') {
                        formG.valid = false;
                        formG.message = 'address should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'address') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'apartment') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'apartment') {
                        formG.valid = false;
                        formG.message = 'apartment should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'apartment') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'city') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'city') {
                        formG.valid = false;
                        formG.message = 'city should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'city') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'province') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'province') {
                        formG.valid = false;
                        formG.message = 'province should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'province') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'postalCode') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'postalCode') {
                        formG.valid = false;
                        formG.message = 'postalCode should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'postalCode') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'country') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'country') {
                        formG.valid = false;
                        formG.message = 'country should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'country') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'confirmPassword' && dataToValidate.property === 'password') {
            if (data.password !== data.confirmPassword) {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'email') {
                        formG.valid = false;
                        formG.message = 'Please provide both same passwords'
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }


    }
    const handleClick = () => {
        let sendData
        if (data.deviceOptions === 1) {
            sendData = {
                firstName: data.firstName,
                lastName: data.lastName,
                mobileNumber: data.mobileNumber.replace(/[^0-9,.]+/g, ""),
                password: data.password,
                deviceOptions: data.deviceOptions
            }
        }
        else if (data.deviceOptions === 2) {
            sendData = {
                firstName: data.firstName,
                lastName: data.lastName,
                mobileNumber: data.mobileNumber.replace(/[^0-9,.]+/g, ""),
                password: data.password,
                deviceOptions: data.deviceOptions,
                modemId: data.modemId,
                macAddress: data.macAddress,
            }
        }
        else if (data.deviceOptions === 3) {
            sendData = {
                firstName: data.firstName,
                lastName: data.lastName,
                mobileNumber: data.mobileNumber.replace(/[^0-9,.]+/g, ""),
                password: data.password,
                deviceOptions: data.deviceOptions,
                modemId: data.modemId,
                shippingAddress: [{
                    apartment: data.apartment,
                    city: data.city,
                    province: data.province,
                    postalCode: data.postalCode,
                    country: data.country
                }],
            }
        }

        validateForm({ property: 'firstName', value: data.firstName })
        validateForm({ property: 'lastName', value: data.lastName })
        validateForm({ property: 'mobileNumber', value: data.mobileNumber })
        validateForm({ property: 'password', value: data.password })
        validateForm({ property: 'deviceOptions', value: data.deviceOptions })
        validateForm({ property: 'modemId', value: data.modemId })
        validateForm({ property: 'macAddress', value: data.macAddress })
        validateForm({ property: 'apartment', value: data.apartment })
        validateForm({ property: 'city', value: data.city })
        validateForm({ property: 'province', value: data.province })
        validateForm({ property: 'postalCode', value: data.postalCode })
        validateForm({ property: 'country', value: data.country })
        validateForm({ property: 'address', value: data.address })
        const [password, confirmPassword, firstName, lastName, mobileNumber, deviceOptions, modemId, macAddress, address, apartment, city, province, postalCode, country] = formGroupData;
        if (data.deviceOptions === 1) {
            if (firstName.valid && lastName.valid && mobileNumber.valid && password.valid && deviceOptions.valid && confirmPassword.valid) {
                if (data.confirmPassword === data.password) {
                    Service.PatchUserDetails(localStorage.getItem("token"), sendData).then((res) => {
                        if (res.success) {
                            const data = res.data.data
                            console.log(data)
                            history.push("/signup/cdn1/payment")
                            props.popUpAlert(res.data.message, "UPDATE_AGENT_SUCCESS", "success");
                        }
                        else {

                            props.popUpAlert(res.message, "UPDATE_AGENT_SUCCESS", "error");
                        }
                    }).catch(err => {
                        if (err.request.status === 402) {
                            window.location.href = "https://efone.ca/pricing"
                            localStorage.removeItem("token")
                            localStorage.removeItem("localNumbers")
                        }

                        props.popUpAlert(err.response.message, "UPDATE_AGENT_SUCCESS", "error");
                    })

                }
                else {
                    props.popUpAlert("Both password and confirm password should be same", "UPDATE_AGENT_SUCCESS", "error");
                }
            }
        }
        else if (data.deviceOptions === 2) {
            if (firstName.valid && lastName.valid && mobileNumber.valid && password.valid && deviceOptions.valid && confirmPassword.valid && macAddress.valid && modemId.valid) {
                if (data.confirmPassword === data.password) {
                    Service.PatchUserDetails(localStorage.getItem("token"), sendData).then((res) => {
                        if (res.success) {
                            const data = res.data.data
                            console.log(data)
                            history.push("/signup/cdn1/payment")
                            props.popUpAlert(res.data.message, "UPDATE_AGENT_SUCCESS", "success");
                        }
                        else {
                            props.popUpAlert(res.data.message, "UPDATE_AGENT_SUCCESS", "error");
                        }
                    }).catch(err => {
                        console.log('errrrrrr', err)
                        props.popUpAlert(err.message, "UPDATE_AGENT_SUCCESS", "error");
                    })

                }
                else {
                    props.popUpAlert("Both password and confirm password should be same", "UPDATE_AGENT_SUCCESS", "error");
                }

            }
        }
        else if (data.deviceOptions === 3) {
            if (firstName.valid && lastName.valid && mobileNumber.valid && password.valid && deviceOptions.valid && confirmPassword.valid && macAddress.valid && modemId.valid && address.valid && apartment.valid && city.valid && province.valid && postalCode.valid && country.valid) {
                if (data.confirmPassword === data.password) {
                    Service.PatchUserDetails(localStorage.getItem("token"), sendData).then((res) => {
                        if (res.success) {
                            const data = res.data.data
                            console.log(data)
                            history.push("/signup/cdn1/payment")
                            props.popUpAlert(res.data.message, "UPDATE_AGENT_SUCCESS", "success");
                        }
                        else {
                            props.popUpAlert(res.data.message, "UPDATE_AGENT_SUCCESS", "error");
                        }
                    }).catch(err => {
                        console.log('errrrrrr', err)
                        props.popUpAlert(err.message, "UPDATE_AGENT_SUCCESS", "error");
                    })

                }
                else {
                    props.popUpAlert("Both password and confirm password should be same", "UPDATE_AGENT_SUCCESS", "error");
                }
            }
        }

    }
    const modemIdOption = [
        { label: "Linksys PAP2T($35)", value: "Linksys PAP2T($35)" }
    ]
    const deviceOptions = [
        { value: 1, label: "I don't want to use a eFone landline adapter (eFone smartphone app only)" },
        { value: 2, label: "I have eFone landline adapter" },
        { value: 3, label: "Please ship me the eFone landline  adapter" },]


    const getSignUpDetails = () => {
        Service.signupDetails(localStorage.getItem("token")).then((res) => {
            if (res.success) {
                console.log("-----res", res.data)
                const newdt = res.data
                setSignUpDetails(res.data)
                setData(res.data)
                setData({
                    ...data,
                    firstName: newdt[0].firstName,
                    lastName: newdt[0].lastName,
                    mobileNumber: newdt[0].mobileNumber,
                    password: newdt[0].password,
                    // confirmPassword: newdt[0].confirmPassword,
                    deviceOptions: newdt[0].deviceOptions,
                    modemId: newdt[0].modemId,
                    macAddress: newdt[0].macAddress,
                    address: newdt[0].shippingAddress[0].address,
                    apartment: newdt[0].shippingAddress[0].apartment,
                    city: newdt[0].shippingAddress[0].city,
                    country: newdt[0].shippingAddress[0].country,
                    postalCode: newdt[0].shippingAddress[0].postalCode,
                    province: newdt[0].shippingAddress[0].province
                });

            }

        }).catch((err) => {

        })
    }
    const handlechangedetails = (e: any) => {
        const val = e.target.value
        if (val !== " ") {
            setData((prev: any) => {
                return {
                    ...data,
                    [e.target.name]: val
                }
            })
        }

    }
    const handlechangeFirstName = (e: any) => {
        const val = e.target.value
        if (val !== " ") {
            setData((prev: any) => {
                return {
                    ...data,
                    firstName: e.target.value
                }
            })
        }

    }
    const handlechangelastName = (e: any) => {
        const val = e.target.value
        if (val !== " ") {
            setData((prev: any) => {
                return {
                    ...data,
                    lastName: e.target.value
                }
            })
        }
    }
    const handleDeviceOption = (e: any) => {
        setData((prev: any) => {
            return {
                ...data,
                deviceOptions: e.value
            }
        })
    }
    const handleModemOption = (e: any) => {
        setData((prev: any) => {
            return {
                ...data,
                modemId: e.value
            }
        })
    }
    React.useEffect(() => {
        const newdt = signupDetails
        setData({
            ...data,
            firstName: newdt[0]?.firstName,
            lastName: newdt[0]?.lastName,
            mobileNumber: newdt[0]?.mobileNumber,
            password: newdt[0]?.password,
            confirmPassword: newdt[0]?.password,
            deviceOptions: newdt[0]?.deviceOptions,
            modemId: newdt[0]?.modemId,
            macAddress: newdt[0]?.macAddress,
            address: newdt[0]?.shippingAddress[0]?.address,
            apartment: newdt[0]?.shippingAddress[0]?.apartment,
            city: newdt[0]?.shippingAddress[0]?.city,
            country: newdt[0]?.shippingAddress[0]?.country,
            postalCode: newdt[0]?.shippingAddress[0]?.postalCode,
            province: newdt[0]?.shippingAddress[0]?.province
        });

    }, [signupDetails])
    React.useEffect(() => {
        getSignUpDetails()
    }, [])
    var number = signupDetails[0]?.phoneNumber
    if (number) {
        var cleaned = ('' + number).replace(/\D/g, '');
        var match: any = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        number = ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    var newNumber =
        signupDetails[0]?.numberType === 3
            ? signupDetails[0]?.temporaryNumber && signupDetails[0]?.temporaryNumber
            : "";
    if (newNumber) {
        var cleaned = ("" + newNumber).replace(/\D/g, "");
        var match: any = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        newNumber = ["(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return (
        <>
            <div>
                {signupDetails[0]?.phoneNumber && signupDetails[0].numberType === 1 ? <TextScroller text={number} val="phone" /> : ""}
                {signupDetails[0]?.temporaryNumber && signupDetails[0].numberType === 3 ? <TextScroller text={newNumber} val="temp" /> : ""}
                {/* {signupDetails[0]?.phoneNumber ? <TextScroller text={signupDetails[0].numberType === 3 && signupDetails[0]?.temporaryNumber ? newNumber : number} /> : ""} */}
            </div>
            <div style={{ marginBottom: "105px" }}>

                <CardContent>
                    {signupDetails[0]?.numberType === 1 ?
                        <CardContent style={{ marginTop: '10px', marginLeft: 30, display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex" }}>
                                <FmdGoodOutlinedIcon style={{ color: "#377dff" }} />
                                <Typography className={classes.type}>Local Number</Typography>
                            </div>
                            {/* <Typography className={classes.type} style={{ float: "right" }}>Port Number :{signupDetails[0]?.portNumber}</Typography> */}
                            <Typography className={classes.type} style={{ float: "right" }}>{number}</Typography>
                        </CardContent>

                        :
                        <CardContent style={{ marginTop: '10px', marginLeft: 30, display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex" }}>
                                <MobileScreenShareIcon style={{ color: "#377dff" }} />
                                <Typography className={classes.type}>Transfer a Number</Typography>
                            </div>

                            <Typography className={classes.type} style={{ float: "right" }} >{signupDetails[0]?.numberType === 3 && signupDetails[0]?.temporaryNumber ? newNumber : number}</Typography>
                        </CardContent>

                    }

                </CardContent>
                <div style={{ marginTop: 55, width: '60%', margin: '0px auto', marginBottom: "45px" }}>
                    <Grid container spacing={4}>
                        <Grid item md={3}></Grid>
                        <Grid item md={6}>
                            <div>
                                <Typography className={classes.type}>First Name</Typography>
                                <TextField
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    name="firstName"
                                    value={data.firstName}
                                    onChange={handlechangeFirstName}
                                    placeholder='Enter first name'
                                    className={classes.textfield}
                                />
                                <p className={classes.err}>{formGroupData.map((fileds: any) => {
                                    if (fileds.valid === false && fileds.field === 'firstName') {
                                        return fileds.message
                                    } else {
                                        return ''
                                    }
                                })}</p>
                            </div>
                            <div className={classes.mt}>
                                <Typography className={classes.type}>Last Name </Typography>
                                <TextField
                                    variant="outlined"
                                    size='small'
                                    name="lastName"
                                    fullWidth
                                    value={data.lastName}
                                    onChange={handlechangelastName}
                                    placeholder='Enter last name'
                                    className={classes.textfield}
                                />
                                <p className={classes.err}>{formGroupData.map((fileds: any) => {
                                    if (fileds.valid === false && fileds.field === 'lastName') {
                                        return fileds.message
                                    } else {
                                        return ''
                                    }
                                })}</p>
                            </div>
                            <div className={classes.mt}>
                                <Typography className={classes.type}>Your Mobile Number </Typography>
                                <ReactTelephoneInput
                                    variant="outlined"
                                    size='medium'
                                    max={10}
                                    style={{ width: "100%" }}
                                    value={data.mobileNumber}
                                    defaultCountry="us"
                                    flagsImagePath={Flags}
                                    onChange={handleInputChange}
                                    onBlur={handleInputBlur}
                                />
                                {/* <TextField
                                variant="outlined"
                                size='small'
                                fullWidth
                                value={data.mobileNumber}
                                name="mobileNumber"
                                type="tel"
                                onChange={handlechangedetails}
                                placeholder='Enter mobile number'
                                className={classes.textfield}
                            /> */}
                                <p className={classes.err}>{formGroupData.map((fileds: any) => {
                                    if (fileds.valid === false && fileds.field === 'mobileNumber') {
                                        return fileds.message
                                    } else {
                                        return ''
                                    }
                                })}</p>
                            </div>
                            <div className={classes.mt}>
                                <Typography className={classes.type}>Password</Typography>
                                <TextField
                                    variant="outlined"
                                    size='small'
                                    type={showPw ? "text" : "password"}
                                    fullWidth
                                    value={data.password}
                                    name="password"
                                    onChange={handlechangedetails}
                                    placeholder='Password'
                                    className={classes.textfield}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start" className="passIcon">
                                                {showPw ? <FiEye onClick={habdleshowPW} style={{ cursor: "pointer" }} /> :
                                                    <FiEyeOff onClick={habdleshowPW} style={{ cursor: "pointer" }} />}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <p className={classes.err}>{formGroupData.map((fileds: any) => {
                                    if (fileds.valid === false && fileds.field === 'password') {
                                        return fileds.message
                                    } else {
                                        return ''
                                    }
                                })}</p>
                            </div>
                            <div className={classes.mt}>
                                <Typography className={classes.type}>Confirm Password</Typography>
                                <TextField
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    type={showconfirmPw ? "text" : "password"}
                                    value={data?.confirmPassword}
                                    name="confirmPassword"
                                    onChange={handlechangedetails}
                                    placeholder='Confirm Password'
                                    className={classes.textfield}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start" className="passIcon">
                                                {showconfirmPw ? <FiEye onClick={habdleshowconfirmPW} style={{ cursor: "pointer" }} /> :
                                                    <FiEyeOff onClick={habdleshowconfirmPW} style={{ cursor: "pointer" }} />}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <p className={classes.err}>{formGroupData.map((fileds: any) => {
                                    if (fileds.valid === false && fileds.field === 'confirmPassword') {
                                        return fileds.message
                                    } else {
                                        return ''
                                    }
                                })}</p>
                            </div>
                            {/* <div className={classes.mt}>
                            <Typography className={classes.type}>Device</Typography>
                            <Select
                                name="deviceOptions"
                                options={deviceOptions}
                                value={
                                    deviceOptions.filter((option: any) =>
                                        option.value === data.deviceOptions)
                                }

                                onChange={handleDeviceOption}
                                className={classes.textfield}
                            />
                            <p className={classes.err}>{formGroupData.map((fileds: any) => {
                                if (fileds.valid === false && fileds.field === 'deviceOptions') {
                                    return fileds.message
                                } else {
                                    return ''
                                }
                            })}</p>
                        </div> */}
                            {data.deviceOptions === 2 || data.deviceOptions === 3 ?
                                <div className={classes.mt}>
                                    <Typography className={classes.type}>Modem</Typography>
                                    <ReactSelect
                                        name="modemId"
                                        options={modemIdOption}
                                        value={
                                            modemIdOption.filter((option: any) =>
                                                option.value === data.modemId)
                                        }

                                        onChange={handleModemOption}
                                        className={classes.textfield}
                                    />
                                    <p className={classes.err}>{formGroupData.map((fileds: any) => {
                                        if (fileds.valid === false && fileds.field === 'modemId') {
                                            return fileds.message
                                        } else {
                                            return ''
                                        }
                                    })}</p>
                                </div>
                                : ""}
                            {data.deviceOptions === 2 ?
                                <div className={classes.mt}>
                                    <Typography className={classes.type}>MAC Address</Typography>
                                    <TextField
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        value={data.macAddress}
                                        name="macAddress"
                                        onChange={handlechangedetails}
                                        placeholder='MacAddress'
                                        className={classes.textfield}
                                    />
                                    <p className={classes.err}>{formGroupData.map((fileds: any) => {
                                        if (fileds.valid === false && fileds.field === 'macAddress') {
                                            return fileds.message
                                        } else {
                                            return ''
                                        }
                                    })}</p>
                                </div> : ""}
                            {
                                data.deviceOptions === 3 ?

                                    <div className={classes.mt}>
                                        <Typography className={classes.type}>Address</Typography>
                                        <PlacesAutocomplete
                                            value={data.address ? data.address : ""}
                                            onChange={handleChangeaddress}
                                            onSelect={handleSelect}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
                                                <div>
                                                    {/* <input
                            style={{
                              width: "95%", padding: 10, color: '#8392AB', position: 'relative',
                              borderRadius: ' 4px', borderColor: 'rgba(0, 0, 0, 0.23)', fontFamily: "sans-serif",
                              border: "1px solid #3f51b5"
                            }}
                            {...getInputProps({
                              placeholder: 'Search address',
                              className: 'location-search-input',
                            })}
                          /> */}
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                        {...getInputProps({
                                                            placeholder: 'Enter a Location',
                                                            className: 'location-search-input',
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map((suggestion: any) => {
                                                            const className = suggestion.active
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';
                                                            // inline style for demonstration purpose
                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                            return (
                                                                <div style={{ display: "flex", border: "1px solid #d4d5d9", padding: 14 }} >
                                                                    <div
                                                                        {...getSuggestionItemProps(suggestion, {
                                                                            className,
                                                                            style,
                                                                        })}
                                                                    >
                                                                        <span>{suggestion.description}</span>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                        {/* <TextField
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        name="address"
                                        onChange={handlechangedetails}
                                        placeholder='Enter a Location'
                                        className={classes.textfield}
                                    /> */}
                                        <p className={classes.err}>{formGroupData.map((fileds: any) => {
                                            if (fileds.valid === false && fileds.field === 'address') {
                                                return fileds.message
                                            } else {
                                                return ''
                                            }
                                        })}</p>
                                    </div>
                                    : ""}
                            {
                                data.deviceOptions === 3 ?

                                    <div className={classes.mt}>
                                        <Grid container spacing={2}>
                                            <Grid item md={5}>
                                                <Typography className={classes.type}>App/Suite</Typography>
                                                <TextField
                                                    variant="outlined"
                                                    size='small'
                                                    fullWidth
                                                    value={data.apartment}
                                                    name="apartment"
                                                    onChange={handlechangedetails}
                                                    placeholder='Enter apartment'
                                                    className={classes.textfield}
                                                />
                                                <p className={classes.err}>{formGroupData.map((fileds: any) => {
                                                    if (fileds.valid === false && fileds.field === 'apartment') {
                                                        return fileds.message
                                                    } else {
                                                        return ''
                                                    }
                                                })}</p>
                                            </Grid>
                                            <Grid item md={2}></Grid>
                                            <Grid item md={5}>
                                                <Typography className={classes.type}>City</Typography>
                                                <TextField
                                                    variant="outlined"
                                                    size='small'
                                                    fullWidth
                                                    value={data.city}
                                                    name="city"
                                                    onChange={handlechangedetails}
                                                    placeholder='Enter city'
                                                    className={classes.textfield}
                                                />
                                                <p className={classes.err}>{formGroupData.map((fileds: any) => {
                                                    if (fileds.valid === false && fileds.field === 'city') {
                                                        return fileds.message
                                                    } else {
                                                        return ''
                                                    }
                                                })}</p>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    : ""}
                            {
                                data.deviceOptions === 3 ?
                                    <div className={classes.mt}>
                                        <Grid container spacing={2}>
                                            <Grid item md={5}>
                                                <Typography className={classes.type}>Province/State</Typography>
                                                <TextField
                                                    variant="outlined"
                                                    size='small'
                                                    fullWidth
                                                    value={data.province}
                                                    name="province"
                                                    onChange={handlechangedetails}
                                                    placeholder='Enter province'
                                                    className={classes.textfield}
                                                />
                                                <p className={classes.err}>{formGroupData.map((fileds: any) => {
                                                    if (fileds.valid === false && fileds.field === 'province') {
                                                        return fileds.message
                                                    } else {
                                                        return ''
                                                    }
                                                })}</p>
                                            </Grid>
                                            <Grid item md={2}></Grid>
                                            <Grid item md={5}>
                                                <Typography className={classes.type}>zip/Postal code</Typography>
                                                <TextField
                                                    variant="outlined"
                                                    size='small'
                                                    fullWidth
                                                    value={data.postalCode}
                                                    name="postalCode"
                                                    onChange={handlechangedetails}
                                                    placeholder='Enter postalCode'
                                                    className={classes.textfield}
                                                />
                                                <p className={classes.err}>{formGroupData.map((fileds: any) => {
                                                    if (fileds.valid === false && fileds.field === 'postalCode') {
                                                        return fileds.message
                                                    } else {
                                                        return ''
                                                    }
                                                })}</p>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    : ""}
                            {
                                data.deviceOptions === 3 ?
                                    <div className={classes.mt}>
                                        <Grid container spacing={2}>
                                            <Grid item md={6}>
                                                <Typography className={classes.type}>Country</Typography>
                                                <TextField
                                                    variant="outlined"
                                                    size='small'
                                                    fullWidth
                                                    value={data.country}
                                                    name="country"
                                                    onChange={handlechangedetails}
                                                    placeholder='Enter country'
                                                    className={classes.textfield}
                                                />
                                                <p className={classes.err}>{formGroupData.map((fileds: any) => {
                                                    if (fileds.valid === false && fileds.field === 'country') {
                                                        return fileds.message
                                                    } else {
                                                        return ''
                                                    }
                                                })}</p>
                                            </Grid>
                                            <Grid md={6}>

                                            </Grid>
                                        </Grid>
                                    </div>
                                    : ""}
                            {/* {
                            data.deviceOptions === 3 ?
                                <div className={classes.mt}>
                                    <h4 className={classes.main}>shipping charges will be applicable in checkout.</h4>
                                </div>
                                : ""
                        } */}
                        </Grid>
                        <Grid item md={3}></Grid>
                    </Grid>
                </div>
                <div className="number-block-btm">
                    <Grid container spacing={4} style={{ position: "sticky" }}>
                        <Grid item lg={4}>
                            {signupDetails[0]?.numberType === 3 ?
                                <IconButton className={classes.nextIconActive}
                                    onClick={() => history.push({ pathname: '/signup/cdn1/phone-number/port/number', state: "Authorisation" })}
                                >
                                    <GrFormPreviousLink className={classes.icon} />
                                </IconButton> :
                                <IconButton className={classes.nextIconActive}
                                    onClick={() => history.push({ pathname: '/signup/cdn1/phone-number/local', state: props.location.state })}
                                >
                                    <GrFormPreviousLink className={classes.icon} />
                                </IconButton>}
                        </Grid>
                        <Grid item lg={4}>
                            <div className="number-dots ">
                                <div className="number-dot-item dot-blue">
                                    <BsCheckCircleFill />
                                </div>
                                <div className="number-dot-item dot-blue">
                                    <BsCheckCircleFill />
                                </div>
                                <div className="number-dot-item dot-blue">
                                    <BsCheckCircleFill />
                                </div>
                                <div className="number-dot-item active">
                                    <BsCheckCircleFill />
                                </div>
                                <div className="number-dot-item">
                                    <BsCheckCircleFill />
                                </div>
                            </div>

                        </Grid>
                        <Grid item lg={4}>
                            {(data?.deviceOptions !== '' && data?.deviceOptions === 1) ?
                                data.firstName && data.lastName && data.mobileNumber && data.password && data.confirmPassword &&
                                <IconButton className={classes.nextIconActive1}
                                    onClick={handleClick} style={{ float: 'right' }}
                                >
                                    <GrFormNextLink className={classes.icon} />
                                </IconButton> :
                                data?.deviceOptions !== '' && data?.deviceOptions === 2 ?
                                    data.firstName && data.lastName && data.mobileNumber && data.password && data.confirmPassword && data.modemId && data.macAddress &&
                                    <IconButton className={classes.nextIconActive1}
                                        onClick={handleClick} style={{ float: 'right' }}
                                    >
                                        <GrFormNextLink className={classes.icon} />
                                    </IconButton> :
                                    data?.deviceOptions !== '' && data?.deviceOptions === 3 ?
                                        data.firstName && data.lastName && data.mobileNumber && data.password && data.confirmPassword && data.modemId && data.macAddress && data.address && data.apartment && data.city && data.province && data.postalCode && data.country &&
                                        <IconButton className={classes.nextIconActive1}
                                            onClick={handleClick} style={{ float: 'right' }}
                                        >
                                            <GrFormNextLink className={classes.icon} />
                                        </IconButton> :
                                        <IconButton className={classes.nextIcon}
                                            onClick={handleClick} style={{ float: 'right' }}
                                        >
                                            <GrFormNextLink className={classes.icon} />
                                        </IconButton>
                            }

                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    )
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        popUpAlert: (err: any, instance: any, type: any) => dispatch(popUpActionCreator.initglobalPopup(err, instance, type))
    }
}

export default connect(null, mapDispatchToProps)(UserProfile)

